import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';
import { i18n } from '@furo/framework/src/i18n';
import '@furo/route/src/furo-app-flow';
import '@furo/layout/src/furo-horizontal-flex';
import '@furo/data/src/furo-catalog.js';
import '../project-components/user-profile.js';

/**
 * `main-menu`
 * Das ist die Kopfzeile von Mora
 *
 * @summary todo shortdescription
 * @customElement
 * @appliesMixin FBP
 */
class MainMenu extends FBP(LitElement) {
  /**
   * @private
   * @return {Object}
   */
  static get properties() {
    return {
      /**
       * Name der Aktuellen Seite
       */
      pageName: {
        type: String,
        attribute: 'page-name',
      },
      pageTitle: {
        type: String,
      },
    };
  }

  set pageName(v) {
    // set pagetitle when pagename is set from outside
    this.pageTitle = i18n.t(`menu_titel_${v}`);
    this._pageTitle = v;
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();

    // aktives element in der navigation anwählen und setzen
    const a = this.shadowRoot.getElementById(this._pageTitle);
    if (a) {
      a.setAttribute('active', true);
    }
  }

  refresh() {
    this._FBPTriggerWire('--toggleUserProfile', true);
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('MainHeader') ||
      css`
        :host {
          display: block;

          color: var(--on-primary);
          font-size: 19px;
          height: 48px;
          white-space: nowrap;
        }

        div {
          line-height: 42px;
          padding: 4px 10px 0 10px;
          border-bottom: 2px solid var(--primary);
          cursor: pointer;
        }

        user-profile {
          line-height: 42px;
          padding: 4px 10px 0 10px;
          cursor: pointer;
        }

        div[active] {
          border-bottom: 2px solid var(--secondary);
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-horizontal-flex>
        <furo-icon-button icon="home" @-click="--HomeClicked"></furo-icon-button>
        <div flex class="title">${this.pageTitle}</div>
        <user-profile
          flex
          ƒ-bind-data="--userprofileEntity(*.data)"
          @-click="--UserprofileClicked"
        ></user-profile>
        <div pointer id="supporte" @-click="--SupporteClicked">
          ${i18n.t('menu_titel_supporte')}
        </div>
        <div pointer id="zeiterfassung" @-click="--ZeiterfassungClicked">
          ${i18n.t('menu_titel_zeiterfassung')}
        </div>
        <div pointer id="ausgaben" @-click="--AusgabenClicked">
          ${i18n.t('menu_titel_ausgaben')}
        </div>
        <div pointer id="nachbestellung" @-click="--NachbestellungClicked">
          ${i18n.t('menu_titel_nachbestellung')}
        </div>
        <furo-icon-button
          raised
          icon="add-shopping-cart"
          @-click="--BestellvorschlaegeClicked"
        ></furo-icon-button>
        <furo-icon-button icon="power-settings-new" @-click="^^logout-request"></furo-icon-button>
      </furo-horizontal-flex>

      <furo-app-flow event="menu-item-supporte-clicked" ƒ-emit="--SupporteClicked"></furo-app-flow>

      <furo-app-flow
        event="menu-item-zeiterfassung-clicked"
        ƒ-emit="--ZeiterfassungClicked"
      ></furo-app-flow>

      <furo-app-flow event="menu-item-ausgaben-clicked" ƒ-emit="--AusgabenClicked"></furo-app-flow>

      <furo-app-flow
        event="menu-item-nachbestellung-clicked"
        ƒ-emit="--NachbestellungClicked"
      ></furo-app-flow>

      <furo-app-flow
        event="menu-item-bestellvorschlaege-clicked"
        ƒ-emit="--BestellvorschlaegeClicked"
      ></furo-app-flow>

      <furo-app-flow event="home-clicked" ƒ-emit="--HomeClicked"></furo-app-flow>

      <furo-app-flow event="userprofile-clicked" ƒ-emit="--UserprofileClicked"></furo-app-flow>

      <!-- Datenkomponenten um das Userprofil zu laden -->
      <furo-deep-link
        service="Userprofile"
        ƒ-qp-in="--toggleUserProfile"
        @-hts-out="--htsUserprofile"
      ></furo-deep-link>

      <furo-entity-agent
        service="Userprofile"
        ƒ-hts-in="--htsUserprofile"
        @-hts-injected="--htsUserprofileUpdated"
        ƒ-load="--htsUserprofileUpdated"
        @-response="--userprofileReceived"
      ></furo-entity-agent>

      <furo-data-object
        type="userprofile.UserprofileEntity"
        ƒ-inject-raw="--userprofileReceived"
        ƒ-init="--pageDeActivated"
        @-object-ready="--userprofileEntity"
      ></furo-data-object>
    `;
  }
}

window.customElements.define('main-menu', MainMenu);
