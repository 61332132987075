import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme';
import { FBP } from '@furo/fbp';
import { i18n } from '@furo/framework';
import './tr-objekte.js';

/**
 * `display-Objekte`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo/display-Objekte.html
 * @appliesMixin FBP
 */
class TableObjekte extends FBP(LitElement) {
  constructor() {
    super();
    this.fields = {};
  }

  /**
   *
   * @param repeaterNode
   */
  bindData(repeaterNode) {
    this.fields = repeaterNode;
    repeaterNode.addEventListener('repeated-fields-changed', () => {
      this._FBPTriggerWire('--data-changed', this.fields);
      this.requestUpdate();
    });
    this._FBPTriggerWire('--data-changed', this.fields);
    this.requestUpdate();
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent(this.name) ||
      css`
        :host {
          display: block;
          font-size: 14px;
        }

        :host([hidden]) {
          display: none;
        }

        table {
          width: 100%;
          max-width: 100%;
          margin-bottom: 2rem;
          background-color: #fff;
        }
        tr {
          transition: all 0.3s ease;
        }
        tr-Objekte:nth-child(odd) {
          background-color: #f5f5f5;
        }
        tr-Objekte:hover {
          background-color: rgba(0, 0, 0, 0.12);
        }
        th {
          text-align: left;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <h3>${i18n.t('rapport_abstitel_objekte')}</h3>
      <table cellpadding="0" cellspacing="0">
        <thead>
          <tr>
            <th>${i18n.t('rapport_tabtitel_objekt')}</th>
            <th>${i18n.t('rapport_tabtitel_beschreibung')}</th>
            <th>${i18n.t('rapport_tabtitel_serviceart')}</th>
            <th>${i18n.t('rapport_tabtitel_grund')}</th>
          </tr>
        </thead>
        <tbody>
          <template
            is="flow-repeat"
            ƒ-inject-items="--data-changed(*.repeats)"
            ƒ-select="--itemSelected"
            ƒ-deselect="--addClicked"
          >
            <tr-objekte ƒ-bind-data="--item"></tr-objekte>
          </template>
        </tbody>
      </table>
    `;
  }
}

window.customElements.define('table-objekte', TableObjekte);
