import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';
import './bes-list.js';
import './bes-detail.js';
import '../../project-components/mora-app-drawer.js';
/**
 * `view-nachbestellung`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-view-nachbestellung
 * @appliesMixin FBP
 */
class ViewNachbestellung extends FBP(LitElement) {
  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('ViewNachbestellung') ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }
        furo-app-bar-top {
          height: 48px;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-vertical-flex>
        <furo-app-bar-top drawer="nachbestellung">
          <main-menu flex page-name="nachbestellung" ƒ-refresh="--pageActivated"></main-menu>
        </furo-app-bar-top>

        <mora-app-drawer name="nachbestellung" float-breakpoint="1020">
          <bes-list
            slot="drawer"
            drawer="nachbestellung"
            @-item-clicked="--listItemclicked"
            @-add-bestellung-clicked="--addClicked"
            ƒ-set-qp="--pageActivated(*.query)"
            ƒ-reload-list="--bestellungChanged, --bestellungCreated"
          ></bes-list>


          <bes-detail
            ƒ-set-qp="--locationChanged(*.query)"
            @-bestellung-created="--bestellungCreated"
            @-bestellung-saved="--bestellungChanged"
            @-bestellung-deleted="--bestellungChanged, --bestellungDeletedQP"
          ></bes-detail>

      </furo-vertical-flex>
      </furo-app-drawer>

      <!-- wir hören auf die URL -->
      <furo-location
        url-space-regex="${window.APPROOT}/nachbestellung"
        @-location-changed="--locationChanged"
      ></furo-location>

      <!-- addClicked gibt uns einen HTS rein und wir kriegen die qp -->
      <furo-reverse-deep-link
        service="Bestellung"
        rel="list" @-converted="--addQp"
        ƒ-convert="--addClicked"
      ></furo-reverse-deep-link>

      <furo-reverse-deep-link
        service="Bestellung"
        rel="self" @-converted="--itemQp"
        ƒ-convert="--listItemclicked(*.links), --bestellungCreated(*.links)"
      ></furo-reverse-deep-link>

      <!-- wir schreiben die QP in die URL -->
      <furo-qp-changer clear="bes" ƒ-set-qp="--itemQp,--addQp,--bestellungDeletedQP"></furo-qp-changer>
    `;
  }
}

window.customElements.define('view-nachbestellung', ViewNachbestellung);
