import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme';
import { FBP } from '@furo/fbp';
import './tb-history.js';

/**
 * `history-list`
 * Historie der Arbeiten an einem Objekt
 * todo Tabelle mit echten Daten füllen
 *
 * @summary Objekthistorie
 * @customElement
 * @demo demo/history-list.html
 * @appliesMixin FBP
 */
class HistoryList extends FBP(LitElement) {
  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires();
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent(this.name) ||
      css`
        :host {
          display: block;
          background-color: var(--surface, white);
          font-size: 14px;
          padding: 12px 24px;
          height: 100%;
          box-sizing: border-box;
        }

        :host([hidden]) {
          display: none;
        }

        table {
          margin-top: 24px;
        }
        th {
          font-weight: 400;
          color: var(--primary);
        }

        th {
          text-align: left;
        }

        h1 {
          font-size: 20px;
          height: 40px;
          line-height: 56px;
          margin: 0px;
          font-weight: normal;
        }
      `
    );
  }

  /**
   * Query Params
   * @param qp
   */
  setQp(qp) {
    this._FBPTriggerWire('--qpIn', qp);
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <h1>Historie</h1>

      <table width="100%">
        <thead>
          <tr>
            <th>Datum</th>
            <th>Beschreibung</th>
            <th>Zuständigkeit</th>
          </tr>
        </thead>
        <tbody>
          <template is="flow-repeat" ƒ-inject-items="--historyList(*.entities)">
            <tb-history ƒ-bind-data="--item" ƒ-index="--index"></tb-history>
          </template>
        </tbody>
      </table>

      <furo-deep-link service="History" ƒ-qp-in="--qpIn" @-hts-out="--hts"></furo-deep-link>
      <furo-collection-agent
        service="History"
        ƒ-hts-in="--hts"
        @-hts-injected="--htsin"
        ƒ-list="--htsin"
        @-response="--historyList"
      ></furo-collection-agent>
    `;
  }
}

window.customElements.define('history-list', HistoryList);
