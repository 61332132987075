import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme';
import { FBP } from '@furo/fbp';
import '../../project-components/show-date.js';

/**
 * `support-card`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo/support-card.html
 * @appliesMixin FBP
 */
class SupportCard extends FBP(LitElement) {
  constructor() {
    super();

    this.header1_br_class = 'display';
    this.header2_br_class = 'display';
    this.header3_br_class = 'display';
    this.label1_t1_br_class = 'display';
    this.label1_t2_br_class = 'display';
    this.label2_t1_br_class = 'display';
    this.label2_t2_br_class = 'display';
  }

  injectItem(entity) {
    this.data = entity.data;
    if (this.data.dringlichkeit._value === 'Hoch') {
      this.dringend = true;
    } else {
      this.dringend = false;
    }
    this.entity = entity;

    this.status = entity.data.status._value;

    this._FBPTriggerWire('--data', entity.data);

    const today = new Date();
    const parsedate = new Date(
      this.data.termindatum.year._value,
      this.data.termindatum.month._value,
      this.data.termindatum.day._value,
    );

    this.isOldSupport = SupportCard.daysBetween(today, parsedate) >= 7;

    if (this.data.kachel_header1._value.length === 0) {
      this.header1_br_class = 'nodisplay';
    }
    if (this.data.kachel_header2._value.length === 0) {
      this.header2_br_class = 'nodisplay';
    }
    if (this.data.kachel_header3._value.length === 0) {
      this.header3_br_class = 'nodisplay';
    }
    if (this.data.kachel_label1_text1._value.length === 0) {
      this.label1_t1_br_class = 'nodisplay';
    }
    if (this.data.kachel_label1_text2._value.length === 0) {
      this.label1_t2_br_class = 'nodisplay';
    }
    if (this.data.kachel_label2_text1._value.length === 0) {
      this.label2_t1_br_class = 'nodisplay';
    }
    if (this.data.kachel_label2_text2._value.length === 0) {
      this.label2_t2_br_class = 'nodisplay';
    }
    this.requestUpdate();
  }

  static daysBetween(date1, date2) {
    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24;

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(date1 - date2);

    // Convert back to days and return
    return Math.round(differenceMs / ONE_DAY);
  }

  /**
   * @private
   * @return {Object}
   */
  static get properties() {
    return {
      /**
       * Dringlichkeits Status
       */
      dringend: {
        type: Boolean,
        reflect: true,
      },
      /**
       * Abgeschlossen Status
       */
      status: {
        type: Number,
        reflect: true,
      },
      /**
       * Ein Support gilt als alt wenn er eine Woche über dem Termindatum ist.
       */
      isOldSupport: {
        type: Boolean,
        reflect: true,
      },

      /**
       * Supportkachel br-Attribute abhängig davon ob ein Text vorhanden ist
       */
      header1_br_class: {
        type: String,
        reflect: true,
      },
      header2_br_class: {
        type: String,
        reflect: true,
      },
      header3_br_class: {
        type: String,
        reflect: true,
      },
      label1_t1_br_class: {
        type: String,
        reflect: true,
      },
      label1_t2_br_class: {
        type: String,
        reflect: true,
      },
      label2_t1_br_class: {
        type: String,
        reflect: true,
      },
      label2_t2_br_class: {
        type: String,
        reflect: true,
      },
    };
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent(this.name) ||
      css`
        :host {
          display: block;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
            0 3px 1px -2px rgba(0, 0, 0, 0.2);
          background-color: white;
          width: var(--kachel-breite);
          height: 270px;
          margin: 24px 12px 0 12px;
          float: left;
          border-radius: 4px;
          font-size: 14px;
          box-sizing: border-box;
          border-left: 10px solid #fff;
          border-right: 10px solid #fff;
        }

        div[flex] {
          position: relative;
          cursor: pointer;
        }

        .btnbar {
          border-top: 1px solid gainsboro;
          padding: 2px 8px;
        }

        show-date.date {
          font-size: 12px;
          color: var(--primary);
          position: absolute;
          left: 8px;
          top: 4px;
        }

        div.supnr {
          font-size: 12px;
          color: gray;
          position: absolute;
          right: 4px;
          top: 4px;
        }

        h3 {
          font-weight: 300;
          padding: 8px;
          margin-top: 24px;
        }

        div.txt {
          position: absolute;
          padding: 8px;
          top: 120px;
        }

        span {
          color: gray;
          font-size: 12px;
          display: block;
        }

        :host([dringend]) .btnbar span {
          color: var(--dringend-color, #cc2222);
        }

        :host([dringend]) {
          border-left: 10px solid var(--dringend-color, #cc2222);
        }

        :host([status='1']) {
          border-right: solid orange 10px;
          border-radius: 4px;
        }

        :host([status='2']) {
          border-right: solid rgba(0, 128, 0, 0.42) 10px;
          border-radius: 4px;
        }

        :host([isOldSupport]) {
          background-color: #e2e2e2;
        }

        .nodisplay {
          display: none;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <!-- wir schicken entity.links._value weil das im furo-reverse-deep-link in der view supporte gebraucht wird -->
      <furo-vertical-flex @-click="^^support-card-clicked(entity.links._value)">
        <div flex>
          <show-date class="date" ƒ-bind-data="--data(*.termindatum)"></show-date>
          <div class="supnr">${this.data.supportnr}</div>
          <h3>
            ${this.data.kachel_header1}<br class="${this.header1_br_class}" />
            ${this.data.kachel_header2}<br class="${this.header2_br_class}" />
            ${this.data.kachel_header3}<br class="${this.header3_br_class}" />
            ${this.data.kachel_header4}
          </h3>

          <div class="txt">
            <span class="label">${this.data.kachel_label1}</span>
            ${this.data.kachel_label1_text1}<br class="${this.label1_t1_br_class}" />
            ${this.data.kachel_label1_text2}<br class="${this.label1_t2_br_class}" />
            <span class="label">${this.data.kachel_label2}</span>
            ${this.data.kachel_label2_text1}<br class="${this.label2_t1_br_class}" />
            ${this.data.kachel_label2_text2}<br class="${this.label2_t2_br_class}" />
          </div>
          <paper-ripple></paper-ripple>
        </div>
        <div class="btnbar">
          <span>${this.data.dringlichkeitstext}</span>
        </div>
      </furo-vertical-flex>
    `;
  }
}

window.customElements.define('support-card', SupportCard);
