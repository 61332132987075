import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';
import { i18n } from '@furo/framework';

/**
 * `part-sichtpruefung`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-part-sichtpruefung
 * @appliesMixin FBP
 */
class PartSichtpruefung extends FBP(LitElement) {
  bindData(d) {
    this._FBPTriggerWire('--fields', d);
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('PartSichtpruefung') ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }

        h3 {
          margin-top: 56px;
          font-weight: Normal;
          font-size: 24px;
          letter-spacing: 0;
        }

        .io-nio > furo-data-checkbox-input {
          width: 80px;
        }

        furo-form-layouter > div {
          line-height: 67px;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <h3>${i18n.t('objekt-protokoll_abs_sichtpruefung')}</h3>

      <furo-form-layouter four>
        <div double>${i18n.t('objekt-protokoll_label_schutzleiter')}</div>
        <furo-horizontal-flex class="io-nio">
          <furo-data-checkbox-input
            condensed
            ƒ-bind-data="--fields(*.sp1_io_l)"
            @-click="--sp1_io"
            ƒ-uncheck="--sp1_nio"
          ></furo-data-checkbox-input>
          <furo-data-checkbox-input
            condensed
            ƒ-bind-data="--fields(*.sp1_nio_l)"
            @-click="--sp1_nio"
            ƒ-uncheck="--sp1_io"
          ></furo-data-checkbox-input>
        </furo-horizontal-flex>
      </furo-form-layouter>

      <furo-form-layouter four>
        <div double>${i18n.t('objekt-protokoll_label_potentialausgleich_vorhanden')}</div>
        <furo-horizontal-flex class="io-nio">
          <furo-data-checkbox-input
            condensed
            ƒ-bind-data="--fields(*.sp9_io_l)"
            @-click="--sp9_io"
            ƒ-uncheck="--sp9_nio"
          ></furo-data-checkbox-input>

          <furo-data-checkbox-input
            condensed
            ƒ-bind-data="--fields(*.sp9_nio_l)"
            @-click="--sp9_nio"
            ƒ-uncheck="--sp9_io"
          ></furo-data-checkbox-input>
        </furo-horizontal-flex>
      </furo-form-layouter>

      <furo-form-layouter four>
        <div double>${i18n.t('objekt-protokoll_label_typenschild_warnhinweis_kennzeichen')}</div>
        <furo-horizontal-flex class="io-nio">
          <furo-data-checkbox-input
            condensed
            ƒ-bind-data="--fields(*.sp2_io_l)"
            @-click="--sp2_io"
            ƒ-uncheck="--sp2_nio"
          ></furo-data-checkbox-input>
          <furo-data-checkbox-input
            condensed
            ƒ-bind-data="--fields(*.sp2_nio_l)"
            @-click="--sp2_nio"
            ƒ-uncheck="--sp2_io"
          ></furo-data-checkbox-input>
        </furo-horizontal-flex>
      </furo-form-layouter>

      <furo-form-layouter four>
        <div double>${i18n.t('objekt-protokoll_label_gehaeuse_schutzabdeckung')}</div>
        <furo-horizontal-flex class="io-nio">
          <furo-data-checkbox-input
            condensed
            ƒ-bind-data="--fields(*.sp3_io_l)"
            @-click="--sp3_io"
            ƒ-uncheck="--sp3_nio"
          ></furo-data-checkbox-input>
          <furo-data-checkbox-input
            condensed
            ƒ-bind-data="--fields(*.sp3_nio_l)"
            @-click="--sp3_nio"
            ƒ-uncheck="--sp3_io"
          ></furo-data-checkbox-input>
        </furo-horizontal-flex>
      </furo-form-layouter>

      <furo-form-layouter four>
        <div double>
          ${i18n.t('objekt-protokoll_label_anschlussleitung_befestigung_zugentlastung')}
        </div>
        <furo-horizontal-flex class="io-nio">
          <furo-data-checkbox-input
            condensed
            ƒ-bind-data="--fields(*.sp4_io_l)"
            @-click="--sp4_io"
            ƒ-uncheck="--sp4_nio"
          ></furo-data-checkbox-input>
          <furo-data-checkbox-input
            condensed
            ƒ-bind-data="--fields(*.sp4_nio_l)"
            @-click="--sp4_nio"
            ƒ-uncheck="--sp4_io"
          ></furo-data-checkbox-input>
        </furo-horizontal-flex>
      </furo-form-layouter>

      <furo-form-layouter four>
        <div double>${i18n.t('objekt-protokoll_label_kuehlluftoeffnungen_luftfilter')}</div>
        <furo-horizontal-flex class="io-nio">
          <furo-data-checkbox-input
            condensed
            ƒ-bind-data="--fields(*.sp5_io_l)"
            @-click="--sp5_io"
            ƒ-uncheck="--sp5_nio"
          ></furo-data-checkbox-input>
          <furo-data-checkbox-input
            condensed
            ƒ-bind-data="--fields(*.sp5_nio_l)"
            @-click="--sp5_nio"
            ƒ-uncheck="--sp5_io"
          ></furo-data-checkbox-input>
        </furo-horizontal-flex>
      </furo-form-layouter>

      <furo-form-layouter four>
        <div double>${i18n.t('objekt-protokoll_label_isolierteile')}</div>
        <furo-horizontal-flex class="io-nio">
          <furo-data-checkbox-input
            condensed
            ƒ-bind-data="--fields(*.sp6_io_l)"
            @-click="--sp6_io"
            ƒ-uncheck="--sp6_nio"
          ></furo-data-checkbox-input>
          <furo-data-checkbox-input
            condensed
            ƒ-bind-data="--fields(*.sp6_nio_l)"
            @-click="--sp6_nio"
            ƒ-uncheck="--sp6_io"
          ></furo-data-checkbox-input>
        </furo-horizontal-flex>
      </furo-form-layouter>

      <furo-form-layouter four>
        <div double>${i18n.t('objekt-protokoll_label_anzeichen_von_ueberlastung')}</div>
        <furo-horizontal-flex class="io-nio">
          <furo-data-checkbox-input
            condensed
            ƒ-bind-data="--fields(*.sp7_io_l)"
            @-click="--sp7_io"
            ƒ-uncheck="--sp7_nio"
          ></furo-data-checkbox-input>
          <furo-data-checkbox-input
            condensed
            ƒ-bind-data="--fields(*.sp7_nio_l)"
            @-click="--sp7_nio"
            ƒ-uncheck="--sp7_io"
          ></furo-data-checkbox-input>
        </furo-horizontal-flex>
      </furo-form-layouter>

      <furo-form-layouter four>
        <div double>${i18n.t('objekt-protokoll_label_unzulaessige_eingriffe')}</div>
        <furo-horizontal-flex class="io-nio">
          <furo-data-checkbox-input
            condensed
            ƒ-bind-data="--fields(*.sp8_io_l)"
            @-click="--sp8_io"
            ƒ-uncheck="--sp8_nio"
          ></furo-data-checkbox-input>
          <furo-data-checkbox-input
            condensed
            ƒ-bind-data="--fields(*.sp8_nio_l)"
            @-click="--sp8_nio"
            ƒ-uncheck="--sp8_io"
          ></furo-data-checkbox-input>
        </furo-horizontal-flex>
      </furo-form-layouter>
    `;
  }
}

window.customElements.define('part-sichtpruefung', PartSichtpruefung);
