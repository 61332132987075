import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';
import { i18n } from '@furo/framework';

/**
 * `part-messungen`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-part-messungen
 * @appliesMixin FBP
 */
class PartMessungen extends FBP(LitElement) {
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires();

    this._FBPAddWireHook('--clearSlwMWert', () => {
      this.fields.mess_slw_mwert._value = null;
    });

    this._FBPAddWireHook('--clearIwMWert', () => {
      this.fields.mess_iw_mwert._value = null;
    });

    this._FBPAddWireHook('--clearSlsMWert', () => {
      this.fields.mess_sls_mwert._value = null;
    });

    this._FBPAddWireHook('--clearDsMWert', () => {
      this.fields.mess_ds_mwert._value = null;
    });

    this._FBPAddWireHook('--clearBsMWert', () => {
      this.fields.mess_bs_mwert._value = null;
    });

    this._FBPAddWireHook('--clearEasMWert', () => {
      this.fields.mess_eas_mwert._value = null;
    });
  }

  bindData(d) {
    this.fields = d;
    this._FBPTriggerWire('--fields', d);
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('PartMessungen') ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }

        .io-nio > furo-data-checkbox-input {
          width: 80px;
        }

        .io-nio > div {
          line-height: 67px;
        }

        h3 {
          margin-top: 56px;
          font-weight: Normal;
          font-size: 24px;
          letter-spacing: 0;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <h3>${i18n.t('objekt-protokoll_abs_messungen')}</h3>
      <h4>${i18n.t('objekt-protokoll_schlusstext_messungen')}</h4>
      <furo-form
        full
        header-text="${i18n.t('objekt-protokoll_label_slw_beschreibung')} ${i18n.t(
          'objekt-protokoll_label_slw_grenzwerttext',
        )}"
        secondary-text="${i18n.t('objekt-protokoll_table_titel_grenzwert')}: ${i18n.t(
          'objekt-protokoll_label_slw_grenzwert',
        )} ${i18n.t('objekt-protokoll_tooltip_1s')}"
      >
        <furo-form-layouter four>
          <furo-data-number-input
            hint=""
            condensed
            ƒ-bind-data="--fields(*.mess_slw_mwert)"
            trailing-icon="clear"
            @-trailing-icon-clicked="--clearSlwMWert"
            ƒ-focus="--clearSlwMWert"
          ></furo-data-number-input>
          <furo-horizontal-flex class="io-nio">
            <furo-data-checkbox-input
              condensed
              ƒ-bind-data="--fields(*.mess_slw_io_l)"
              @-click="--slw_io"
              ƒ-uncheck="--slw_nio"
            ></furo-data-checkbox-input>
            <furo-data-checkbox-input
              condensed
              ƒ-bind-data="--fields(*.mess_slw_nio_l)"
              @-click="--slw_nio"
              ƒ-uncheck="--slw_io"
            ></furo-data-checkbox-input>
          </furo-horizontal-flex>
          <furo-data-text-input
            double
            condensed
            ƒ-bind-data="--fields(*.mess_slw_text)"
          ></furo-data-text-input>
        </furo-form-layouter>
      </furo-form>

      <furo-form
        full
        header-text="${i18n.t('objekt-protokoll_label_iw_beschreibung')} ${i18n.t(
          'objekt-protokoll_label_iw_grenzwerttext',
        )}"
        secondary-text="${i18n.t('objekt-protokoll_table_titel_grenzwert')}: ${i18n.t(
          'objekt-protokoll_label_iw_grenzwert',
        )} ${i18n.t('objekt-protokoll_tooltip_2s')}"
      >
        <furo-form-layouter four>
          <furo-data-number-input
            condensed
            ƒ-bind-data="--fields(*.mess_iw_mwert)"
            trailing-icon="clear"
            @-trailing-icon-clicked="--clearIwMWert"
            ƒ-focus="--clearIwMWert"
          ></furo-data-number-input>
          <furo-horizontal-flex class="io-nio">
            <furo-data-checkbox-input
              condensed
              ƒ-bind-data="--fields(*.mess_iw_io_l)"
              @-click="--iw_io"
              ƒ-uncheck="--iw_nio"
            ></furo-data-checkbox-input>
            <furo-data-checkbox-input
              condensed
              ƒ-bind-data="--fields(*.mess_iw_nio_l)"
              @-click="--iw_nio"
              ƒ-uncheck="--iw_io"
            ></furo-data-checkbox-input>
          </furo-horizontal-flex>
          <furo-data-text-input
            double
            condensed
            ƒ-bind-data="--fields(*.mess_iw_text)"
          ></furo-data-text-input>
        </furo-form-layouter>
      </furo-form>

      <furo-form
        full
        header-text="${i18n.t('objekt-protokoll_label_sls_beschreibung')} ${i18n.t(
          'objekt-protokoll_label_sls_grenzwerttext',
        )}"
        secondary-text="${i18n.t('objekt-protokoll_table_titel_grenzwert')}: ${i18n.t(
          'objekt-protokoll_label_sls_grenzwert',
        )} ${i18n.t('objekt-protokoll_tooltip_4s')} ${i18n.t('objekt-protokoll_tooltip_3s')}"
      >
        <furo-form-layouter four>
          <furo-data-number-input
            condensed
            ƒ-bind-data="--fields(*.mess_sls_mwert)"
            trailing-icon="clear"
            @-trailing-icon-clicked="--clearSlsMWert"
            ƒ-focus="--clearSlsMWert"
          ></furo-data-number-input>

          <furo-horizontal-flex class="io-nio">
            <furo-data-checkbox-input
              condensed
              ƒ-bind-data="--fields(*.mess_sls_io_l)"
              @-click="--sls_io"
              ƒ-uncheck="--sls_nio"
            ></furo-data-checkbox-input>
            <furo-data-checkbox-input
              condensed
              ƒ-bind-data="--fields(*.mess_sls_nio_l)"
              @-click="--sls_nio"
              ƒ-uncheck="--sls_io"
            ></furo-data-checkbox-input>
          </furo-horizontal-flex>

          <furo-data-text-input
            double
            condensed
            ƒ-bind-data="--fields(*.mess_sls_text)"
          ></furo-data-text-input>
        </furo-form-layouter>
      </furo-form>

      <furo-form
        full
        header-text="${i18n.t('objekt-protokoll_label_ds_beschreibung')} ${i18n.t(
          'objekt-protokoll_label_ds_grenzwerttext',
        )}"
        secondary-text="${i18n.t('objekt-protokoll_label_ds_zusatz')}
        ${i18n.t('objekt-protokoll_table_titel_grenzwert')}: ${i18n.t(
          'objekt-protokoll_label_ds_grenzwert',
        )} ${i18n.t('objekt-protokoll_tooltip_4s')}"
      >
        <furo-form-layouter four>
          <furo-data-number-input
            condensed
            ƒ-bind-data="--fields(*.mess_ds_mwert)"
            trailing-icon="clear"
            @-trailing-icon-clicked="--clearDsMWert"
            ƒ-focus="--clearDsMWert"
          ></furo-data-number-input>
          <furo-horizontal-flex class="io-nio">
            <furo-data-checkbox-input
              condensed
              ƒ-bind-data="--fields(*.mess_ds_io_l)"
              @-click="--ds_io"
              ƒ-uncheck="--ds_nio"
            ></furo-data-checkbox-input>

            <furo-data-checkbox-input
              condensed
              ƒ-bind-data="--fields(*.mess_ds_nio_l)"
              @-click="--ds_nio"
              ƒ-uncheck="--ds_io"
            ></furo-data-checkbox-input>
          </furo-horizontal-flex>
          <furo-data-text-input
            double
            condensed
            ƒ-bind-data="--fields(*.mess_ds_text)"
          ></furo-data-text-input>
        </furo-form-layouter>
      </furo-form>

      <furo-form
        full
        header-text="${i18n.t('objekt-protokoll_label_bs_beschreibung')} ${i18n.t(
          'objekt-protokoll_label_bs_grenzwerttext',
        )}"
        secondary-text="${i18n.t('objekt-protokoll_table_titel_grenzwert')}: ${i18n.t(
          'objekt-protokoll_label_bs_grenzwert',
        )} ${i18n.t('objekt-protokoll_tooltip_text')}"
      >
        <furo-form-layouter four>
          <furo-data-number-input
            condensed
            ƒ-bind-data="--fields(*.mess_bs_mwert)"
            trailing-icon="clear"
            @-trailing-icon-clicked="--clearBsMWert"
            ƒ-focus="--clearBsMWert"
          ></furo-data-number-input>
          <furo-horizontal-flex class="io-nio">
            <furo-data-checkbox-input
              condensed
              ƒ-bind-data="--fields(*.mess_bs_io_l)"
              @-click="--bs_io"
              ƒ-uncheck="--bs_nio"
            ></furo-data-checkbox-input>

            <furo-data-checkbox-input
              condensed
              ƒ-bind-data="--fields(*.mess_bs_nio_l)"
              @-click="--bs_nio"
              ƒ-uncheck="--bs_io"
            ></furo-data-checkbox-input>
          </furo-horizontal-flex>
          <furo-data-text-input
            double
            condensed
            ƒ-bind-data="--fields(*.mess_bs_text)"
          ></furo-data-text-input>
        </furo-form-layouter>
      </furo-form>

      <furo-form
        full
        header-text="${i18n.t('objekt-protokoll_label_eas_beschreibung')} ${i18n.t(
          'objekt-protokoll_label_eas_grenzwerttext',
        )}"
        secondary-text="${i18n.t('objekt-protokoll_table_titel_grenzwert')}: ${i18n.t(
          'objekt-protokoll_label_eas_grenzwert',
        )} ${i18n.t('objekt-protokoll_tooltip_4s')} ${i18n.t('objekt-protokoll_tooltip_3s')}"
      >
        <furo-form-layouter four>
          <furo-data-number-input
            condensed
            ƒ-bind-data="--fields(*.mess_eas_mwert)"
            trailing-icon="clear"
            @-trailing-icon-clicked="--clearEasMWert"
            ƒ-focus="--clearEasMWert"
          ></furo-data-number-input>
          <furo-horizontal-flex class="io-nio">
            <furo-data-checkbox-input
              condensed
              ƒ-bind-data="--fields(*.mess_eas_io_l)"
              @-click="--eas_io"
              ƒ-uncheck="--eas_nio"
            ></furo-data-checkbox-input>

            <furo-data-checkbox-input
              condensed
              ƒ-bind-data="--fields(*.mess_eas_nio_l)"
              @-click="--eas_nio"
              ƒ-uncheck="--eas_io"
            ></furo-data-checkbox-input>
          </furo-horizontal-flex>
          <furo-data-text-input
            double
            condensed
            ƒ-bind-data="--fields(*.mess_eas_text)"
          ></furo-data-text-input>
        </furo-form-layouter>
      </furo-form>
    `;
  }
}

window.customElements.define('part-messungen', PartMessungen);
