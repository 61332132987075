import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme';
import { FBP } from '@furo/fbp';
import '../../project-components/show-date.js';

/**
 * `display-ersatzteile`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo/display-ersatzteile.html
 * @appliesMixin FBP
 */
class TrErsatzteile extends FBP(LitElement) {
  constructor() {
    super();
    this.fields = {};
  }

  /**
   *
   * @param fieldNode
   */
  bindData(fieldNode) {
    this.fields = fieldNode;
    fieldNode.addEventListener('field-value-changed', () => {
      this.requestUpdate();
    });
    this._FBPTriggerWire('--data', fieldNode);
    this.requestUpdate();
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent(this.name) ||
      css`
        :host {
          display: table-row;
        }

        :host([hidden]) {
          display: none;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <td><show-date ƒ-bind-data="--data(*.tag)"></show-date></td>
      <td>${this.fields.menge}</td>
      <td>${this.fields.display_artikel} - ${this.fields.display_artikelbezeichnung}</td>
      <td>${this.fields.display_objectnumber} - ${this.fields.display_objecttext}</td>
      <td>${this.fields.display_user}</td>
    `;
  }
}

window.customElements.define('tr-ersatzteile', TrErsatzteile);
