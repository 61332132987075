import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme';
import { FBP } from '@furo/fbp';
import { i18n } from '@furo/framework';

/**
 * `panel-support-notiz`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo/panel-support-notiz.html
 * @appliesMixin FBP
 */

class PanelSupportNotiz extends FBP(LitElement) {
  constructor() {
    super();
    // leeres object damit es immer vorhanden ist
    this.fields = {};
  }

  bindData(d) {
    this.fields = d.data;
    this._FBPTriggerWire('--data', this.fields);
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('PanelSupportNotiz') ||
      css`

        :host {
          display: block;
          background-color: var(--surface, white);
          font-size: 14px;
          padding: 12px 24px;
          height: 100%;
          box-sizing: border-box;
        }

        :host([hidden]) {
          display: none;
        }

      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-form
        header-text="${i18n.t('support-notiz_main_titel')}"
        ƒ-start-activity="--startActivity"
        ƒ-stop-activity="--stopActivity"
      >
        <furo-form-layouter two breakpoint-big="810,405" breakpoint-small="380">
          <furo-data-textarea-input
            full
            ƒ-bind-data="--data(*.notiz)"
            rows="7"
          ></furo-data-textarea-input>
        </furo-form-layouter>
      </furo-form>

    `;
  }

}

window.customElements.define('panel-support-notiz', PanelSupportNotiz);
