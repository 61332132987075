import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme';
import { FBP } from '@furo/fbp';
import '../../project-components/data-display.js';

/**
 * `objekt-detail`
 * Card mit Objekt Details
 * todo: Formatierung der Card, momentan sind einfach alle daten dargestellt
 *
 * @summary detail card for supportcaseobject
 * @customElement
 * @demo demo/objekt-detail.html
 * @appliesMixin FBP
 */
class SupportcaseobjectDrawer extends FBP(LitElement) {
  constructor() {
    super();
    this.fields = {
      garantiebis: {
        day: 0,
        month: 0,
        year: 0,
      },
    };
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    //  this._FBPTraceWires()
    /**
     * Register hook on wire --sco to
     * bind the fields
     */
    this._FBPAddWireHook('--sco', e => {
      this.fields = e;
      this._FBPTriggerWire('--data', this.fields);

      e.addEventListener('data-injected', () => {
        this.requestUpdate();
      });
    });
  }

  /**
   * Query Params
   * @param qp
   */
  htsIn(hts) {
    this._FBPTriggerWire('--hts', hts);
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent(this.name) ||
      css`
        :host {
          display: block;
          background-color: white;
          width: var(--kachel-breite);
          height: 100%;
          font-size: 14px;
        }

        :host([hidden]) {
          display: none;
        }

        div.num {
          color: gray;
          padding: 12px;
        }

        div.serial {
          color: var(--primary);
          padding: 12px;
        }

        h3 {
          font-weight: 300;
          padding: 12px;
          margin-top: 0;

          background-color: var(--primary);
          color: var(--on-primary);
        }

        div.txt {
          padding: 8px;
        }

        .label {
          margin-top: 10px;
          color: gray;
          font-size: 12px;
          display: block;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <furo-vertical-flex>
        <furo-horizontal-flex>
          <div class="serial">${this.fields.subnr}</div>
          <div flex></div>
          <div class="num">${this.fields.objektnr}</div>
        </furo-horizontal-flex>

        <h3>
          <furo-vertical-flex>
            <data-display ƒ-bind="--sco(*.data.sidebar_header1)"></data-display>
            <data-display ƒ-bind="--sco(*.data.sidebar_header2)"></data-display>
            <data-display ƒ-bind="--sco(*.data.sidebar_header3)"></data-display>
            <data-display ƒ-bind="--sco(*.data.sidebar_header4)"></data-display>
          </furo-vertical-flex>
        </h3>

        <div class="txt">
          <furo-vertical-flex>
            <span class="label"
              ><data-display ƒ-bind="--sco(*.data.sidebar_info1)"></data-display
            ></span>
            <data-display ƒ-bind="--sco(*.data.sidebar_info1text1)"></data-display>
            <data-display ƒ-bind="--sco(*.data.sidebar_info1text2)"></data-display>
            <data-display ƒ-bind="--sco(*.data.sidebar_info1text3)"></data-display>
            <data-display ƒ-bind="--sco(*.data.sidebar_info1text4)"></data-display>

            <span class="label"
              ><data-display ƒ-bind="--sco(*.data.sidebar_info2)"></data-display
            ></span>
            <data-display ƒ-bind="--sco(*.data.sidebar_info2text1)"></data-display>
            <data-display ƒ-bind="--sco(*.data.sidebar_info2text2)"></data-display>
            <data-display ƒ-bind="--sco(*.data.sidebar_info2text3)"></data-display>
            <data-display ƒ-bind="--sco(*.data.sidebar_info2text4)"></data-display>

            <span class="label"
              ><data-display ƒ-bind="--sco(*.data.sidebar_info3)"></data-display
            ></span>
            <data-display ƒ-bind="--sco(*.data.sidebar_info3text1)"></data-display>
            <data-display ƒ-bind="--sco(*.data.sidebar_info3text2)"></data-display>
            <data-display ƒ-bind="--sco(*.data.sidebar_info3text3)"></data-display>
            <data-display ƒ-bind="--sco(*.data.sidebar_info3text4)"></data-display>

            <span class="label"
              ><data-display ƒ-bind="--sco(*.data.sidebar_info4)"></data-display
            ></span>
            <data-display ƒ-bind="--sco(*.data.sidebar_info4text1)"></data-display>
            <data-display ƒ-bind="--sco(*.data.sidebar_info4text2)"></data-display>
            <data-display ƒ-bind="--sco(*.data.sidebar_info4text3)"></data-display>
            <data-display ƒ-bind="--sco(*.data.sidebar_info4text4)"></data-display>
          </furo-vertical-flex>
        </div>
      </furo-vertical-flex>

      <furo-entity-agent
        service="Supportcaseobject"
        ƒ-hts-in="--hts"
        load-on-hts-in
        @-response="--rawEntity"
      ></furo-entity-agent>
      <furo-data-object
        type="supportcaseobject.ObjectEntity"
        ƒ-inject-raw="--rawEntity"
        @-object-ready="--sco"
        ƒ-init="--qpInjected"
      ></furo-data-object>
    `;
  }
}

window.customElements.define('supportcaseobject-drawer', SupportcaseobjectDrawer);
