import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme';
import { FBP } from '@furo/fbp';
import { i18n } from '@furo/framework';

/**
 * `bev-card`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo/bev-card.html
 * @appliesMixin FBP
 */
class BevCard extends FBP(LitElement) {
  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()
  }

  injectItem(entity) {
    this.data = entity.data;
    this.queryParams = {
      bev: entity.data.id,
    };

    this._FBPTriggerWire('--qpBEV', this.queryParams);
  }

  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent(this.name) ||
      css`
        :host {
          display: block;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
            0 3px 1px -2px rgba(0, 0, 0, 0.2);
          background-color: white;
          width: var(--kachel-breite);
          height: 270px;
          margin: 24px 12px 0 12px;
          float: left;
          border-radius: 4px;
          font-size: 14px;
          box-sizing: border-box;
          border-left: 10px solid #fff;
          border-right: 10px solid #fff;
        }

        div[flex] {
          position: relative;
          cursor: pointer;
        }

        .btnbar {
          border-top: 1px solid gainsboro;
          padding: 0px 0px;
        }

        show-date.date {
          font-size: 12px;
          color: var(--primary);
          position: absolute;
          left: 8px;
          top: 4px;
        }

        div.supnr {
          font-size: 12px;
          color: gray;
          position: absolute;
          left: 4px;
          top: 4px;
        }

        div.objnr {
          font-size: 12px;
          color: gray;
          position: absolute;
          right: 4px;
          top: 4px;
        }

        h3 {
          font-weight: 300;
          padding: 8px;
          margin-top: 24px;
        }

        div.txt {
          position: absolute;
          padding: 8px;
          top: 120px;
        }

        span {
          color: gray;
          font-size: 12px;
          display: block;
        }

        furo-ripple.success {
          --furo-ripple-bg-color: var(--success, #5ef47f);
        }

        furo-ripple.fail {
          --furo-ripple-bg-color: var(--error, #fe3f2b);
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <furo-vertical-flex>
        <div flex>
          <div class="supnr">${this.data.display_supportcase}</div>
          <div class="objnr">${this.data.display_objectnumber}</div>
          <h3>
            ${this.data.menge} x ${this.data.display_artikel}<br />
            ${this.data.display_artikelbezeichnung}<br />
            <br />
          </h3>
          <div class="txt">
            <span class="label">${i18n.t('betsellvorschlag_label_entnommen_vom_monteur')}:</span>
            ${this.data.display_creator}<br />
            <br />
          </div>
          <furo-ripple
            noink
            class="success"
            ƒ-trigger="--acceptResponse, --rejectResponse"
          ></furo-ripple>
          <furo-ripple noink class="fail" ƒ-trigger="--acceptError, --rejectError"></furo-ripple>
        </div>
        <furo-horizontal-flex class="btnbar">
          <furo-button
            raised
            primary
            flex
            label="${i18n.t('_label_bestellen')}"
            @-click="--acceptClicked"
          ></furo-button>
          <furo-button
            raised
            danger
            flex
            label="${i18n.t('_label_loeschen')}"
            @-click="--rejectClicked"
          ></furo-button>
        </furo-horizontal-flex>
      </furo-vertical-flex>

      <furo-deep-link service="Bestellvorschlag" ƒ-qp-in="--qpBEV" @-hts-out="--htsBEV">
      </furo-deep-link>

      <furo-custom-method
        method="accept"
        service="Bestellvorschlag"
        ƒ-trigger="--acceptClicked"
        ƒ-hts-in="--htsBEV"
        @-response="--acceptResponse, ^^bestellvorschlag-reload"
        @-response-error="--acceptError, ^^bestellvorschlag-reload"
      ></furo-custom-method>

      <furo-custom-method
        method="reject"
        service="Bestellvorschlag"
        ƒ-trigger="--rejectClicked"
        ƒ-hts-in="--htsBEV"
        @-response="--rejectResponse, ^^bestellvorschlag-reload"
        @-response-error="--rejectError, ^^bestellvorschlag-reload"
      ></furo-custom-method>
    `;
  }
}

window.customElements.define('bev-card', BevCard);
