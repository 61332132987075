import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';

import '../../menu/objekt-menu.js';
import './panel-support-notiz.js';
import '../../project-components/detail-button-bar.js';

/**
 * `view-support-notiz`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-view-support-notiz
 * @appliesMixin FBP
 */

class ViewSupportNotiz extends FBP(LitElement) {
  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    //  this._FBPTraceWires()
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('ViewSupportNotiz') ||
      css`
        :host {
          display: block;
          background-color: var(--surface, white);
          font-size: 14px;
          height: 100%;
          box-sizing: border-box;
        }

        :host([hidden]) {
          display: none;
        }
        furo-app-bar-top {
          height: 48px;
        }

        furo-ripple.success {
          --furo-ripple-bg-color: var(--success, #5ef47f);
        }

        furo-ripple.fail {
          --furo-ripple-bg-color: var(--error, #fe3f2b);
        }

        detail-button-bar {
          padding: 12px 24px;
        }

      `
    );
  }



  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-vertical-flex>
        <furo-app-bar-top drawer="support-notiz">
          <objekt-menu flex page-name="s-support-notiz" ƒ-refresh="--pageActivated"></objekt-menu>
        </furo-app-bar-top>

          <panel-support-notiz
            scroll
            ƒ-bind-data="--supportcasenotizEntity"
          ></panel-support-notiz>

          <detail-button-bar
            ƒ-bind-entity="--supportcasenotizEntity"
            @-save-clicked="--saveClicked"
          ></detail-button-bar>

      </furo-vertical-flex>


      <furo-deep-link
        service="Supportcasenotiz"
        ƒ-qp-in="--pageActivated(*.query)"
        @-hts-out="--htsNotiz"
      ></furo-deep-link>

      <furo-entity-agent
        service="Supportcasenotiz"
        ƒ-hts-in="--htsNotiz"
        ƒ-load="--htsUpdated"
        ƒ-bind-request-data="--supportcasenotizEntity(*.data)"
        @-hts-injected="--htsUpdated"
        @-save-success="--saveSuccess"
        ƒ-save="--saveClicked"
        @-response="--supportcasenotizRecived"
        @-response-error="--error"
      ></furo-entity-agent>


      <furo-data-object
        type="supportcase.SupportcasenotizEntity"
        ƒ-clear-all-errors="--supportcasenotizRecived, --saveSuccess"
        ƒ-inject-raw="--supportcasenotizRecived, --saveSuccess"
        @-object-ready="--supportcasenotizEntity"
      ></furo-data-object>

      <furo-ripple noink class="success" ƒ-trigger="--saveSuccess"></furo-ripple>
      <furo-ripple noink class="fail" ƒ-trigger="--error"></furo-ripple>

      <furo-app-flow event="supportnotiz-back-to-support-clicked" ƒ-emit="--saveSuccess"></furo-app-flow>


    `;
  }
}

window.customElements.define('view-support-notiz', ViewSupportNotiz);
