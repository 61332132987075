import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';
import { i18n } from '@furo/framework/src/i18n';
import '@furo/route/src/furo-app-flow';
import '@furo/layout/src/furo-horizontal-flex';
import '@furo/data/src/furo-catalog.js';
// import './project-components/user-profile'

/**
 * `objekt-menu`
 * Das ist die Kopfzeile von Mora
 *
 * @summary todo shortdescription
 * @customElement
 * @appliesMixin FBP
 */
class ObjektMenu extends FBP(LitElement) {
  /**
   * @private
   * @return {Object}
   */
  static get properties() {
    return {
      /**
       * Name der Aktuellen Seite
       */
      pageName: {
        type: String,
        attribute: 'page-name',
      },
      pageTitle: {
        type: String,
      },
    };
  }

  set pageName(v) {
    // set pagetitle when pagename is set from outside
    this.pageTitle = i18n.t(`menu_titel_${v}`);
    this._pageTitle = v;
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires();

    let menuelement = this._pageTitle;
    if (menuelement === 'objektprotokoll') {
      menuelement = 'support-detail';
    }
    if (menuelement === 'objekt-history') {
      menuelement = 'support-detail';
    }
    // aktives element in der navigation anwählen und setzen
    const a = this.shadowRoot.getElementById(menuelement);
    if (a) {
      a.setAttribute('active', true);
    }
  }

  refresh() {
    this._FBPTriggerWire('--toggleUserProfile', true);
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('MainHeader') ||
      css`
        :host {
          display: block;
          color: var(--on-primary);
          font-size: 19px;
          height: 48px;
          white-space: nowrap;
        }

        div {
          line-height: 42px;
          padding: 4px 10px 0 10px;
          border-bottom: 2px solid transparent;
        }

        user-profile {
          line-height: 42px;
          padding: 4px 10px 0 10px;
          cursor: pointer;
        }

        div[active] {
          border-bottom: 2px solid var(--secondary);
        }

        div[pointer] {
          cursor: pointer;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-horizontal-flex>
        <furo-icon-button icon="home" @-click="--HomeClicked"></furo-icon-button>
        <div flex class="title">${this.pageTitle}</div>
        <user-profile
          flex
          ƒ-bind-data="--userprofileEntity(*.data)"
          @-click="--UserprofileClicked"
        ></user-profile>
        <div pointer id="s-support-detail" @-click="--SupportClicked">
          ${i18n.t('menu_titel_s-support-detail')}
        </div>
        <div pointer id="s-zeiterfassung" @-click="--ArbeitszeitClicked">
          ${i18n.t('menu_titel_s-zeiterfassung')}
        </div>
        <div pointer id="s-ersatzteile" @-click="--ErsatzteileClicked">
          ${i18n.t('menu_titel_s-ersatzteile')}
        </div>
        <div pointer id="s-ausgaben" @-click="--AusgabenClicked">
          ${i18n.t('menu_titel_s-ausgaben')}
        </div>
        <div pointer id="s-rapport" @-click="--RapportClicked">
          ${i18n.t('menu_titel_s-rapport')}
        </div>

        <furo-icon-button icon="power-settings-new" @-click="^^logout-request"></furo-icon-button>
      </furo-horizontal-flex>

      <furo-location @-location-query-changed="--qp"></furo-location>
      <furo-app-flow
        ƒ-set-qp="--qp(*.query)"
        event="menu-item-support-detail-clicked"
        ƒ-trigger="--SupportClicked"
      ></furo-app-flow>
      <furo-app-flow
        ƒ-set-qp="--qp(*.query)"
        event="menu-item-s-zeiterfassung-clicked"
        ƒ-trigger="--ArbeitszeitClicked"
      ></furo-app-flow>
      <furo-app-flow
        ƒ-set-qp="--qp(*.query)"
        event="menu-item-s-ersatzteile-clicked"
        ƒ-trigger="--ErsatzteileClicked"
      ></furo-app-flow>
      <furo-app-flow
        ƒ-set-qp="--qp(*.query)"
        event="menu-item-s-ausgaben-clicked"
        ƒ-trigger="--AusgabenClicked"
      ></furo-app-flow>
      <furo-app-flow
        ƒ-set-qp="--qp(*.query)"
        event="menu-item-s-rapport-clicked"
        ƒ-trigger="--RapportClicked"
      ></furo-app-flow>

      <furo-app-flow event="home-clicked" ƒ-emit="--HomeClicked"></furo-app-flow>
      <furo-app-flow event="userprofile-clicked" ƒ-emit="--UserprofileClicked"></furo-app-flow>
      <!-- Datenkomponenten um das Userprofil zu laden -->
      <furo-deep-link
        service="Userprofile"
        ƒ-qp-in="--toggleUserProfile"
        @-hts-out="--htsUserprofile"
      ></furo-deep-link>

      <furo-entity-agent
        service="Userprofile"
        ƒ-hts-in="--htsUserprofile"
        @-hts-injected="--htsUserprofileUpdated"
        ƒ-load="--htsUserprofileUpdated"
        @-response="--userprofileReceived"
      ></furo-entity-agent>

      <furo-data-object
        type="userprofile.UserprofileEntity"
        ƒ-inject-raw="--userprofileReceived"
        ƒ-init="--pageDeActivated"
        @-data-injected="--userprofileEntityInjected"
        @-object-ready="--userprofileEntity"
      ></furo-data-object>
    `;
  }
}

window.customElements.define('objekt-menu', ObjektMenu);
