import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';
import './asg-list.js';
import './asg-detail.js';
/**
 * `view-ausgaben`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-view-ausgaben
 * @appliesMixin FBP
 */
class ViewAusgaben extends FBP(LitElement) {
  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires();
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('ViewAusgaben') ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }
        furo-app-bar-top {
          height: 48px;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-vertical-flex>
        <furo-app-bar-top drawer="ausgaben">
          <main-menu flex page-name="ausgaben" ƒ-refresh="--pageActivated"></main-menu>
        </furo-app-bar-top>
        <furo-app-drawer name="ausgaben" float-breakpoint="1020">
          <asg-list
            slot="drawer"
            drawer="ausgaben"
            @-item-clicked="--listItemclicked"
            @-add-time-clicked="--addClicked"
            @-date-selected="--date-selected"
            ƒ-set-qp="--pageActivated(*.query)"
            ƒ-reload-list="--ausgabeChanged, --ausgabeCreated"
          ></asg-list>

          <asg-detail
            ƒ-set-qp="--locationChanged(*.query)"
            ƒ-inject-date="--date-selected"
            ƒ-add-clicked="--addClicked"
            @-ausgabe-created="--ausgabeCreated"
            @-ausgabe-saved="--ausgabeChanged"
            @-ausgabe-deleted="--ausgabeChanged, --ausgabeDeletedQP"
          ></asg-detail>
        </furo-app-drawer>
      </furo-vertical-flex>
      <!-- wir hören auf die URL -->
      <furo-location
        url-space-regex="${window.APPROOT}/ausgaben"
        @-location-changed="--locationChanged"
      ></furo-location>

      <!-- addClicked gibt uns einen HTS rein und wir kriegen die qp -->
      <furo-reverse-deep-link
        service="Ausgabe"
        rel="list"
        @-converted="--addQp"
        ƒ-convert="--addClicked"
      ></furo-reverse-deep-link>

      <furo-reverse-deep-link
        service="Ausgabe"
        rel="self"
        @-converted="--itemQp"
        ƒ-convert="--listItemclicked(*.links)"
      ></furo-reverse-deep-link>

      <!-- wir schreiben die QP in die URL -->
      <furo-qp-changer clear="aus" ƒ-set-qp="--itemQp,--addQp,--ausgabeDeletedQP"></furo-qp-changer>
    `;
  }
}

window.customElements.define('view-ausgaben', ViewAusgaben);
