import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme';
import { FBP } from '@furo/fbp';
import { i18n } from '@furo/framework';
import './tr-reisezeitpauschale-reisezeit.js';
import './tr-reisezeitpauschale-reiseweg.js';
import './tr-reisezeitpauschale-fahrkosten.js';

/**
 * `display-ersatzteile`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo/display-reisezeitpauschalen.html
 * @appliesMixin FBP
 */
class TableReisezeitpauschalen extends FBP(LitElement) {
  constructor() {
    super();
    this.fields = {};
  }

  /**
   *
   * @param repeaterNode
   */
  bindData(repeaterNode) {
    this.fields = repeaterNode;
    repeaterNode.addEventListener('repeated-fields-changed', () => {
      this._FBPTriggerWire('--data-changed', this.fields);
      this.requestUpdate();
    });
    this._FBPTriggerWire('--data-changed', this.fields);
    this.requestUpdate();
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent(this.name) ||
      css`
        :host {
          display: block;
          font-size: 14px;
        }

        :host([hidden]) {
          display: none;
        }

        table {
          width: 100%;
          max-width: 100%;
          margin-bottom: 2rem;
          background-color: #fff;
        }

        tbody div {
          display: contents;
        }

        tr-arbeitszeiten:hover {
          background-color: rgba(0, 0, 0, 0.12);
        }

        tr-arbeitszeiten-colspan:hover {
          background-color: rgba(0, 0, 0, 0.12);
        }

        tr-reisezeitpauschale-reisezeit,
        tr-reisezeitpauschale-reiseweg,
        tr-reisezeitpauschale-fahrkosten {
          transition: all 0.3s ease;
        }

        tr-reisezeitpauschale-reisezeit:nth-child(odd) {
          background-color: #f5f5f5;
        }

        tr-reisezeitpauschale-reiseweg:nth-child(even) {
          background-color: #f5f5f5;
        }

        tr-reisezeitpauschale-fahrkosten:nth-child(odd) {
          background-color: #f5f5f5;
        }

        tr-reisezeitpauschale-reisezeit:hover,
        tr-reisezeitpauschale-reiseweg:hover,
        tr-reisezeitpauschale-fahrkosten:hover {
          background-color: rgba(0, 0, 0, 0.12);
        }

        th {
          text-align: left;
        }

        .menge {
          padding-right: 5px;
          text-align: right;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <h3>${i18n.t('rapport_abstitel_Reisezeitpauschalen')}</h3>
      <table cellpadding="0" cellspacing="0">
        <thead>
          <tr>
            <th>${i18n.t('rapport_tabtitel_datum')}</th>
            <th>${i18n.t('rapport_tabtitel_text')}</th>
            <th class="menge">${i18n.t('rapport_tabtitel_menge')}</th>
            <th>${i18n.t('rapport_tabtitel_einheit')}</th>
            <th>${i18n.t('rapport_tabtitel_mitarbeiter')}</th>
          </tr>
        </thead>
        <tbody>
          <template
            is="flow-repeat"
            ƒ-inject-items="--data-changed(*.repeats)"
            ƒ-select="--itemSelected"
            ƒ-deselect="--addClicked"
          >
            <div>
              <tr-reisezeitpauschale-reisezeit
                ƒ-bind-data="--item"
              ></tr-reisezeitpauschale-reisezeit>
              <tr-reisezeitpauschale-reiseweg ƒ-bind-data="--item"></tr-reisezeitpauschale-reiseweg>
              <tr-reisezeitpauschale-fahrkosten
                ƒ-bind-data="--item"
              ></tr-reisezeitpauschale-fahrkosten>
            </div>
          </template>
        </tbody>
      </table>
    `;
  }
}

window.customElements.define('table-reisezeitpauschalen', TableReisezeitpauschalen);
