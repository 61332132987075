import { LitElement, html, css } from 'lit-element';
import { FBP } from '@furo/fbp';

/**
 * `ers-item`
 * Describe your element
 *
 * @summary shortdescription
 * @customElement
 * @demo demo/ers-item.html
 * @appliesMixin FBP
 */
let tag;
let year;
let month;
let day;

class ErsItem extends FBP(LitElement) {
  select() {
    this.setAttribute('selected', '');
  }

  deselect() {
    this.removeAttribute('selected');
  }

  inject(entity) {
    this.entity = entity;
    this.item = entity.data;

    if (tag !== JSON.stringify(entity.data.tag)) {
      const datediv = document.createElement('div');
      datediv.classList.add('date');
      year = entity.data.tag.year;
      month = entity.data.tag.month;
      day = entity.data.tag.day;

      if (year < 10) {
        year = `0${year}`;
      }

      if (month < 10) {
        month = `0${month}`;
      }

      if (day < 10) {
        day = `0${day}`;
      }

      // datediv.innerHTML = entity.data.tag;
      datediv.innerHTML = `${day}.${month}.${year}`;
      this.parentNode.insertBefore(datediv, this);
      tag = JSON.stringify(entity.data.tag);
      this._datediv = datediv;
    }
  }

  clearDate() {
    tag = undefined;
    this.noformatError =
      "Expected 'this' to be used by class method 'clearDate'  class-methods-use-this";
  }

  disconnectedCallback() {
    if (this._datediv) {
      this._datediv.remove();
    }
  }

  /**
   *
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return css`
      :host {
        display: block;
        border-top: 1px solid gainsboro;
        color: #666666;
        padding: 8px 12px 12px 12px;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;
      }

      :host([selected]) {
        background-color: var(--secondary-color);
      }

      paper-ripple {
        color: var(--secondary-color);
      }

      .nr {
        color: var(--primary);
        font-weight: 500;
        padding-right: 12px;
      }

      .time {
        color: var(--primary);

        width: 120px;
      }
    `;
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <style>
        .obj {
          @apply --zeit-item-mixin;
        }
      </style>
      <div @-click="^^item-clicked(entity),^^list-item-clicked(index)">
        <furo-horizontal-flex>
          <div class="nr">${this.item.display_objectnumber}</div>
          <div flex style="overflow: hidden; white-space: nowrap">
            ${this.item.display_objecttext}
          </div>
        </furo-horizontal-flex>
        <div class="obj">${this.item.display_artikelbezeichnung}</div>

        <furo-horizontal-flex>
          <div class="time" flex>${this.item.menge}x${this.item.display_artikel}</div>
          <div>${this.item.display_lagerort}</div>
        </furo-horizontal-flex>
        <paper-ripple></paper-ripple>
      </div>
    `;
  }
}

window.customElements.define('ers-item', ErsItem);
