import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';
import '@furo/data/src/furo-deep-link.js';
import '@furo/data-ui/src/furo-catalog.js';
import '@furo/form/src/furo-catalog.js';

import './support-card.js';
import './table-filter.js';
import './nav-card.js';

/**
 * `view-supporte`
 * todo Describe your element
 *
 * @customElement
 * @appliesMixin FBP
 */
class ViewSupporte extends FBP(LitElement) {
  /**
   * @private
   * @return {Object}
   */
  static get properties() {
    return {};
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('ViewSupporte') ||
      css`
        :host {
          display: block;
          background: var(--surface);
          height: 100vh;
        }

        :host([hidden]) {
          display: none;
        }

        div[scroll] {
          overflow: auto;
          padding-bottom: 48px;
        }
        furo-app-bar-top {
          height: 48px;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-vertical-flex>
        <furo-app-bar-top>
          <main-menu flex page-name="supporte" ƒ-refresh="--pageActivated"></main-menu>
        </furo-app-bar-top>
        <table-filter @-new-filter="--newFilter"></table-filter>
        <div scroll @-support-card-clicked="--scClicked" ƒ-.scroll-top="--nextPageRequested">
          <nav-card
            icon="arrow-back"
            ƒ-show="--hasPrev"
            ƒ-hide="--noPrev"
            @-click="--prevPageRequested"
          ></nav-card>
          <template is="flow-repeat" ƒ-inject-items="--data(*.entities.repeats)">
            <support-card ƒ-inject-item="--item"></support-card>
          </template>
          <nav-card
            icon="arrow-forward"
            ƒ-show="--hasNext"
            ƒ-hide="--noNext"
            @-click="--nextPageRequested"
          ></nav-card>
        </div>
      </furo-vertical-flex>

      <!--non-visual components -->
      <!-- wenn auf eine Kachel geklickt wurde, wird aus der Entität die QueryParams errechnet  -->
      <furo-reverse-deep-link
        service="Supportcase"
        rel="self"
        ƒ-convert="--scClicked"
        @-converted="--qp"
      ></furo-reverse-deep-link>

      <!-- Mit den QueryParams lösen wir den app-flow aus -->
      <furo-app-flow event="supportcase-card-clicked" ƒ-emit="--qp"></furo-app-flow>

      <!-- Eingehende Verarbeitung -->

      <!-- Wenn die Page --pageActivated auslöst, werden die QP von der URL mitgegeben, aus diesen erzeugen wir ein hts  -->
      <furo-deep-link
        service="Supportcase"
        ƒ-qp-in="--pageActivated(*.query)"
        @-hts-out="--hts"
      ></furo-deep-link>

      <!-- das HTS  wird dem Agent übergeben, wenn er es verarbeitet hat (hts-updated) lösen wir das laden der Liste aus.
       Die daten der Antwort legen wir auf den wire --data
       -->

      <furo-collection-agent
        service="Supportcase"
        ƒ-hts-in="--hts"
        ƒ-set-filter="--newFilter"
        ƒ-next="--nextPageRequested"
        ƒ-prev="--prevPageRequested"
        list-on-hts-in
        @-filter-changed="--filterUpdated"
        @-hts-updated="--collectionHTS"
        ƒ-list="--filterUpdated"
        @-response="--collectionResponse"
      ></furo-collection-agent>

      <furo-rel-exists
        service="Supportcase"
        rel="next"
        @-furo-rel-exists="--hasNext"
        @-rel-dont-exist="--noNext"
        ƒ-inject="--collectionHTS"
      ></furo-rel-exists>
      <furo-rel-exists
        service="Supportcase"
        rel="prev"
        @-furo-rel-exists="--hasPrev"
        @-rel-dont-exist="--noPrev"
        ƒ-inject="--collectionHTS"
      ></furo-rel-exists>
      <furo-data-object
        type="supportcase.SupportcaseCollection"
        ƒ-inject-raw="--collectionResponse"
        @-data-injected="--data"
      >
      </furo-data-object>
    `;
  }
}

window.customElements.define('view-supporte', ViewSupporte);
