import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';
import '../../menu/objekt-menu.js';
import './support-card-detail.js';
import './object-card-list.js';

/**
 * `view-support-detail`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-view-support-detail
 * @appliesMixin FBP
 */
class ViewSupportDetail extends FBP(LitElement) {
  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    //  this._FBPTraceWires()
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('ViewSupportDetail') ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }
        furo-app-bar-top {
          height: 48px;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-vertical-flex>
        <furo-app-bar-top drawer="support-detail">
          <objekt-menu flex page-name="s-support-detail" ƒ-refresh="--pageActivated"></objekt-menu>
        </furo-app-bar-top>
        <furo-app-drawer
          name="support-detail"
          float-breakpoint="1020"
        >
          <support-card-detail @-support-detail-notiz-clicked="--supportdetailnotizClicked"
            slot="drawer"
            drawer="support-detail"
            ƒ-hts-in="--htsSupportcase"
            ƒ-clear="--pageDeActivated"
          >
          </support-card-detail>

          <object-card-list scroll @-support-objekt-clicked="--objektClicked" @-support-objekt-detail-clicked="--objektDetailClicked" @-snr-protokoll-clicked="--snrProtokollClicked"  ƒ-hts-user="--htsUser" ƒ-hts-in="--htsSupportcaseobject" ƒ-clear="--pageDeActivated">
          </object-card-list>

      </furo-vertical-flex>

      <furo-app-flow event="supportobjekt-clicked" ƒ-emit="--objektClicked"></furo-app-flow>
      <furo-app-flow event="supportobjektdetail-clicked" ƒ-emit="--objektDetailClicked"></furo-app-flow>
      <furo-app-flow event="objekt-snr-clicked" ƒ-emit="--snrProtokollClicked"></furo-app-flow>
      <furo-app-flow event="supportnotiz-clicked" ƒ-emit="--supportdetailnotizClicked"></furo-app-flow>


      <furo-deep-link
        service="Supportcase"
        ƒ-qp-in="--pageActivated(*.query)"
        @-hts-out="--htsSupportcase"
      ></furo-deep-link>

      <furo-deep-link
        service="Supportcaseobject"
        ƒ-qp-in="--pageActivated(*.query)"
        @-hts-out="--htsSupportcaseobject"
      ></furo-deep-link>

      <furo-deep-link
        service="User"
        ƒ-qp-in="--pageActivated(*.query)"
        @-hts-out="--htsUser"></furo-deep-link>
    `;
  }
}

window.customElements.define('view-support-detail', ViewSupportDetail);
