import { LitElement, html, css } from 'lit-element';
import { FBP } from '@furo/fbp';

import '@furo/data/src/furo-data-object.js';
import '@furo/data/src/furo-entity-agent.js';

import '@furo/data-input/src/furo-catalog.js';
import '@furo/data-input/src/furo-data-collection-dropdown.js';
import '@furo/form/src/furo-catalog.js';
import './zerf-detail-form.js';
import './make-empty-zeit-object.js';
import '../../project-components/detail-button-bar.js';

/**
 * `zeit-detail`
 * Describe your element
 *
 * @summary shortdescription
 * @customElement
 * @demo demo/zeit-detail.html
 * @appliesMixin FBP
 */
class ZerfDetail extends FBP(LitElement) {
  constructor() {
    super();
    this.vorgabetag = {
      tag: {
        year: 0,
        month: 0,
        day: 0,
      },
    };
  }

  /**
   * Exposes --queryParams
   * @param {Object} det
   */
  setQp(det) {
    // setze auf edit wenn wir zei haben
    this._FBPTriggerWire('--queryParams', det);

    // setze auf neu erstellen wenn wir kein zei haben
    if (det.zei === undefined) {
      this._FBPTriggerWire('--creatorQP', det);
    } else {
      this._FBPTriggerWire('--editQP', det);
    }
    // wir müssen nach dem delete nur den QP leer schicken, damit zei verschwindet
    this.createNewQP = {};
  }

  /**
   * injectDate
   * @param e
   */
  injectDate(e) {
    this.tag = e.tag;

    const year = parseInt(e.tag.substring(0, 4), 10);
    const month = parseInt(e.tag.substring(5, 7), 10);
    const day = parseInt(e.tag.substring(8, 10), 10);

    this.vorgabetag.tag.year = year;
    this.vorgabetag.tag.month = month;
    this.vorgabetag.tag.day = day;

    this._FBPTriggerWire('--dateInjected', this.vorgabetag);
  }

  addTimeClicked(e) {
    this._FBPTriggerWire('--addClicked', e);
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    //  this._FBPTraceWires();
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <furo-vertical-flex style="position: relative">
        <furo-banner-display autofocus=""></furo-banner-display>
        <furo-banner ƒ-parse-grpc-status="--error" icon="warning"></furo-banner>

        <zerf-detail-form
          flex
          scroll
          ƒ-bind-data="--zoReady(*.data)"
          ƒ-object-collection-in="--objekteResponse"
          ƒ-kst-collection-in="--kstResponse"
          ƒ-start-activity="--saveClicked, --createClicked"
          ƒ-stop-activity="--dataLoaded, --saveSuccess, --emptyObject, --createSuccess, --error"
        >
        </zerf-detail-form>

        <furo-ripple noink class="success" ƒ-trigger="--saveSuccess, --createSuccess"></furo-ripple>
        <detail-button-bar
          ƒ-bind-entity="--zoReady"
          @-save-clicked="--saveClicked"
          @-create-clicked="--createClicked"
          @-delete-clicked="--deleteClicked"
        >
        </detail-button-bar>
      </furo-vertical-flex>

      <!-- Ab hier die datenlogik-->
      <furo-deep-link
        service="Zeit"
        ƒ-qp-in="--queryParams, --createdQp"
        @-hts-out="--hts"
      ></furo-deep-link>

      <furo-entity-agent
        service="Zeit"
        ƒ-hts-in="--hts"
        ƒ-load="--editQP"
        ƒ-bind-request-data="--zoReady(*.data)"
        ƒ-delete="--deleteClicked"
        @-delete-success="^^az-deleted(createNewQP)"
        @-save-success="--saveSuccess, ^^az-saved"
        @-create-success="--createSuccess, ^^az-created(createNewQP), --createdQp(createNewQP)"
        ƒ-save="--saveClicked, --createClicked"
        @-load-success="--dataLoaded"
        @-response-error="--error"
      ></furo-entity-agent>

      <furo-data-object
        type="zeit.ZeitEntity"
        ƒ-clear-all-errors="--dataLoaded, --saveSuccess, --emptyObject, --createSuccess"
        ƒ-inject-raw="--dataLoaded, --saveSuccess, --emptyObject"
        @-object-ready="--zoReady"
      ></furo-data-object>

      <furo-deep-link
        service="Kstzeit"
        ƒ-qp-in="--queryParams"
        @-hts-out="--htsKST"
      ></furo-deep-link>

      <furo-collection-agent
        service="Kstzeit"
        ƒ-hts-in="--htsKST"
        list-on-hts-in
        @-response="--kstResponse"
      ></furo-collection-agent>

      <!-- Abschnitt welcher für die Datenermittlung eines neuen Datensatzes abläuft -->

      <make-empty-zeit-object
        ƒ-qp-in="--creatorQP"
        ƒ-date-in="--dateInjected"
        ƒ-trigger-custom-method="--addClicked, --createSuccess"
        @-object-created="--emptyObject"
      ></make-empty-zeit-object>
    `;
  }

  /**
   *
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return css`
      :host {
        display: block;
        background-color: var(--surface, white);
        font-size: 14px;
        padding: 12px 24px;
        height: 100%;
        min-height: auto;

        box-sizing: border-box;
      }

      furo-ripple.success {
        --furo-ripple-bg-color: var(--success, #5ef47f);
      }

      furo-banner-display {
        --furo-icon-fill-color: var(--danger, red);
      }
      zeit-detail-form {
        margin-top: 12px;
      }
    `;
  }
}

window.customElements.define('zerf-detail', ZerfDetail);
