import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';
import '@furo/data/src/furo-deep-link.js';
import '@furo/data-ui/src/furo-catalog.js';
import '@furo/form/src/furo-catalog.js';

import './bev-card.js';
/**
 * `view-bestellvorschlaege`
 *
 * @customElement
 * @appliesMixin FBP
 */
class ViewBestellvorschlaege extends FBP(LitElement) {
  /**
   * @private
   * @return {Object}
   */
  static get properties() {
    return {};
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('ViewBestellvorschlaege') ||
      css`
        :host {
          display: block;
          background: var(--surface);
          height: 100vh;
        }

        :host([hidden]) {
          display: none;
        }

        div[scroll] {
          overflow: auto;
          padding-bottom: 48px;
        }
        furo-app-bar-top {
          height: 48px;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-vertical-flex>
        <furo-app-bar-top>
          <main-menu flex page-name="bestellvorschlaege" ƒ-refresh="--pageActivated"></main-menu>
        </furo-app-bar-top>

        <div @-bestellvorschlag-reload="--bestellvorschladReload">
          <template is="flow-repeat" ƒ-inject-items="--data(*.entities.repeats)">
            <bev-card ƒ-inject-item="--item"></bev-card>
          </template>
        </div>
      </furo-vertical-flex>

      <furo-reverse-deep-link
        service="Bestellvorschlag"
        rel="self"
        ƒ-convert="--scClicked"
        @-converted="--qp"
      ></furo-reverse-deep-link>

      <furo-deep-link
        service="Bestellvorschlag"
        ƒ-qp-in="--pageActivated(*.query)"
        @-hts-out="--hts"
      ></furo-deep-link>

      <furo-collection-agent
        service="Bestellvorschlag"
        ƒ-hts-in="--hts"
        ƒ-load="--bestellvorschladReload"
        list-on-hts-in
        @-hts-updated="--collectionHTS"
        @-response="--collectionResponse"
      ></furo-collection-agent>

      <furo-data-object
        type="bestellvorschlag.BestellvorschlagCollection"
        ƒ-inject-raw="--collectionResponse"
        @-data-injected="--data"
      ></furo-data-object>
    `;
  }
}

window.customElements.define('view-betsellvorschlaege', ViewBestellvorschlaege);
