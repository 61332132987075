import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';
import './history-list.js';
import './supportcaseobject-drawer.js';

/**
 * `view-objekt-history`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-view-objekt-history
 * @appliesMixin FBP
 */
class ViewObjektHistory extends FBP(LitElement) {
  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('ViewObjektHistory') ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }
        furo-app-bar-top {
          height: 48px;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-vertical-flex>
        <furo-app-bar-top drawer="history">
          <objekt-menu flex page-name="s-objekt-history" ƒ-refresh="--pageActivated"></objekt-menu>
        </furo-app-bar-top>

        <furo-app-drawer name="history" float-breakpoint="1020">
          <supportcaseobject-drawer
            slot="drawer"
            drawer="history"
            ƒ-hts-in="--htsSupportcaseobject"
          >
          </supportcaseobject-drawer>

          <history-list scroll ƒ-set-qp="--pageActivated(*.query)"></history-list>
        </furo-app-drawer>
      </furo-vertical-flex>

      <furo-deep-link
        service="Supportcaseobject"
        ƒ-qp-in="--pageActivated(*.query)"
        @-hts-out="--htsSupportcaseobject"
      ></furo-deep-link>
    `;
  }
}

window.customElements.define('view-objekt-history', ViewObjektHistory);
