import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';

/**
 * `date-display`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-date-display
 * @appliesMixin FBP
 */
let year;
let month;
let day;

class DateDisplay extends FBP(LitElement) {
  /**
   * @private
   * @return {Object}
   */
  static get properties() {
    return {
      /**
       * Description
       */
      datum: { type: String },
    };
  }

  bind(d) {
    if (d !== undefined) {
      d.addEventListener('field-value-changed', () => {
        year = d.year._value;
        month = d.month._value;
        day = d.day._value;

        if (year < 10) {
          year = `0${year}`;
        }

        if (month < 10) {
          month = `0${month}`;
        }

        if (day < 10) {
          day = `0${day}`;
        }

        this.datum = `${day}.${month}.${year}`;
      });
    }
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('DateDisplay') ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      ${this.datum}
    `;
  }
}

window.customElements.define('date-display', DateDisplay);
