import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme';
import { FBP } from '@furo/fbp';

/**
 * `show-date`
 * Zeigt ein Datum an
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-show-date
 * @appliesMixin FBP
 */
let year;
let month;
let day;

class ShowDate extends FBP(LitElement) {
  bindData(field) {
    if (typeof field !== 'undefined') {
      year = field.year._value;
      month = field.month._value;
      day = field.day._value;

      if (year < 10) {
        year = `0${year}`;
      }

      if (month < 10) {
        month = `0${month}`;
      }

      if (day < 10) {
        day = `0${day}`;
      }

      this.datum = `${day}.${month}.${year}`;
    }
  }

  /**
   * use this if you inject a raw field
   * @param field
   */
  injectRaw(field) {
    if (typeof field !== 'undefined') {
      year = field.year;
      month = field.month;
      day = field.day;

      if (year < 10) {
        year = `0${year}`;
      }

      if (month < 10) {
        month = `0${month}`;
      }

      if (day < 10) {
        day = `0${day}`;
      }

      this.datum = `${day}.${month}.${year}`;
    }
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent(this.name) ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }
      `
    );
  }

  /**
   * @private
   * @return {Object}
   */
  static get properties() {
    return {
      /**
       * Dringlichkeits Status
       */
      datum: {
        type: String,
      },
    };
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      ${this.datum}
    `;
  }
}

window.customElements.define('show-date', ShowDate);
