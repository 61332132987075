import { LitElement, html, css } from 'lit-element';
import { FBP } from '@furo/fbp';
import { i18n } from '@furo/framework';
import '../../project-components/textbaustein-reference-search.js';

/**
 * `zerf-detail-form`
 * Describe your element
 *
 * @summary shortdescription
 * @customElement
 * @demo demo/zerf-detail-form.html
 * @appliesMixin FBP
 */
class ZerfDetailForm extends FBP(LitElement) {
  /**
   * Collection der KST
   * @param {}
   */
  kstCollectionIn(det) {
    this._FBPTriggerWire('--kstCollectionInjected', det);
  }

  /**
   * Collection der Objekte
   * @param {}
   */
  objectCollectionIn(det) {
    this._FBPTriggerWire('--objectCollectionInjected', det);
  }

  // zurücksetzen
  clear() {
    this._disableObjectText = true;
    this._textbausteinActive = false;
    this.requestUpdate();
    this._FBPTriggerWire('--itemCleared');
  }

  bindData(d) {
    this.fields = d;

    this._FBPTriggerWire('--data', d);

    if (this.fields.__eventListener['field-value-changed'] !== undefined) {
      this.fields.__eventListener['field-value-changed'].length = 0;
    }

    this.fields.addEventListener('field-value-changed', e => {
      if (e.detail._name === 'halbertag_l' && e.detail._value) {
        this.sollzeitenfields.tag._value = this.fields.tag._value;
        this.sollzeitenfields.halbertag_l._value = this.fields.halbertag_l._value;

        this._FBPTriggerWire('--sollzeitTrigger');
      }
      if (e.detail._name === 'ganzertag_l' && e.detail._value) {
        this.sollzeitenfields.tag._value = this.fields.tag._value;
        this.sollzeitenfields.halbertag_l._value = this.fields.halbertag_l._value;

        this._FBPTriggerWire('--sollzeitTrigger');
      }
    });

    d.ulid_supportcaseobject.addEventListener('field-value-changed', e => {
      this._disableObjectText = e.target._value.length === 0;
      this.display_objecttext = this.fields.display_objecttext;
      this.display_kostenstelletext = this.fields.display_kostenstelle;
      this.requestUpdate();
    });

    d.textbaustein_active.addEventListener('field-value-changed', e => {
      this._textbausteinActive = e.detail._value;
      this.requestUpdate();
    });
  }

  constructor() {
    super();
    this.fields = {};
    this.sollzeitenfields = {};
    this._disableObjectText = true;
    this._textbausteinActive = false;

    this._FBPAddWireHook('--sollzeitCalcObjIn', e => {
      this.sollzeitenfields = e;
    });

    this._FBPAddWireHook('--userzeitObjectBack', e => {
      this.fields.tag._value = e.data.tag;
      this.fields.zeit_von._value = e.data.zeit_von;
      this.fields.zeit_bis._value = e.data.zeit_bis;
      this.fields.halbertag_l._value = e.data.halbertag_l;
      this.fields.ganzertag_l._value = e.data.ganzertag_l;
    });
  }

  /**
   * ƒlow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires();
  }

  /**
   * Startet die progressbar vom form
   */
  startActivity() {
    this._FBPTriggerWire('--startActivity', null);
  }

  /**
   * Stopt die progressbar vom form
   */
  stopActivity() {
    this._FBPTriggerWire('--stopActivity', null);
  }

  /**
   *
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return css`
      :host {
        display: block;
        position: relative;
      }

      .obenRechts furo-data-text-input {
        width: 70px;
      }

      .obenRechts furo-data-number-input {
        width: 80px;
        margin-left: 12px;
      }

      .obenRechts {
        position: absolute;
        right: 0px;
        top: 0;
      }
    `;
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <furo-form
        header-text="${i18n.t('zeiterfassung_main_titel')}"
        ƒ-start-activity="--startActivity"
        ƒ-stop-activity="--stopActivity"
      >
        <div class="obenRechts">
          <furo-data-number-input
            condensed
            float
            disabled
            ƒ-bind-data="--data(*.faktor)"
          ></furo-data-number-input>
          <furo-data-number-input
            condensed
            disabled
            ƒ-bind-data="--data(*.dauer)"
          ></furo-data-number-input>
        </div>

        <furo-form-layouter two breakpoint-big="810,405" breakpoint-small="380">
          <furo-data-date-input ƒ-bind-data="--data(*.tag)"></furo-data-date-input>

          <furo-form-layouter two breakpoint-big="400,300" breakpoint-small="200">
            <furo-data-time-input
              ƒ-focus="--data"
              ƒ-bind-data="--data(*.zeit_von)"
            ></furo-data-time-input>
            <furo-data-time-input ƒ-bind-data="--data(*.zeit_bis)"></furo-data-time-input>
          </furo-form-layouter>

          <furo-data-checkbox-input
            condensed
            label="1/2 Tag"
            ƒ-bind-data="--data(*.halbertag_l)"
            @-click="--halbertag"
            ƒ-uncheck="--ganzertag"
          ></furo-data-checkbox-input>

          <furo-data-checkbox-input
            condensed
            label="1/1 Tag"
            ƒ-bind-data="--data(*.ganzertag_l)"
            @-click="--ganzertag"
            ƒ-uncheck="--halbertag"
          ></furo-data-checkbox-input>

          <div ?hidden=${this._disableObjectText}>
            ${i18n.t('zeiterfassung_label_objekt')}: ${this.display_objecttext}
          </div>

          <div ?hidden=${this._disableObjectText}>
            ${i18n.t('s-zeiterfassung_label_kostenstelle')}: ${this.display_kostenstelletext}
          </div>

          <furo-data-collection-dropdown
            ?hidden=${!this._disableObjectText}
            full
            ƒ-inject-entities="--kstCollectionInjected(*.entities)"
            ƒ-bind-data="--data(*.ulid_kostenstelle)"
            display-field="text"
          ></furo-data-collection-dropdown>

          <textbaustein-reference-search
            ?hidden=${!this._textbausteinActive}
            ƒ-bind-text="--data(*.text)"
            min-term-length="2"
            @-item-selected="--refSearchItemSelected"
            ƒ-set-filter="--artikelfilter"
            full
          ></textbaustein-reference-search>

          <furo-data-textarea-input
            full
            label="${i18n.t('s-zeiterfassung_label_text')}"
            ƒ-bind-data="--data(*.text)"
            rows="8"
          ></furo-data-textarea-input>
        </furo-form-layouter>

        <!-- ripple effekt wenn ein element links gewählt wurde -->
        <paper-ripple class="new" noink ƒ-simulated-ripple="--itemCleared"></paper-ripple>
        <paper-ripple class="injected" noink ƒ-simulated-ripple="--entityInjected"></paper-ripple>

        <!-- Service zur Ermittlung der Sollzeiten für die Erfassung 1/2 Tag 1/1 Tag -->
        <furo-data-object
          type="sollzeit.Calculate"
          @-object-ready="--sollzeitCalcObjIn"
        ></furo-data-object>

        <furo-deep-link
          ƒ-trigger="--sollzeitTrigger"
          service="Sollzeit"
          @-hts-out="--userzeitHTS"
        ></furo-deep-link>
        <furo-custom-method
          service="Sollzeit"
          method="calculate"
          ƒ-hts-in="--userzeitHTS"
          @-response="--userzeitObjectBack"
          @-hts-updated="--loopBack"
          ƒ-trigger="--loopBack"
          ƒ-bind-request-data="--sollzeitCalcObjIn"
        ></furo-custom-method>
      </furo-form>
    `;
  }
}

window.customElements.define('zerf-detail-form', ZerfDetailForm);
