import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';

/**
 * `supportcase-supportnr-display`
 * Zeigt den Benutzernamen mit einem Icon des aktuell angemeldeten Bernutzers an
 *
 * @summary Wird für die Anzeige der Support-Nr verwendet.
 * @customElement
 * @demo demo-show-date
 * @appliesMixin FBP
 */

class SupportcaseSupportnrDisplay extends FBP(LitElement) {
  constructor() {
    super();
    this.supportnr = '';
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()
  }

  bindData(d) {
    d.supportnr.addEventListener('field-value-changed', e => {
      this.supportnr = e.target._value;
      this.requestUpdate();
    });
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent(this.name) ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }

        .sc {
          color: var(--primary);
          line-height: 64px;
          font-size: 20px;
          text-align: center;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <div class="sc">
        ${this.supportnr}
      </div>
    `;
  }
}

window.customElements.define('supportcase-supportnr-display', SupportcaseSupportnrDisplay);
