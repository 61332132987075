import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';

/**
 * ´display_img´
 * Wird für die Anzeige von Bildern img-Tags verwendet
 *
 */

class DisplayImg extends FBP(LitElement) {
  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()
  }

  bindData(data) {
    if (data !== undefined) {
      data.addEventListener('field-value-changed', e => {
        if (e.target._value !== undefined) {
          this.img = e.target._value;
        } else {
          this.img = '';
        }

        this.requestUpdate();
      });
    }
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('DisplayImg') ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }

        img {
          width: 100%;
        }
      `
    );
  }

  render() {
    // language=HTML
    return html`
      <img src="${this.img}" alt="" />
    `;
  }
}

window.customElements.define('display-img', DisplayImg);
