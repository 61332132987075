import { LitElement, html, css } from 'lit-element';
import { FBP } from '@furo/fbp';
import { i18n } from '@furo/framework';
import '@furo/data-input/src/furo-data-reference-search';
import '@furo/data-input/src/furo-data-checkbox-input';
import '../../project-components/artikel-reference-search.js';
import '../../project-components/display_img.js';

/**
 * `ers-detail-form`
 * Describe your element
 *
 * @summary shortdescription
 * @customElement
 * @demo demo/ers-detail-form.html
 * @appliesMixin FBP
 */
class ErsDetailForm extends FBP(LitElement) {
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires();

    // Artikelfilter gebaut, damit dem Artikelreference-Searcher
    // mitgegeben werden kann, ob alle Artikel (keine Filterangabe)
    // oder nur archivierte Artikel ("true") bzw. nicht archivierte artikel ("false")
    // zurückgegeben werden sollen.

    const filter = [];
    const artikelArchivFilter = ['archiv_l', 'eq', false];
    filter.push(artikelArchivFilter);
    this._FBPTriggerWire('--artikelfilter', filter);

    // Mittels diesem Hook wird der Wert der Verkaufseinheit des Ersatzteils
    // mit der Verkaufseinheit des aus dem Dropdown ausgewählten Artikels gesetzt.
    this._FBPAddWireHook('--refSearchItemSelected', e => {
      this.fields.display_artikelvke._value = e.data.vke;
      if (this.fields.display_artikelvke._value == null) {
        this.fields.display_artikelvke._value = '';
      }

      // Diese Logik setzt den Wert von artikelbildUrl vom ausgewählten Artikel aus dem Dropdown
      // in das Feld display_artikelbildUrl des Ersatzteils, damit wird immer das aktuelle Bild angezeigt,
      // auch wenn der Artikel geändert wird.

      // Prüfen ob der aktuell ausgwählte Artikel ein Bild bild mitbringt,
      // wenn ja, dann wird das neue Bild bzw. die Url im Ersatzteil gesetzt
      // wenn nicht, wird die BildUrl auf dem Ersatzteil geleert.

      if (e.data.artikelbildUrl !== undefined) {
        this.fields.display_artikelbildUrl._value = e.data.artikelbildUrl;
      } else {
        this.fields.display_artikelbildUrl._value = '';
      }

      // Diese Logik überprüft ob der ausgewählte Artikel ein diversartikel ist.
      // Ist dies der Fall, so soll es möglich sein ein Artikeltext zu setzen,
      // ansonsten soll dieses Feld Readonly sein.
      // Der Hook greift nur beim Wechseln des Artikels im Reference Searcher
      // Wenn ein neues Item aus der Liste geladen wird, greift dieser Hook nicht,
      // dann wir anhand des Display_diversartikel_l flags auf dem Server entschieden ob
      // der Fat-String artikeltext, das Label readonly gesetzt bekommt.
      // Wird ein neues Ersatzteil erstellt, so wird per default, im make-empty-ersatzteil-object.js
      // definiert das ein Readonly-Label für das Feld artikeltext (fat.String-Typ) gesetzt wird.

      if (e.data.divers_l == null) {
        // Readonly setzen wenn nicht vorhanden

        // -- So würde es funktionieren --
        const val = this.fields.artikeltext.labels._value;
        val.readonly = true;
        this.fields.artikeltext.labels._value = val;

        // Zusätzlich soll beim setzen von readonly auf dem artikeltext der artikeltext selbst geleert werden
        this.fields.artikeltext.value._value = '';
      } else {
        // Readonly entfernen falls vorhanden
        this.fields.artikeltext.labels._value = {};
      }

      // Custom-Methode für Lagerbestandsermittlung triggern
      // nachdem der Lagerort geändert wurde.
      this.setUpForCustomMethod(this.fields.ulid_lagerort._value, e.data.id);
    });

    this._FBPAddWireHook('--lagerbestandObjReady', e => {
      this.lagerbestandObjIn = e;
    });

    this._FBPAddWireHook('--lagerbestandObjBack', e => {
      if (e.data.lagerbestand === undefined) {
        this.fields.display_lagerbestand.value._value = 0;
      } else {
        this.fields.display_lagerbestand.value._value = e.data.lagerbestand;
      }
    });

    this._FBPAddWireHook('--lagerortValueChanged', e => {
      // Custom-Methode für Lagerbestandsermittlung triggern
      // nachdem der Lagerort geändert wurde.
      this.setUpForCustomMethod(e, this.fields.its_artikel._value.id);
    });

    this._FBPAddWireHook('--clearMenge', () => {
      this.fields.menge._value = null;
    });
  }

  setUpForCustomMethod(lagortUlid, artikelUlid) {
    this.setLagerortUlidForCustomMethod(lagortUlid);
    this.setArtikelUlidForCustomMethod(artikelUlid);
    this._FBPTriggerWire('--lagerbestandObjIn', this.lagerbestandObjIn);
    this._FBPTriggerWire('--triggerCustomMethodLagerbestand');
  }

  setLagerortUlidForCustomMethod(lagortUlid) {
    this.lagerbestandObjIn.lagerortUlid._value = lagortUlid;
  }

  setArtikelUlidForCustomMethod(artikelUlid) {
    this.lagerbestandObjIn.artikelUlid._value = artikelUlid;
  }

  setQp(det) {
    this._FBPTriggerWire('--queryParams', det);
  }

  /**
   * Collection der Objekte
   * @param {}
   */
  objectCollectionIn(det) {
    this._FBPTriggerWire('--objectCollectionInjected', det);
  }

  rawLagerorteCollectionIn(det) {
    // Der erste Lagerort in dieser Collection ist der
    // Lagerort des Benutzers (Monteur-Auto)
    this.userLagerortId = det.entities[0].data.id;
    this._FBPTriggerWire('--lagerortCollectionInjected', det);
  }

  bindData(d) {
    this.fields = d;
    this._FBPTriggerWire('--data', this.fields);

    // Event der beim neuerfassen dafür sorgt, dass das Feld bestellen_l
    // nur dann sichtbar ist, wenn der Lagerort dem Lagerort des Monteurs entspricht.

    this.fields.ulid_lagerort.addEventListener('field-value-changed', e => {
      const attr = this.fields.bestellen_l.attributes._value;

      if (attr.typ === 'create') {
        const val = this.fields.bestellen_l.labels._value;
        val.hidden = e.target._value !== this.userLagerortId;
        if (val.hidden) {
          this.fields.bestellen_l.labels._value = val;
          const bestellenvalue = this.fields.bestellen_l._value;
          bestellenvalue.value = false;
          this.fields.bestellen_l._value = bestellenvalue;
        } else {
          this.fields.bestellen_l.labels._value = undefined;
        }
      }
    });
  }

  /**
   * Startet die progressbar vom form
   */
  startActivity() {
    this._FBPTriggerWire('--startActivity', null);
  }

  /**
   * Stopt die progressbar vom form
   */
  stopActivity() {
    this._FBPTriggerWire('--stopActivity', null);
  }

  /**
   *
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return css`
      :host {
        display: block;
        position: relative;
      }

      img {
        border: 1px solid #ddd; /* thickness and color of border */
        border-radius: 4px; /* edge rounding of border */
      }
    `;
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <furo-form
        header-text="${i18n.t('s-ersatzteile_main_titel')}"
        ƒ-start-activity="--startActivity"
        ƒ-stop-activity="--stopActivity"
      >
        <furo-form-layouter two breakpoint-big="810,405" breakpoint-small="380">
          <furo-data-date-input ƒ-bind-data="--data(*.tag)"></furo-data-date-input>

          <furo-data-collection-dropdown
            full
            ƒ-inject-entities="--objectCollectionInjected(*.entities)"
            ƒ-bind-data="--data(*.ulid_supportcaseobject)"
          ></furo-data-collection-dropdown>

          <furo-data-collection-dropdown
            full
            ƒ-inject-entities="--lagerortCollectionInjected(*.entities)"
            ƒ-bind-data="--data(*.ulid_lagerort)"
            @-value-changed="--lagerortValueChanged"
          ></furo-data-collection-dropdown>

          <artikel-reference-search
            ƒ-bind-data="--data(*.its_artikel)"
            min-term-length="2"
            @-item-selected="--refSearchItemSelected"
            ƒ-set-filter="--artikelfilter"
            full
          ></artikel-reference-search>

          <furo-data-number-input
            full
            readonly
            ƒ-bind-data="--data(*.display_lagerbestand)"
          ></furo-data-number-input>

          <furo-form-layouter breakpoint-big="810,405" breakpoint-small="380">
            <furo-data-text-input ƒ-bind-data="--data(*.artikeltext)"></furo-data-text-input>
            <furo-data-textarea-input
              rows="6"
              ƒ-bind-data="--data(*.notiz)"
              trailing-icon="av:note"
            ></furo-data-textarea-input>
          </furo-form-layouter>

          <display-img ƒ-bind-Data="--data(*.display_artikelbildUrl)"></display-img>

          <furo-data-number-input
            ƒ-bind-data="--data(*.menge)"
            trailing-icon="clear"
            @-trailing-icon-clicked="--clearMenge"
            ƒ-focus="--clearMenge"
            step="0.01"
          ></furo-data-number-input>

          <furo-data-text-input
            readonly="true"
            ƒ-bind-data="--data(*.display_artikelvke)"
          ></furo-data-text-input>

          <furo-data-checkbox-input ƒ-bind-data="--data(*.bestellen_l)"></furo-data-checkbox-input>
        </furo-form-layouter>
      </furo-form>

      <furo-deep-link service="Lagerort" ƒ-qp-in="--queryParams" @-hts-out="--hts"></furo-deep-link>

      <furo-data-object
        type="lagerort.Lagerbestand"
        @-object-ready="--lagerbestandObjReady"
      ></furo-data-object>

      <furo-custom-method
        service="Lagerort"
        method="lagerbestand"
        ƒ-hts-in="--hts"
        @-response="--lagerbestandObjBack"
        @-hts-updated="--htsUpdated"
        ƒ-trigger="--triggerCustomMethodLagerbestand"
        ƒ-bind-request-data="--lagerbestandObjIn"
      ></furo-custom-method>
    `;
  }
}

window.customElements.define('ers-detail-form', ErsDetailForm);
