import { LitElement, html, css } from 'lit-element';
import { FBP } from '@furo/fbp';
import { i18n } from '@furo/framework';

/**
 * `zeit-week-form`
 * Describe your element
 *
 * @summary shortdescription
 * @customElement
 * @demo demo/zeit-week-form.html
 * @appliesMixin FBP
 */
class ZerfWeekForm extends FBP(LitElement) {
  /**
   * Constructor
   */
  constructor() {
    super();
    this.fields = {};
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    //  this._FBPTraceWires();
  }

  bindData(d) {
    this.fields = d.data;
    d.addEventListener('data-injected', () => {
      this._FBPTriggerWire('--data', this.fields);
      this.requestUpdate();
    });
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <furo-collapsible-box label="${i18n.t('zerf-week-form-wochenuebersicht')}" closed="">
        <div slot="context">
          <furo-data-date-input
            label="${i18n.t('zerf-week-form-wochevom')}"
            ƒ-bind-data="--data(*.tag)"
            @-value-changed="^^date-value-changed"
          ></furo-data-date-input>
        </div>

        <furo-horizontal-flex>
          <div flex>${i18n.t('zerf-week-form-wochentag')}</div>
          <div flex>${i18n.t('zerf-week-form-ArbeitszeitExklFakt')}</div>
          <div flex>${i18n.t('zerf-week-form-ArbeitszeitInklFakt')}</div>
        </furo-horizontal-flex>

        <furo-empty-spacer
          style="border-bottom: 1px solid var(--separator-color, gainsboro);"
        ></furo-empty-spacer>

        <furo-horizontal-flex>
          <div flex>${i18n.t('zerf-week-form-day0')}</div>
          <div flex class="dauer">${this.fields.day0_dauer}</div>
          <div flex class="dauer">${this.fields.day0_dauerif}</div>
        </furo-horizontal-flex>
        <furo-horizontal-flex>
          <div flex>${i18n.t('zerf-week-form-day1')}</div>
          <div flex class="dauer">${this.fields.day1_dauer}</div>
          <div flex class="dauer">${this.fields.day1_dauerif}</div>
        </furo-horizontal-flex>
        <furo-horizontal-flex>
          <div flex>${i18n.t('zerf-week-form-day2')}</div>
          <div flex class="dauer">${this.fields.day2_dauer}</div>
          <div flex class="dauer">${this.fields.day2_dauerif}</div>
        </furo-horizontal-flex>
        <furo-horizontal-flex>
          <div flex>${i18n.t('zerf-week-form-day3')}</div>
          <div flex class="dauer">${this.fields.day3_dauer}</div>
          <div flex class="dauer">${this.fields.day3_dauerif}</div>
        </furo-horizontal-flex>
        <furo-horizontal-flex>
          <div flex>${i18n.t('zerf-week-form-day4')}</div>
          <div flex class="dauer">${this.fields.day4_dauer}</div>
          <div flex class="dauer">${this.fields.day4_dauerif}</div>
        </furo-horizontal-flex>
        <furo-horizontal-flex>
          <div flex>${i18n.t('zerf-week-form-day5')}</div>
          <div flex class="dauer">${this.fields.day5_dauer}</div>
          <div flex class="dauer">${this.fields.day5_dauerif}</div>
        </furo-horizontal-flex>
        <furo-horizontal-flex>
          <div flex>${i18n.t('zerf-week-form-day6')}</div>
          <div flex class="dauer">${this.fields.day6_dauer}</div>
          <div flex class="dauer">${this.fields.day6_dauerif}</div>
        </furo-horizontal-flex>

        <furo-vertical-flex>
          <furo-empty-spacer
            style="border-bottom: 1px solid var(--separator-color, gainsboro);"
          ></furo-empty-spacer>
        </furo-vertical-flex>

        <furo-horizontal-flex>
          <div flex>${i18n.t('zerf-week-form-weektotal')}</div>
          <div flex class="dauer">${this.fields.week_dauer}</div>
          <div flex class="dauer">${this.fields.week_dauerif}</div>
        </furo-horizontal-flex>
      </furo-collapsible-box>
    `;
  }

  /**
   *
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return css`
      :host {
        display: block;
        position: relative;
      }

      furo-ripple.success {
        --furo-ripple-bg-color: var(--success, #5ef47f);
      }

      furo-banner-display {
        --furo-icon-fill-color: var(--danger, red);
      }
      zeit-detail-form {
        margin-top: 12px;
      }
    `;
  }
}

window.customElements.define('zerf-week-form', ZerfWeekForm);
