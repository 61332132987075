import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme';
import { FBP } from '@furo/fbp';
import { i18n } from '@furo/framework';

import '../../project-components/typerenderer/mora-type-renderer.js';
import './abschnitt-objektdetail.js';
/**
 * `panel-objektdetail`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo/panel-objektdetail.html
 * @appliesMixin FBP
 */

class PanelObjektdetail extends FBP(LitElement) {
  bindData(d) {
    this.fields = d.data;
    this.fields.addEventListener('new-data-injected', () => {
      this._FBPTriggerWire('--fields', d.data);
    });
  }

  // weiterleiten des pageActivated von oben
  pageActivated(data) {
    this._FBPTriggerWire('--pageActivated', data);
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('PanelObjektdetail') ||
      css`
        :host {
          display: block;
          height: 100vh;
          background-color: white;
          padding: 24px;
        }

        :host([hidden]) {
          display: none;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-form header-text="${i18n.t('menu_titel_objektdetail')}">
        <template
          is="flow-repeat"
          ƒ-inject-items="--fields(*.abschnittlist.repeats)"
          ƒ-clear="--clear"
        >
          <abschnitt-objektdetail ƒ-bind-data="--item" context="form"></abschnitt-objektdetail>
        </template>
      </furo-form>
    `;
  }
}

window.customElements.define('panel-objektdetail', PanelObjektdetail);
