import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';
import { i18n } from '@furo/framework';

/**
 * `part-geraetedaten`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-part-geraetedaten
 * @appliesMixin FBP
 */
class PartGeraetedaten extends FBP(LitElement) {
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires();

    this._FBPAddWireHook('--clearNennspannung', () => {
      this.fields.obj_nennspannung._value = null;
    });

    this._FBPAddWireHook('--clearNennstrom', () => {
      this.fields.obj_nennstrom._value = null;
    });

    this._FBPAddWireHook('--clearNennleistung', () => {
      this.fields.obj_nennleistung._value = null;
    });

    this._FBPAddWireHook('--clearFrequenz', () => {
      this.fields.obj_frequenz._value = null;
    });
  }

  bindData(d) {
    this.fields = d;
    this._FBPTriggerWire('--fields', d);
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('PartGeraetedaten') ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }

        h3 {
          margin-top: 56px;
          font-weight: Normal;
          font-size: 24px;
          letter-spacing: 0;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <h3>${i18n.t('objekt-protokoll_abs_geraetedaten')}</h3>
      <furo-form-layouter four breakpoint-big="810,405" breakpoint-small="380">
        <furo-data-text-input ƒ-bind-data="--fields(*.obj_hersteller)"></furo-data-text-input>
        <furo-data-text-input ƒ-bind-data="--fields(*.obj_typ)"></furo-data-text-input>
        <furo-data-text-input ƒ-bind-data="--fields(*.obj_liefsnr)"></furo-data-text-input>
        <furo-data-text-input ƒ-bind-data="--fields(*.obj_schutzart)"></furo-data-text-input>

        <furo-data-number-input
          ƒ-bind-data="--fields(*.obj_nennspannung)"
          value="0"
          trailing-icon="clear"
          @-trailing-icon-clicked="--clearNennspannung"
          ƒ-focus="--clearNennspannung"
        ></furo-data-number-input>
        <furo-data-number-input
          ƒ-bind-data="--fields(*.obj_nennstrom)"
          value="0"
          trailing-icon="clear"
          @-trailing-icon-clicked="--clearNennstrom"
          ƒ-focus="--clearNennstrom"
        ></furo-data-number-input>
        <furo-data-number-input
          ƒ-bind-data="--fields(*.obj_nennleistung)"
          value="0"
          trailing-icon="clear"
          @-trailing-icon-clicked="--clearNennleistung"
          ƒ-focus="--clearNennleistung"
        ></furo-data-number-input>
        <furo-data-number-input
          ƒ-bind-data="--fields(*.obj_frequenz)"
          value="0"
          trailing-icon="clear"
          @-trailing-icon-clicked="--clearFrequenz"
          ƒ-focus="--clearFrequenz"
        ></furo-data-number-input>
      </furo-form-layouter>
    `;
  }
}

window.customElements.define('part-geraetedaten', PartGeraetedaten);
