import { LitElement, html, css } from 'lit-element';
import { FBP } from '@furo/fbp';
import { i18n } from '@furo/framework';
import '@furo/data-input/src/furo-data-reference-search';
import '@furo/data-input/src/furo-data-checkbox-input';
import '../../project-components/artikel-reference-search.js';

/**
 * `bes-detail-form`
 * Describe your element
 *
 * @summary shortdescription
 * @customElement
 * @demo demo/bes-detail-form.html
 * @appliesMixin FBP
 */
class BesDetailForm extends FBP(LitElement) {
  constructor(props) {
    super(props);
    this.bestellungDO = {};
  }

  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires();

    // Artikelfilter gebaut, damit dem Artikelreference-Searcher
    // mitgegeben werden kann, ob alle Artikel (keine Filterangabe)
    // oder nur archivierte Artikel ("true") bzw. nicht archivierte artikel ("false")
    // zurückgegeben werden sollen.

    const filter = [];
    const artikelArchivFilter = ['archiv_l', 'eq', false];
    filter.push(artikelArchivFilter);
    this._FBPTriggerWire('--artikelfilter', filter);

    this._FBPAddWireHook('--clearMenge', () => {
      this.fields.menge._value = null;
    });

    this._FBPAddWireHook('--lagerbestandObjReady', e => {
      this.lagerbestandObjIn = e;
    });


    // Mittels diesem Hook wird der Wert der Verkaufseinheit des Ersatzteils
    // mit der Verkaufseinheit des aus dem Dropdown ausgewählten Artikels gesetzt.
    this._FBPAddWireHook('--refSearchItemSelected', e => {

      // Custom-Methode für Lagerbestandsermittlung triggern
      // nachdem der Artikel geändert wurde.
      // Die Lagerortid 'self' bezieht sich immer
      // auf den Lagerort des Monteurs
      this.setUpForCustomMethod("self", e.data.id);
    });

    // Lagerbestand der vom Service zurückgemeldet wird hier setzen
    // sofern vorhanden.
    this._FBPAddWireHook('--lagerbestandObjBack', e => {
      if (e.data.lagerbestand === undefined) {
        this.fields.display_lagerbestand.value._value = 0;
      } else {
        this.fields.display_lagerbestand.value._value = e.data.lagerbestand;
      }
    });

  }

  setUpForCustomMethod(lagortUlid, artikelUlid) {
    this.setLagerortUlidForCustomMethod(lagortUlid);
    this.setArtikelUlidForCustomMethod(artikelUlid);
    this._FBPTriggerWire('--lagerbestandObjIn', this.lagerbestandObjIn);
    this._FBPTriggerWire('--triggerCustomMethodLagerbestand');
  }

  setLagerortUlidForCustomMethod(lagortUlid) {
    this.lagerbestandObjIn.lagerortUlid._value = lagortUlid;
  }

  setArtikelUlidForCustomMethod(artikelUlid) {
    this.lagerbestandObjIn.artikelUlid._value = artikelUlid;
  }

  setQp(det) {
    this._FBPTriggerWire('--queryParams', det);
  }

  /**
   * Collection der Objekte
   * @param {}
   */
  objectCollectionIn(det) {
    this._FBPTriggerWire('--objectCollectionInjected', det);
  }

  rawLagerorteCollectionIn(det) {
    this._FBPTriggerWire('--lagerortResponse', det);
  }

  bindData(d) {
    this.fields = d;
    this.fields.addEventListener('branch-value-changed', () => {
      this.bestellungDO = d;
      // wenn beim Tag vom Datum ein Wert ist, verstecken wir das Formular
      if (this.bestellungDO.bestellt_am.day._value !== 0) {
        this.hideForm = true;
      } else {
        this.hideForm = false;
      }
      this.requestUpdate();
    });
    this._FBPTriggerWire('--data', d);
  }

  /**
   * Startet die progressbar vom form
   */
  startActivity() {
    this._FBPTriggerWire('--startActivity', null);
  }

  /**
   * Stopt die progressbar vom form
   */
  stopActivity() {
    this._FBPTriggerWire('--stopActivity', null);
  }

  /**
   *
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return css`
      :host {
        display: block;
        position: relative;
      }
      div[hidden] {
        display: none;
      }

      h1 {
        font-size: 20px;
        height: 40px;
        line-height: 56px;
        margin: 0px;
        font-weight: normal;
      }
      h2 {
        font-size: 16px;
        font-weight: normal;
      }
    `;
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <div ?hidden="${!this.hideForm}">
        <h1>Archivierte Bestellung</h1>
        <h2>${i18n.t('nachbestellung_label_bestelltam')}</h2>
        <date-display ƒ-bind="--data(*.bestellt_am)"></date-display><br />
        <h2>${i18n.t('nachbestellung_label_menge')}</h2>
        ${this.bestellungDO.menge} <br />
        <h2>${i18n.t('nachbestellung_label_artikel')}</h2>
        ${this.bestellungDO.display_artikel} - ${this.bestellungDO.display_artikelbezeichnung}
        <br />
        <h2>${i18n.t('nachbestellung_label_kommission')}</h2>
        ${this.bestellungDO.text} <br />
      </div>
      <furo-form
        ?hidden="${this.hideForm}"
        header-text="${i18n.t('nachbestellung_main_titel')}"
        ƒ-start-activity="--startActivity"
        ƒ-stop-activity="--stopActivity"
      >
        <furo-form-layouter two breakpoint-big="810,405" breakpoint-small="380">
          <furo-data-number-input
            ƒ-bind-data="--data(*.menge)"
            trailing-icon="clear"
            @-trailing-icon-clicked="--clearMenge"
            ƒ-focus="--clearMenge"
          ></furo-data-number-input>

          <furo-data-number-input
            full
            readonly
            ƒ-bind-data="--data(*.display_lagerbestand)"
          ></furo-data-number-input>

          <artikel-reference-search
            ƒ-bind-data="--data(*.its_artikel)"
            min-term-length="2"
            @-item-selected="--refSearchItemSelected"
            ƒ-set-filter="--artikelfilter"
            full
          ></artikel-reference-search>
          <furo-data-text-input full ƒ-bind-data="--data(*.text)"></furo-data-text-input>

          <!--furo-data-date-input readonly ƒ-bind-data="--data(*.bestellt_am)"></furo-data-date-input-->

          <furo-data-checkbox-input
            newline
            ?hidden=${this._hideBestellen}
            ƒ-bind-data="--data(*.freigabe_l)"
          ></furo-data-checkbox-input>
        </furo-form-layouter>
      </furo-form>


      <furo-deep-link service="Lagerort" ƒ-qp-in="--queryParams" @-hts-out="--hts"></furo-deep-link>

      <furo-data-object
        type="lagerort.Lagerbestand"
        @-object-ready="--lagerbestandObjReady"
      ></furo-data-object>

      <furo-custom-method
        service="Lagerort"
        method="lagerbestand"
        ƒ-hts-in="--hts"
        @-response="--lagerbestandObjBack"
        @-hts-updated="--htsUpdated"
        ƒ-trigger="--triggerCustomMethodLagerbestand"
        ƒ-bind-request-data="--lagerbestandObjIn"
      ></furo-custom-method>

    `;
  }
}

window.customElements.define('bes-detail-form', BesDetailForm);
