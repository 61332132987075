import { LitElement, html, css } from 'lit-element';
import { FBP } from '@furo/fbp';

/**
 * `zerf-item`
 * Describe your element
 *
 * @summary shortdescription
 * @customElement
 * @demo demo/zerf-item.html
 * @appliesMixin FBP
 */
let tag;
let dauer = 0;
let sumDauer = 0;
let sumDauerInklFaktor = 0;
let sumElement;
let year;
let month;
let day;
class ZerfItem extends FBP(LitElement) {
  select() {
    this.setAttribute('selected', '');
  }

  deselect() {
    this.removeAttribute('selected');
  }

  inject(entity) {
    this.entity = entity;
    this.item = entity.data;

    let zeitVonHours = entity.data.zeit_von.hours;
    let zeitVonMinutes = entity.data.zeit_von.minutes;
    let zeitBisHours = entity.data.zeit_bis.hours;
    let zeitBisMinutes = entity.data.zeit_bis.minutes;

    if (zeitVonHours === undefined) {
      zeitVonHours = '00';
    }
    if (zeitVonMinutes === undefined) {
      zeitVonMinutes = '00';
    }
    if (zeitBisHours === undefined) {
      zeitBisHours = '00';
    }
    if (zeitBisMinutes === undefined) {
      zeitBisMinutes = '00';
    }

    if (entity.data.zeit_von.hours < 10) {
      zeitVonHours = `0${zeitVonHours}`;
    }
    if (entity.data.zeit_von.minutes < 10) {
      zeitVonMinutes = `0${zeitVonMinutes}`;
    }
    if (entity.data.zeit_bis.hours < 10) {
      zeitBisHours = `0${zeitBisHours}`;
    }
    if (entity.data.zeit_bis.minutes < 10) {
      zeitBisMinutes = `0${zeitBisMinutes}`;
    }

    this.zeit_von = `${zeitVonHours}:${zeitVonMinutes}`;
    this.zeit_bis = `${zeitBisHours}:${zeitBisMinutes}`;

    if (tag !== JSON.stringify(entity.data.tag)) {
      const datediv = document.createElement('div');
      datediv.classList.add('date');

      year = entity.data.tag.year;
      month = entity.data.tag.month;
      day = entity.data.tag.day;

      if (year < 10) {
        year = `0${year}`;
      }

      if (month < 10) {
        month = `0${month}`;
      }

      if (day < 10) {
        day = `0${day}`;
      }

      // datediv.innerHTML = entity.data.tag;
      datediv.innerHTML = `${day}.${month}.${year}`;
      this.parentNode.insertBefore(datediv, this);
      tag = JSON.stringify(entity.data.tag);
      this._datediv = datediv;

      sumElement = document.createElement('span');
      datediv.appendChild(sumElement);
      sumDauer = 0;
      sumDauerInklFaktor = 0;
    }

    if (typeof entity.data.dauer === 'undefined') {
      dauer = 0;
    } else {
      dauer = entity.data.dauer;
    }

    sumDauer += dauer;
    sumDauerInklFaktor += (dauer * entity.data.faktor) / 100;

    if (sumElement) {
      let tmpDauer = sumDauer.toFixed(2);
      let tmpDauerInklFaktor = sumDauerInklFaktor.toFixed(2);
      sumElement.innerHTML = `${tmpDauer}h (${tmpDauerInklFaktor}h)`;
    }
  }

  clearDate() {
    tag = undefined;
    this.sumDauer = 0;
  }

  disconnectedCallback() {
    if (this._datediv) {
      this._datediv.remove();
    }
  }

  /**
   *
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return css`
      :host {
        display: block;
        border-top: 1px solid gainsboro;
        color: #666666;
        padding: 8px 12px 12px 12px;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;
      }

      :host([selected]) {
        background-color: var(--secondary-color);
      }

      paper-ripple {
        color: var(--secondary-color);
      }

      .nr {
        color: var(--primary);
        font-weight: 500;
        width: 120px;
      }

      .time {
        color: var(--primary);

        width: 120px;
      }
    `;
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <style>
        .obj {
          @apply --zeit-item-mixin;
        }
      </style>
      <div @-click="^^item-clicked(entity),^^list-item-clicked(index)">
        <furo-horizontal-flex>
          <span class="nr">${this.item.display_supportcase}</span>
          <span flex>${this.item.display_user}</span>
          <span> ${this.item.display_kostenstelle}</span>
        </furo-horizontal-flex>
        <div class="obj">${this.item.display_objecttext}</div>

        <furo-horizontal-flex>
          <span class="time">${this.zeit_von}-${this.zeit_bis}</span>
          <span flex>(${this.item.dauer}h / ${this.item.faktor}%)</span>
          <span>${this.item.km}km</span>
        </furo-horizontal-flex>
        <paper-ripple></paper-ripple>
      </div>
    `;
  }
}

window.customElements.define('zerf-item', ZerfItem);
