import { LitElement, html, css } from 'lit-element';
import { FBP } from '@furo/fbp';
// import {i18n} from "@furo/framework";

import '@furo/data/src/furo-data-object.js';
import '@furo/data/src/furo-entity-agent.js';

import '@furo/data-input/src/furo-catalog.js';
import '@furo/data-input/src/furo-data-collection-dropdown.js';
import '@furo/form/src/furo-catalog.js';

import './make-empty-asg-object.js';
import '../../project-components/detail-button-bar.js';
import './asg-detail-form.js';

/**
 * `ausgaben-detail`
 * Describe your element
 *
 * @summary shortdescription
 * @customElement
 * @demo demo/ausgaben-detail.html
 * @appliesMixin FBP
 */
class AsgDetail extends FBP(LitElement) {
  constructor() {
    super();
    this.vorgabetag = {
      tag: {
        year: 0,
        month: 0,
        day: 0,
      },
    };
  }

  /**
   * Exposes --queryParams
   * @param {Object} det
   */
  setQp(det) {
    // setze auf edit wenn wir sco und arb haben
    this._FBPTriggerWire('--queryParams', det);
    // setze auf neu ausgabentellen wenn wir nur sco haben

    if (det.aus === undefined) {
      this._FBPTriggerWire('--creatorQP', det);
    } else {
      this._FBPTriggerWire('--editQP', det);
    }
    // wir müssen nach dem delete nur den QP leer schicken, damit aus verschwindet
    this.createNewQP = {};
  }

  /**
   * injectDate
   * @param e
   */
  injectDate(e) {
    this.tag = e.tag;

    const year = parseInt(e.tag.substring(0, 4), 10);
    const month = parseInt(e.tag.substring(5, 7), 10);
    const day = parseInt(e.tag.substring(8, 10), 10);

    this.vorgabetag.tag.year = year;
    this.vorgabetag.tag.month = month;
    this.vorgabetag.tag.day = day;

    this._FBPTriggerWire('--dateInjected', this.vorgabetag);
  }

  addClicked(e) {
    this._FBPTriggerWire('--addClicked', e);
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires();
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <furo-vertical-flex style="position: relative">
        <furo-banner-display autofocus=""></furo-banner-display>
        <furo-banner ƒ-parse-grpc-status="--error" icon="warning"></furo-banner>

        <asg-detail-form
          flex
          scroll
          ƒ-bind-data="--entityReady(*.data)"
          ƒ-object-collection-in="--objekteResponse"
          ƒ-kst-collection-in="--KstspesenResponse"
          ƒ-start-activity="--saveClicked, --createClicked"
          ƒ-stop-activity="--dataLoaded, --saveSuccess, --emptyObject, --createSuccess, --error"
        >
        </asg-detail-form>

        <furo-ripple noink class="success" ƒ-trigger="--saveSuccess, --createSuccess"></furo-ripple>
        <detail-button-bar
          ƒ-bind-entity="--entityReady"
          @-save-clicked="--saveClicked"
          @-create-clicked="--createClicked"
          @-delete-clicked="--deleteClicked"
        >
        </detail-button-bar>
      </furo-vertical-flex>

      <!-- Ab hier die datenlogik-->
      <furo-deep-link
        service="Ausgabe"
        ƒ-qp-in="--queryParams, --createdQp"
        @-hts-out="--hts"
      ></furo-deep-link>

      <make-empty-asg-object
        ƒ-qp-in="--creatorQP"
        ƒ-date-in="--dateInjected"
        ƒ-trigger-custom-method="--addClicked, --createSuccess"
        @-object-created="--emptyObject"
      ></make-empty-asg-object>

      <furo-entity-agent
        service="Ausgabe"
        ƒ-hts-in="--hts"
        ƒ-load="--editQP"
        ƒ-bind-request-data="--entityReady(*.data)"
        ƒ-delete="--deleteClicked"
        @-delete-success="^^ausgabe-deleted(createNewQP)"
        @-save-success="--saveSuccess, ^^ausgabe-saved"
        @-create-success="--createSuccess, ^^ausgabe-created(createNewQP), --createdQp(createNewQP)"
        ƒ-save="--saveClicked, --createClicked"
        @-load-success="--dataLoaded"
        @-response-error="--error"
      ></furo-entity-agent>

      <furo-data-object
        type="ausgabe.AusgabeEntity"
        ƒ-clear-all-errors="--dataLoaded, --saveSuccess, --emptyObject, --createSuccess"
        ƒ-inject-raw="--dataLoaded, --saveSuccess, --emptyObject"
        @-object-ready="--entityReady"
      ></furo-data-object>

      <furo-deep-link
        service="Supportcaseobject"
        ƒ-qp-in="--queryParams"
        @-hts-out="--htsObjekte"
      ></furo-deep-link>

      <furo-collection-agent
        service="Supportcaseobject"
        ƒ-hts-in="--htsObjekte"
        list-on-hts-in
        @-response="--objekteResponse"
      ></furo-collection-agent>

      <furo-deep-link
        service="Kstspesen"
        ƒ-qp-in="--queryParams"
        @-hts-out="--htsKstspesen"
      ></furo-deep-link>

      <furo-collection-agent
        service="Kstspesen"
        ƒ-hts-in="--htsKstspesen"
        list-on-hts-in
        @-response="--KstspesenResponse"
      ></furo-collection-agent>
    `;
  }

  /**
   *
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return css`
      :host {
        display: block;
        background-color: var(--surface, white);
        font-size: 14px;
        padding: 12px 24px;
        height: 100%;
        box-sizing: border-box;
      }

      furo-ripple.success {
        --furo-ripple-bg-color: var(--success, #5ef47f);
      }

      furo-banner-display {
        --furo-icon-fill-color: var(--danger, red);
      }
      ausgaben-detail-form {
        margin-top: 12px;
      }
    `;
  }
}

window.customElements.define('asg-detail', AsgDetail);
