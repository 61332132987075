import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme';
import { FBP } from '@furo/fbp';

/**
 * `display-Objekte`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo/display-Objekte.html
 * @appliesMixin FBP
 */
class TrObjekte extends FBP(LitElement) {
  constructor() {
    super();
    this.fields = {};
  }

  /**
   *
   * @param fieldNode
   */
  bindData(fieldNode) {
    this.fields = fieldNode;

    this.erledigtclass = '';
    if (typeof this.fields.erledigt_l._value === 'undefined') {
      this.erledigtclass = '';
    } else if (this.fields.erledigt_l._value) {
      this.erledigtclass = 'erledigt';
    } else {
      this.erledigtclass = '';
    }

    if (this.fields.serviceart._value == null) {
      this.fields.serviceart._value = '';
    }

    fieldNode.addEventListener('field-value-changed', () => {
      this.requestUpdate();
    });
    this.requestUpdate();
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent(this.name) ||
      css`
        :host {
          display: table-row;
        }

        :host([hidden]) {
          display: none;
        }

        .erledigt {
          background-color: rgba(0, 128, 0, 0.42);
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <td class="${this.erledigtclass}">${this.fields.objektnr}</td>
      <td class="${this.erledigtclass}">${this.fields.objektbez}</td>
      <td class="${this.erledigtclass}">${this.fields.serviceart}</td>
      <td class="${this.erledigtclass}">${this.fields.grund}</td>
    `;
  }
}

window.customElements.define('tr-objekte', TrObjekte);
