import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';
import './panel-protokoll.js';
/**
 * `view-objektprotokoll`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-view-objektprotokoll
 * @appliesMixin FBP
 */
class ViewObjektprotokoll extends FBP(LitElement) {
  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('ViewObjektprotokoll') ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }
        furo-app-bar-top {
          height: 48px;
        }

        furo-ripple.success {
          --furo-ripple-bg-color: var(--success, #5ef47f);
        }

        furo-ripple.fail {
          --furo-ripple-bg-color: var(--error, #fe3f2b);
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-vertical-flex>
        <furo-app-bar-top drawer="objektprotokoll">
          <objekt-menu flex page-name="objektprotokoll" ƒ-refresh="--pageActivated"></objekt-menu>
        </furo-app-bar-top>

        <panel-protokoll
          scroll
          ƒ-bind-data="--protokollEntity"
          @-save-clicked="--saveClicked"
          ƒ-page-activated="--pageActivated"
        ></panel-protokoll>
      </furo-vertical-flex>

      <furo-deep-link
        service="Protokoll"
        ƒ-qp-in="--pageActivated(*.query)"
        @-hts-out="--htsProtokoll"
      ></furo-deep-link>

      <furo-entity-agent
        service="Protokoll"
        ƒ-hts-in="--htsProtokoll"
        ƒ-load="--htsUpdated"
        ƒ-bind-request-data="--protokollEntity(*.data)"
        @-hts-injected="--htsUpdated"
        @-save-success="--saveSuccess"
        ƒ-save="--saveClicked"
        @-response="--protokollRecived"
        @-response-error="--error"
      ></furo-entity-agent>

      <furo-data-object
        type="supportcaseobject.ProtokollEntity"
        ƒ-clear-all-errors="--protokollRecived, --saveSuccess"
        ƒ-inject-raw="--protokollRecived, --saveSuccess"
        @-object-ready="--protokollEntity"
      ></furo-data-object>

      <furo-ripple noink class="success" ƒ-trigger="--saveSuccess"></furo-ripple>
      <furo-ripple noink class="fail" ƒ-trigger="--error"></furo-ripple>
    `;
  }
}

window.customElements.define('view-objekt-protokoll', ViewObjektprotokoll);
