import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme';
import { FBP } from '@furo/fbp';
import { i18n } from '@furo/framework';

import '../../project-components/typerenderer/mora-type-renderer.js';

/**
 * `abschnitt-objektdetail`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo/panel-objektdetail.html
 * @appliesMixin FBP
 */

class AbschnittObjektdetail extends FBP(LitElement) {
  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    //  this._FBPTraceWires()

    let layout = 'one';
    const spaltenwert = this.fields.spalten._value;
    switch (spaltenwert) {
      case 1:
        layout = 'one';
        break;
      case 2:
        layout = 'two';
        break;
      case 3:
        layout = 'three';
        break;
      case 4:
        layout = 'four';
        break;
      case 6:
        layout = 'six';
        break;
      default:
        layout = 'two';
        break;
    }

    const formElement = this.shadowRoot.getElementById('abschnittformular');
    if (formElement) {
      formElement.setAttribute(layout, '');
    }
  }

  bindData(d) {
    this.fields = d;
    this._FBPTriggerWire('--fields', d);
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('AbschnittObjektdetail') ||
      css`
        :host {
          display: block;
          background-color: white;
        }

        :host([hidden]) {
          display: none;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-collapsible-box label="${this.fields.bezeichnung}" open="">
        <furo-form-layouter id="abschnittformular" breakpoint-big="810,405" breakpoint-small="380">
          <template is="flow-repeat" ƒ-inject-items="--fields(*.felder.repeats)">
            <mora-type-renderer ƒ-bind-data="--item" context="form"></mora-type-renderer>
          </template>
        </furo-form-layouter>

        <div style="padding: 0px; margin-top: 56px; background: white">
          <furo-button
            raised
            primary
            label="${i18n.t('_label_speichern')}"
            @-click="^^save-clicked"
          ></furo-button>
        </div>
      </furo-collapsible-box>
    `;
  }
}

window.customElements.define('abschnitt-objektdetail', AbschnittObjektdetail);
