import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';
import { i18n } from '@furo/framework';
import '../../project-components/data-display.js';
import '../../project-components/date-display.js';

/**
 * `support-card-detail`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-support-card-detail
 * @appliesMixin FBP
 */
class SupportCardDetail extends FBP(LitElement) {
  constructor() {
    super();
    this.sc = {};
    this.hide_supportcasenotiz = true;
  }

  /**
   * @private
   * @return {Object}
   */
  static get properties() {
    return {
      /**
       * Supportcase
       */
      sc: { type: Object },
    };
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()

    this._FBPAddWireHook('--DO', (e) => {
      e.addEventListener('field-value-changed', (e) => {
        if(e.detail._name == "hide_supportnotiz"){
          this.hide_supportcasenotiz = e.detail._value;
          this.requestUpdate();
        }
      });
    });
  }

  // to reset the data object
  clear() {
    this._FBPTriggerWire('--clear');
  }

  htsIn(hts) {
    // wir müssen den spc aus der url holen :-(
    this.queryParams = {
      spc: window.location.search.split('spc=')[1],
    };

    this._FBPTriggerWire('--hts', hts);
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('SupportCardDetail') ||
      css`
        :host {
          display: block;
          background-color: white;
          width: var(--kachel-breite);
          height: 100%;
          font-size: 14px;
        }

        div[flex] {
          position: relative;
        }

        .infobar {
          border-top: 1px solid gainsboro;
          padding: 12px 12px;
          font-size: 15px;
        }

        .date {
          font-size: 14px;
          color: var(--primary);
          padding: 12px;
        }

        .supnr {
          font-size: 14px;
          color: gray;
          padding: 12px;
        }

        wcd-select-input {
          margin: 0;
          padding: 0 8px;
          display: block;
        }

        h3 {
          font-weight: 300;
          padding: 12px;
          margin-top: 0;

          background-color: var(--primary);
          color: var(--on-primary);
        }

        div.txt {
          padding: 12px;
        }

        .flex div {
          color: gray;
          font-size: 12px;
        }

        span {
          color: gray;
          font-size: 12px;
          display: block;
        }

        .high {
          color: var(--accent, darkred);
        }

        .label {
          margin-top: 10px;
        }

        a {
          color: var(--primary);
          text-decoration: none;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-vertical-flex>
        <div flex>
          <furo-horizontal-flex>
            <date-display class="date" ƒ-bind="--DO(*.data.termindatum)"></date-display>
            <div flex></div>
            <data-display class="supnr" ƒ-bind="--DO(*.data.supportnr)"></data-display>
          </furo-horizontal-flex>

          <h3 @-click="--supportClicked">
            <furo-vertical-flex>
              <data-display ƒ-bind="--DO(*.data.sidebar_header1)"></data-display>
              <data-display ƒ-bind="--DO(*.data.sidebar_header2)"></data-display>
              <data-display ƒ-bind="--DO(*.data.sidebar_header3)"></data-display>
              <data-display ƒ-bind="--DO(*.data.sidebar_header4)"></data-display>
            </furo-vertical-flex>
          </h3>

          <div class="txt">
            <furo-vertical-flex>
              <span class="label"
                ><data-display ƒ-bind="--DO(*.data.sidebar_info1)"></data-display
              ></span>
              <data-display ƒ-bind="--DO(*.data.sidebar_info1text1)"></data-display>
              <data-display ƒ-bind="--DO(*.data.sidebar_info1text2)"></data-display>
              <data-display ƒ-bind="--DO(*.data.sidebar_info1text3)"></data-display>
              <data-display ƒ-bind="--DO(*.data.sidebar_info1text4)"></data-display>

              <span class="label"
                ><data-display ƒ-bind="--DO(*.data.sidebar_info2)"></data-display
              ></span>
              <data-display ƒ-bind="--DO(*.data.sidebar_info2text1)"></data-display>
              <data-display ƒ-bind="--DO(*.data.sidebar_info2text2)"></data-display>
              <data-display ƒ-bind="--DO(*.data.sidebar_info2text3)"></data-display>
              <data-display ƒ-bind="--DO(*.data.sidebar_info2text4)"></data-display>

              <span class="label"
                ><data-display ƒ-bind="--DO(*.data.sidebar_info3)"></data-display
              ></span>
              <data-display ƒ-bind="--DO(*.data.sidebar_info3text1)"></data-display>
              <data-display ƒ-bind="--DO(*.data.sidebar_info3text2)"></data-display>
              <data-display ƒ-bind="--DO(*.data.sidebar_info3text3)"></data-display>
              <data-display ƒ-bind="--DO(*.data.sidebar_info3text4)"></data-display>

              <span class="label"
                ><data-display ƒ-bind="--DO(*.data.sidebar_info4)"></data-display
              ></span>
              <data-display ƒ-bind="--DO(*.data.sidebar_info4text1)"></data-display>
              <data-display ƒ-bind="--DO(*.data.sidebar_info4text2)"></data-display>
              <data-display ƒ-bind="--DO(*.data.sidebar_info4text3)"></data-display>
              <data-display ƒ-bind="--DO(*.data.sidebar_info4text4)"></data-display>
            </furo-vertical-flex>
          </div>
          <paper-ripple></paper-ripple>
        </div>

        <div class="btnbar">
          <furo-horizontal-flex>
            <furo-button
              flex
              ?hidden=${this.hide_supportcasenotiz}
              raised="false"
              primary=""
              label="${i18n.t('_label_notizen')}"
              @-click="^^support-detail-notiz-clicked(queryParams)"
            ></furo-button>
          </furo-horizontal-flex>
        </div>

        <div class="infobar">
          <data-display class="high" ƒ-bind="--DO(*.data.dringlichkeitstext)"></data-display>
        </div>
      </furo-vertical-flex>

      <furo-app-flow
        event="menu-item-supporte-clicked"
        ƒ-trigger="--supportClicked"
      ></furo-app-flow>

      <furo-entity-agent
        service="Supportcase"
        ƒ-hts-in="--hts"
        load-on-hts-in=""
        @-response="--response"
      ></furo-entity-agent>

      <furo-data-object
        type="supportcase.SupportcaseEntity"
        ƒ-inject-raw="--response"
        @-object-ready="--DO,((data))"
      ></furo-data-object>
    `;
  }
}

window.customElements.define('support-card-detail', SupportCardDetail);
