import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';

/**
 * `next-card`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-next-card
 * @appliesMixin FBP
 */
class NavCard extends FBP(LitElement) {
  /**
   * @private
   * @return {Object}
   */
  static get properties() {
    return {
      /**
       * Description
       */
      hidden: { type: Boolean, reflect: true },
      icon: { type: String },
    };
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()
    this.hidden = true;
  }

  show() {
    this.hidden = false;
  }

  hide() {
    this.hidden = true;
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('NextCard') ||
      css`
        :host {
          display: block;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
            0 3px 1px -2px rgba(0, 0, 0, 0.2);
          background-color: white;
          width: var(--kachel-breite);
          height: 270px;
          margin: 24px 12px 0 12px;
          float: left;
          border-radius: 4px;
          font-size: 14px;
          box-sizing: border-box;
          border-left: 10px solid #fff;
          border-right: 10px solid #fff;
          cursor: pointer;
        }

        :host([hidden]) {
          display: none;
        }

        furo-icon {
          height: 40%;
          width: 50%;
          padding: 25%;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-icon icon="${this.icon}"></furo-icon>
    `;
  }
}

window.customElements.define('nav-card', NavCard);
