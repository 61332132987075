import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme';
import { FBP } from '@furo/fbp';

/**
 * `tr-history-colspan`
 * Historiezusatz Objekt einer Arbeit an einem Objekt
 * todo Tabelle mit echten Daten füllen
 *
 * @summary Tr-History-Colspan
 * @customElement
 * @demo demo/tr-history-colspan.html
 * @appliesMixin FBP
 */
class TrHistoryColspan extends FBP(LitElement) {
  constructor() {
    super();
    this.fields = {};
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */

  /**
   * @param fieldNode
   */
  bindData(fieldNode) {
    this.fields = fieldNode;
    this._FBPTriggerWire('--data', fieldNode);
    this.requestUpdate();
  }

  index(i) {
    if (i % 2 === 0) {
      const myObj = this;
      myObj.setAttribute('class', 'dark');
    }
  }

  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent(this.name) ||
      css`
        :host {
          display: table-row;
        }

        :host([hidden]) {
          display: none;
        }

        td {
          border-bottom: 1px solid gainsboro;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <td class="top" ƒ-.inner-text="--data(*.data.rapportnr)"></td>
      <td colspan="2" ƒ-.inner-text="--data(*.data.postext)"></td>
    `;
  }
}

window.customElements.define('tr-history-colspan', TrHistoryColspan);
