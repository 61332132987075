import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme';
import { FBP } from '@furo/fbp';
import { i18n } from '@furo/framework';
import './part-sichtpruefung.js';
import './part-abs-snr.js';
import './part-abs-schutzklasse.js';
import './part-geraetedaten.js';
import './part-messungen.js';
import './part-funktionspruefung.js';
import './part-pruefergebnis.js';
import './part-messgeraet.js';
import './part-unterschrift-auftraggeber.js';
import './part-unterschrift-pruefer.js';
import '../../project-components/show-date.js';

/**
 * `panel-protokoll`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo/panel-protokoll.html
 * @appliesMixin FBP
 */

class PanelProtokoll extends FBP(LitElement) {
  constructor() {
    super();
    // leeres object damit es immer vorhanden ist
    this.fields = {};
  }

  // weiterleiten des pageActivated von oben
  pageActivated(data) {
    this._FBPTriggerWire('--pageActivated', data);
  }

  bindData(d) {
    this.fields = d.data;

    if (this.fields.display_kunde_adresse._value == null) {
      this.fields.display_kunde_adresse._value = '';
    }

    d.addEventListener('data-injected', () => {
      this._FBPTriggerWire('--fields', this.fields);
      this.requestUpdate();
    });

    this._FBPAddWireHook('--clearPruefer', () => {
      this.fields.pruefer_unterschrift._value = null;
    });

    this._FBPAddWireHook('--clearAuftraggeber', () => {
      this.fields.kunde_unterschrift._value = null;
    });
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent(this.name) ||
      css`
        :host {
          display: block;
          background-color: white;
          padding: 24px;
        }

        :host([hidden]) {
          display: none;
        }

        h3 {
          margin-top: 56px;
          font-weight: Normal;
          font-size: 24px;
          letter-spacing: 0;
        }

        h4 {
          margin-top: 40px;
          margin-bottom: 0;
          font-weight: Normal;
          font-size: 16px;
          letter-spacing: 0.15px;
        }

        h5 {
          margin: 0;
        }

        .padding-sign {
          padding: 0 20px;
        }

        span {
          line-height: 1;
          padding-bottom: 12px;
          display: inline-block;
        }

        .context {
          width: 50%;
        }

        .tooltip {
          position: relative;
          display: inline-block;
        }

        /* Tooltip text */
        .tooltip .tooltiptext {
          visibility: hidden;
          width: 120px;
          background-color: black;
          color: #fff;
          text-align: center;
          padding: 5px 0;
          border-radius: 6px;

          /* Position the tooltip text - see examples below! */
          position: absolute;
          z-index: 1;
        }

        /* Show the tooltip text when you mouse over the tooltip container */
        .tooltip:hover .tooltiptext {
          visibility: visible;
        }

        show-date {
          display: inline-block;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-form
        header-text="${i18n.t('menu_titel_objektprotokoll')}"
        secondary-text="${i18n.t('objekt-protokoll_titel')}"
      >
        <furo-collapsible-box label="${i18n.t('objekt-protokoll_abs_protokoll_anzeigen')}" open="">
          <div slot="context" class="context">
            <furo-vertical-flex>
              <furo-data-checkbox-input
                condensed
                ƒ-bind-data="--fields(*.no_protokoll_l)"
              ></furo-data-checkbox-input>
              <furo-data-textarea-input
                row="1"
                ƒ-bind-data="--fields(*.no_protokoll_text)"
              ></furo-data-textarea-input>
              <div style="padding: 0px; background: white">
                <furo-button
                  raised
                  primary
                  label="${i18n.t('_label_speichern')}"
                  @-click="^^save-clicked"
                ></furo-button>
              </div>
              <div>&nbsp;</div>
            </furo-vertical-flex>
          </div>

          <furo-form-layouter four="" breakpoint-big="810,405" breakpoint-small="380">
            <div>
              ${i18n.t('objekt-protokoll_label_supportnr')} ${this.fields.display_supportcase}
            </div>
            <div double>
              ${i18n.t('objekt-protokoll_label_objektnr')} ${this.fields.display_objectnumber} -
              ${this.fields.display_objecttext}
            </div>
            <div>
              ${i18n.t('objekt-protokoll_label_datum')}
              <show-date ƒ-inject-raw="--fields(*.datum)"></show-date>
            </div>

            <div double>
              <h4>${i18n.t('objekt-protokoll_label_auftraggeber')}</h4>
              <p>
                ${this.fields.display_kunde_firma}<br />
                ${this.fields.display_kunde_adresse}<br />
                ${this.fields.display_kunde_landplzort}<br />
              </p>
              ${i18n.t('objekt-protokoll_label_kundennr')}:<br />
              ${this.fields.display_kunde_kundennr}
            </div>

            <div double>
              <h4>${i18n.t('objekt-protokoll_label_auftragnehmer')}</h4>
              <p>
                ${this.fields.display_firma}<br />
                ${this.fields.display_adresse}<br />
                ${this.fields.display_landplzort}<br />
              </p>
              ${i18n.t('objekt-protokoll_label_pruefer')}:<br />
              ${this.fields.display_user}<br />
            </div>

            <part-abs-snr double ƒ-bind-data="--fields"></part-abs-snr>
            <part-abs-schutzklasse double ƒ-bind-data="--fields"></part-abs-schutzklasse>

            <part-geraetedaten full ƒ-bind-data="--fields"></part-geraetedaten>

            <part-sichtpruefung full ƒ-bind-data="--fields"></part-sichtpruefung>

            <part-messungen full ƒ-bind-data="--fields"></part-messungen>

            <part-funktionspruefung full ƒ-bind-data="--fields"></part-funktionspruefung>

            <part-pruefergebnis full ƒ-bind-data="--fields"></part-pruefergebnis>

            <part-messgeraet full ƒ-bind-data="--fields"></part-messgeraet>

            <part-unterschrift-auftraggeber
              full
              ƒ-bind-data="--fields"
              ƒ-page-activated="--pageActivated"
            ></part-unterschrift-auftraggeber>

            <part-unterschrift-pruefer
              full
              ƒ-bind-data="--fields"
              ƒ-page-activated="--pageActivated"
            ></part-unterschrift-pruefer>

            <div style="padding: 0px; margin-top: 56px; background: white">
              <furo-button
                raised
                primary
                label="${i18n.t('_label_speichern')}"
                @-click="^^save-clicked"
              ></furo-button>
            </div>
          </furo-form-layouter>
        </furo-collapsible-box>
      </furo-form>
    `;
  }
}

window.customElements.define('panel-protokoll', PanelProtokoll);
