import { LitElement, html, css } from 'lit-element';
import { FBP } from '@furo/fbp';

import './zerf-week-form.js';

/**
 * `zeit-week`
 * Describe your element
 *
 * @summary shortdescription
 * @customElement
 * @demo demo/zeit-week.html
 * @appliesMixin FBP
 */
class ZerfWeek extends FBP(LitElement) {
  /**
   * Constructor
   */
  constructor() {
    super();
    this.zeitWeek = {};
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    //  this._FBPTraceWires();

    this._FBPAddWireHook('--dateValueChangedFomDown', e => {
      const datum = {
        tag: e,
      };
      this.injectDate(datum);
    });

    this._FBPAddWireHook('--zeitWeekInReady', e => {
      this.zeitWeek = e;
    });
  }

  /**
   * injectDate
   * @param e
   */
  injectDate(e) {
    this.tag = e.tag;

    const year = parseInt(e.tag.substring(0, 4), 10);
    const month = parseInt(e.tag.substring(5, 7), 10);
    const day = parseInt(e.tag.substring(8, 10), 10);

    this.zeitWeek.tag.year._value = year;
    this.zeitWeek.tag.month._value = month;
    this.zeitWeek.tag.day._value = day;

    this._FBPTriggerWire('--zeitWeekIn', this.zeitWeek);
    this._FBPTriggerWire('--weekZeitTrigger');
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <div>
        <zerf-week-form
          ƒ-bind-data="--weekzeitEntity"
          ƒ-reset="--triggerCustomMethod_nope"
          @-date-value-changed="--dateValueChangedFomDown"
        >
        </zerf-week-form>
      </div>

      <!-- Service zur Ermittlung der Sollzeiten für die Erfassung 1/2 Tag 1/1 Tag -->
      <furo-data-object type="zeit.Week" @-object-ready="--zeitWeekInReady"></furo-data-object>

      <furo-data-object
        type="zeit.WeekEntity"
        ƒ-clear-all-errors=""
        ƒ-inject-raw="--weekzeitResponse"
        @-object-ready="--weekzeitEntity"
      ></furo-data-object>

      <furo-deep-link
        ƒ-trigger="--weekZeitTrigger"
        service="Zeit"
        @-hts-out="--weekZeitHTS"
      ></furo-deep-link>
      <furo-custom-method
        service="Zeit"
        method="weekzeit"
        ƒ-hts-in="--weekZeitHTS"
        @-response="--weekzeitResponse"
        @-hts-updated="--loopBack"
        ƒ-trigger="--loopBack"
        ƒ-bind-request-data="--zeitWeekIn"
      ></furo-custom-method>
    `;
  }

  /**
   *
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return css`
      :host {
        display: block;
        background-color: var(--surface, white);
        font-size: 14px;
        padding: 12px 24px;
        height: auto;
        min-height: 30%;
        box-sizing: border-box;
      }
    `;
  }
}

window.customElements.define('zerf-week', ZerfWeek);
