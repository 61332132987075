import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';
import './zerf-list.js';
import './zerf-detail.js';
import './zerf-week.js';
/**
 * `view-zeiterfassung`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-view-zeiterfassung
 * @appliesMixin FBP
 */
class ViewSZeiterfassung extends FBP(LitElement) {
  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('ViewSZeiterfassung') ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }
        furo-app-bar-top {
          height: 48px;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-vertical-flex>
        <furo-app-bar-top drawer="zeiterfassung">
          <main-menu flex page-name="zeiterfassung" ƒ-refresh="--pageActivated"></main-menu>
        </furo-app-bar-top>

        <furo-app-drawer name="zeiterfassung" float-breakpoint="1020">
          <zerf-list
            slot="drawer"
            drawer="szeiterfassung"
            @-item-clicked="--zeitItemClicked"
            @-add-time-clicked="--addClicked"
            @-date-selected="--date-selected"
            ƒ-set-qp="--pageActivated(*.query)"
            ƒ-reload-list="--azChange, --azCreated"
          ></zerf-list>

          <furo-vertical-scroller>
            <zerf-detail
              ƒ-set-qp="--locationChanged(*.query)"
              ƒ-inject-date="--date-selected"
              ƒ-add-time-clicked="--addClicked"
              @-az-created="--azCreated"
              @-az-saved="--azChange"
              @-az-deleted="--azChange, --azDeletedQP"
            ></zerf-detail>

            <zerf-week ƒ-inject-date="--date-selected"></zerf-week>
          </furo-vertical-scroller>
        </furo-app-drawer>
      </furo-vertical-flex>

      <!-- wir hören auf die URL -->
      <furo-location
        url-space-regex="${window.APPROOT}/zeiterfassung"
        @-location-changed="--locationChanged"
      ></furo-location>

      <!-- addClicked gibt uns einen HTS rein und wir kriegen die qp -->
      <furo-reverse-deep-link
        service="Zeit"
        rel="list"
        @-converted="--addQp"
        ƒ-convert="--addClicked"
      ></furo-reverse-deep-link>

      <furo-reverse-deep-link
        service="Zeit"
        rel="self"
        @-converted="--itemQp"
        ƒ-convert="--zeitItemClicked(*.links)"
      ></furo-reverse-deep-link>

      <!-- wir schreiben die QP in die URL -->
      <furo-qp-changer clear="zei" ƒ-set-qp="--itemQp,--addQp,--azDeletedQP"></furo-qp-changer>
    `;
  }
}

window.customElements.define('view-zeiterfassung', ViewSZeiterfassung);
