import { LitElement, html, css } from 'lit-element';
import { FBP } from '@furo/fbp';

/**
 * `zerf-date`
 * Describe your element
 *
 * @summary shortdescription
 * @customElement
 * @demo demo/zerf-date.html
 * @appliesMixin FBP
 */
class ZerfDateselector extends FBP(LitElement) {
  constructor() {
    super();
    const today = new Date();

    this._date = this._makeDate(today);

    this._FBPAddWireHook('--date', e => {
      this._setDate(e);
    });

    this._FBPAddWireHook('--forward', () => {
      const date = new Date(this._date);
      // add a day
      date.setDate(date.getDate() + 1);
      this._setDate(this._makeDate(date));
      this.requestUpdate();
    });

    this._FBPAddWireHook('--back', () => {
      const date = new Date(this._date);
      // add a day
      date.setDate(date.getDate() - 1);
      this._setDate(this._makeDate(date));

      this.requestUpdate();
    });
  }

  /**
   * ƒlow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();

    setTimeout(() => {
      this._setDate(this._date);
    }, 10);
  }

  _setDate(dateStr) {
    this._date = dateStr;

    this._FBPTriggerWire('--date-updated', this._date);
    /**
     * @event date-changed
     * Fired when
     * detail payload:
     */
    const customEvent = new Event('date-changed', { composed: true, bubbles: true });
    customEvent.detail = { tag: dateStr };
    this.dispatchEvent(customEvent);
  }

  // eslint-disable-next-line class-methods-use-this
  _makeDate(today) {
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${dd}`;
  }

  /**
   *
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return css`
      :host {
        display: block;
      }

      furo-icon-button {
        background-color: var(--secondary);
        border-radius: 50%;
        color: var(--on-secondary);
        margin: 12px;
      }

      input {
        border: none;
        font-size: 18px;
        font-family: 'Arial', serif;
        text-align: center;
        outline: none;
      }
    `;
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <furo-horizontal-flex>
        <furo-icon-button icon="arrow-back" @-click="--back"></furo-icon-button>
        <input flex type="date" ƒ-.value="--date-updated" @-change="--date(*.target.value)" />
        <furo-icon-button icon="arrow-forward" @-click="--forward"></furo-icon-button>
      </furo-horizontal-flex>
    `;
  }
}

window.customElements.define('zerf-dateselector', ZerfDateselector);
