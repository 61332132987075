import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme';
import { FBP } from '@furo/fbp';
import './tr-history.js';
import './tr-history-colspan.js';

/**
 * `tb-history`
 * Historie Objekt einer Arbeit an einem Objekt
 * todo Tabelle mit echten Daten füllen
 *
 * @summary Tr-History
 * @customElement
 * @demo demo/tr-history.html
 * @appliesMixin FBP
 */
class TbHistory extends FBP(LitElement) {
  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */

  /**
   *
   * @param fieldNode
   */
  bindData(fieldNode) {
    this._FBPTriggerWire('--item', fieldNode);
  }

  index(i) {
    this._FBPTriggerWire('--index', i);
  }

  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent(this.name) ||
      css`
        :host {
          display: contents;
        }

        :host([hidden]) {
          display: none;
        }

        .dark {
          background-color: #f5f5f5;
        }

        tr-history:hover {
          background-color: rgba(0, 0, 0, 0.12);
        }

        tr-history-colspan:hover {
          background-color: rgba(0, 0, 0, 0.12);
        }

        tr-history-colspan {
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <tr-history ƒ-bind-data="--item" ƒ-index="--index"></tr-history>
      <tr-history-colspan ƒ-bind-data="--item" ƒ-index="--index"></tr-history-colspan>
    `;
  }
}

window.customElements.define('tb-history', TbHistory);
