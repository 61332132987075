import { LitElement, html, css } from 'lit-element';
import { FBP } from '@furo/fbp';
import { Theme } from '@furo/framework/src/theme';

/**
 * `asg-list-item`
 * Supportausgaben list item
 *
 * @customElement
 * @demo demo/asg-list-item.html
 * @appliesMixin FBP
 */

let tag;
let total = 0;
let sumElement;
let year;
let month;
let day;

class AsgListItem extends FBP(LitElement) {
  select() {
    this.setAttribute('selected', '');
  }

  deselect() {
    this.removeAttribute('selected');
  }

  inject(entity) {
    this.entity = entity;
    this.item = entity.data;

    if (tag !== JSON.stringify(entity.data.tag)) {
      const datediv = document.createElement('div');
      datediv.classList.add('date');

      year = entity.data.tag.year;
      month = entity.data.tag.month;
      day = entity.data.tag.day;

      if (year < 10) {
        year = `0${year}`;
      }

      if (month < 10) {
        month = `0${month}`;
      }

      if (day < 10) {
        day = `0${day}`;
      }

      // datediv.innerHTML = entity.data.tag;
      datediv.innerHTML = `${day}.${month}.${year}`;
      this.parentNode.insertBefore(datediv, this);
      tag = JSON.stringify(entity.data.tag);
      this._datediv = datediv;

      sumElement = document.createElement('span');
      datediv.appendChild(sumElement);
      total = 0;
    }

    total += entity.data.gesamtpreis || 0;
    if (sumElement) {
      sumElement.innerHTML = `Total ${total}`;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  clearDate() {
    tag = undefined;
    total = 0;
  }

  disconnectedCallback() {
    if (this._datediv) {
      this._datediv.remove();
    }
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent(this.name) ||
      css`
        :host {
          display: block;
          border-top: 1px solid gainsboro;
          color: #666666;
          padding: 8px 12px 12px 12px;
          box-sizing: border-box;
          cursor: pointer;
          position: relative;
        }

        :host([selected]) {
          background-color: var(--secondary-color);
        }

        furo-ripple {
          color: var(--secondary-color);
        }

        .nr {
          color: var(--primary);
          font-weight: 500;
          padding-right: 12px;
        }

        .time {
          color: var(--primary);

          width: 120px;
        }
        :host([hidden]) {
          display: none;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <div @-click="^^item-clicked(entity),^^list-item-clicked(index)">
        <furo-horizontal-flex>
          <div class="nr">${this.item.display_objectnumber}</div>
          <div flex class="">${this.item.display_user}</div>
          <div><input type="checkbox" ?checked="${this.item.beleg_l}" disabled /></div>
        </furo-horizontal-flex>
        <div class="obj">${this.item.text}</div>

        <furo-horizontal-flex>
          <div class="time" flex>${this.item.menge}x${this.item.einzelpreis.value}</div>
          <div style="overflow: hidden; white-space: nowrap">${this.item.display_kostenstelle}</div>
        </furo-horizontal-flex>
        <furo-ripple></furo-ripple>
      </div>
    `;
  }
}

window.customElements.define('asg-list-item', AsgListItem);
