import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';
import './panel-rapport.js';

/**
 * `view-rapport`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-view-rapport
 * @appliesMixin FBP
 */
class ViewSRapport extends FBP(LitElement) {
  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('ViewSRapport') ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }

        furo-app-bar-top {
          height: 48px;
        }

        furo-ripple.success {
          --furo-ripple-bg-color: var(--success, #5ef47f);
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-vertical-flex>
        <furo-app-bar-top drawer="rapport">
          <objekt-menu flex page-name="s-rapport" ƒ-refresh="--pageActivated"></objekt-menu>
        </furo-app-bar-top>

        <panel-rapport
          scroll
          flex
          ƒ-bind-data="--entityReady(*.data)"
          @-save="--save"
          ƒ-lock-Send-Button="--save"
          ƒ-unlock-Send-Button="--dataLoaded,--saveSuccess,--error, --pageDeActivated"
          ƒ-color-Send-Button="--saveSuccess"
          ƒ-uncolor-Send-Button="--error, --pageDeActivated"
        ></panel-rapport>
      </furo-vertical-flex>

      <furo-ripple noink class="success" ƒ-trigger="--saveSuccess"></furo-ripple>

      <furo-deep-link
        service="Rapport"
        ƒ-qp-in="--pageActivated(*.query)"
        @-hts-out="--htsRapport"
      ></furo-deep-link>

      <furo-entity-agent
        service="Rapport"
        ƒ-bind-request-data="--entityReady(*.data)"
        ƒ-hts-in="--htsRapport"
        @-hts-injected="--htsUpdated"
        ƒ-load="--htsUpdated"
        @-save-success="--saveSuccess"
        @-load-success="--dataLoaded"
        ƒ-save="--save"
        @-response-error="--error"
      ></furo-entity-agent>

      <furo-data-object
        type="rapport.RapportEntity"
        ƒ-clear-all-errors="--dataLoaded, --saveSuccess"
        ƒ-inject-raw="--dataLoaded, --saveSuccess"
        @-object-ready="--entityReady"
      ></furo-data-object>
    `;
  }
}

window.customElements.define('view-rapport', ViewSRapport);
