import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';
import './ers-list.js';
import './ers-detail.js';

/**
 * `view-s-ersatzteile`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-view-s-ersatzteile
 * @appliesMixin FBP
 */
class ViewSErsatzteile extends FBP(LitElement) {
  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('ViewSErsatzteile') ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }

        furo-app-bar-top {
          height: 48px;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-vertical-flex>
        <furo-app-bar-top drawer="ersatzteile">
          <objekt-menu flex page-name="s-ersatzteile" ƒ-refresh="--pageActivated"></objekt-menu>
        </furo-app-bar-top>
        <furo-app-drawer name="ersatzteile" float-breakpoint="1020">
          <ers-list
            slot="drawer"
            drawer="ersatzteile"
            @-item-clicked="--listItemclicked"
            @-add-time-clicked="--addClicked"
            ƒ-set-qp="--pageActivated(*.query)"
            ƒ-reload-list="--ersatzteilChanged, --ersatzteilCreated"
          ></ers-list>


          <ers-detail
            ƒ-set-qp="--locationChanged(*.query)"
            ƒ-add-clicked="--addClicked"
            @-ersatzteil-created="--ersatzteilCreated"
            @-ersatzteil-saved="--ersatzteilChanged"
            @-ersatzteil-deleted="--ersatzteilChanged, --ersatzeilDeletedQP"
          ></ers-detail>

      </furo-vertical-flex>
      </furo-app-drawer>

      <!-- wir hören auf die URL -->
      <furo-location
        url-space-regex="${window.APPROOT}/s-ersatzteile"
        @-location-changed="--locationChanged"
      ></furo-location>

      <!-- addClicked gibt uns einen HTS rein und wir kriegen die qp -->
      <furo-reverse-deep-link
        service="Ersatzteil"
        rel="list" @-converted="--addQp"
        ƒ-convert="--addClicked"
      ></furo-reverse-deep-link>

      <furo-reverse-deep-link
        service="Ersatzteil"
        rel="self" @-converted="--itemQp"
        ƒ-convert="--listItemclicked(*.links)"
      ></furo-reverse-deep-link>

      <!-- wir schreiben die QP in die URL -->
      <furo-qp-changer clear="sco,est" ƒ-set-qp="--itemQp,--addQp,--ersatzeilDeletedQP"></furo-qp-changer>
    `;
  }
}

window.customElements.define('view-s-ersatzteile', ViewSErsatzteile);
