// Code generated by @furo/ui-builder. DO NOT EDIT.
import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';

// eslint-disable-next-line no-unused-vars
import { i18n } from '@furo/framework/src/i18n.js';

import '@furo/data/src/furo-catalog.js';
import '@furo/data-input/src/furo-catalog.js';
import '@furo/timing/src/furo-de-bounce.js';

/**
 * service specs for the experiment api
 *
 * @summary todo: write summary
 * @customElement
 * @polymer
 * @appliesMixin FBP
 */
export class TextbausteinReferenceSearch extends FBP(LitElement) {
  constructor() {
    super();
    this.textbausteinRef = {};
  }

  /**
   * @private
   * @return {Object}
   */
  static get properties() {
    return {
      // The default style (md like) supports a condensed form. It is a little bit smaller then the default
      condensed: {
        type: Boolean,
      },
    };
  }

  // Fokus
  focus(d) {
    this._FBPTriggerWire('--focused', d);
  }

  // Filter setzen
  setFilter(d) {
    this._FBPTriggerWire('--textbausteinfilter', d);
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires();

    this._FBPAddWireHook('--entityReady', e => {
      this.textbausteinRef = e;
      this._FBPTriggerWire('--field-injected', e.its_textbaustein);
    });

    this._FBPAddWireHook('--textbausteinSelected', e => {
      if (this.text._value.length > 0) {
        this.text._value = `${this.text._value}\r\n\r\n${e.data.bausteintext}`;
      } else {
        this.text._value = e.data.bausteintext;
      }
    });
  }

  bindText(text) {
    this.text = text;
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('ReferenceSearchBaseTheme') ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }

        furo-data-reference-search {
          width: 100%;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <!-- It is a good practice to set a description -->
      <furo-data-reference-search
        ?condensed="${this.condensed}"
        display-field="bausteinbez"
        _displayName="bausteinbez"
        min-term-length="2"
        no-result-hint="${i18n.t('search.noresult.hint')}"
        value-field="id"
        @-search="--term"
        @-item-selected="--textbausteinSelected"
        ƒ-bind-data="--entityReady(*.its_textbaustein)"
        ƒ-collection-in="--collection"
        ƒ-focus="--focused"
      ></furo-data-reference-search>

      <!-- It is a good practice to set a description -->
      <furo-de-bounce @-out="--debouncedTerm" ƒ-input-wire="--term"></furo-de-bounce>

      <!-- It is a good practice to set a description -->
      <furo-deep-link
        service="Textbaustein"
        ƒ-trigger="--field-injected"
        @-hts-out="--hts"
      ></furo-deep-link>

      <furo-collection-agent
        service="Textbaustein"
        @-response="--collection"
        ƒ-hts-in="--hts"
        ƒ-search="--debouncedTerm"
        ƒ-set-filter="--textbausteinfilter"
      ></furo-collection-agent>

      <furo-data-object
        type="textbaustein.Textbausteinreferenz"
        ƒ-clear-all-errors=""
        ƒ-inject-raw=""
        @-object-ready="--entityReady"
      ></furo-data-object>
    `;
  }
}

window.customElements.define('textbaustein-reference-search', TextbausteinReferenceSearch);
