import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';
import '@furo/layout/src/furo-catalog.js';
import { i18n } from '@furo/framework/src/i18n';
import '../../project-components/show-date.js';

/**
 * `objekt-kachel`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-objekt-kachel
 * @appliesMixin FBP
 */
class ObjektKachel extends FBP(LitElement) {
  constructor() {
    super();
    this.fields = {};
    this.erledigtCMO = {}; // CMO => custom method Objekt
    this.userzuweisungCMO = {};
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();

    /**
     * Register hook on wire --data to
     * react to changes in the data object
     */
    this._FBPAddWireHook('--data', this.dataChangedHandler);

    /**
     * Register hook on wire --objekt-erledigt-clicked to
     * switch between erledigt and not erledigt state
     */
    this._FBPAddWireHook('--objekt-erledigt-clicked', this.toggleErledigen);

    /**
     * Register hook on wire --userZuweisungChanged to
     * klick auf dropdown user handeln
     */
    this._FBPAddWireHook('--userZuweisungChanged', this.switchUser);
  }

  switchUser = () => {
    this.userzuweisungCMO.ulid_sup_kopf_user._value = this.fields.ulid_sup_kopf_user._value;
    this._FBPTriggerWire('--userStateChanged', this.userzuweisungCMO);
  };

  toggleErledigen = () => {
    this.fields.erledigt_l._value = !this.fields.erledigt_l._value;
    this.erledigtCMO.erledigt_l._value = this.fields.erledigt_l._value;
    this._FBPTriggerWire('--erledigtStateChanged', this.erledigtCMO);
  };

  dataChangedHandler = entity => {
    this.handleButtonLogic(entity);
    entity.addEventListener('field-value-changed', () => {
      this.handleButtonLogic(entity);
    });
  };

  handleButtonLogic = entity => {
    this.hideSnr = entity.hide_snrprotokoll._value;
    this.hideDoa = entity.hide_objectdetail._value;
    this.abgeschlossen = entity.erledigt_l._value;

    // Status für SNR-Protokoll
    // 0 = offen / nicht erstellt
    // 1 = nicht möglich / nötig
    // 2 = korrekt erstellt
    this.snropen = false;
    this.snrnone = false;
    this.snrdone = false;
    if (
      entity.display_snrprotokoll_status._value === 0 ||
      typeof entity.display_snrprotokoll_status._value === 'undefined'
    ) {
      this.snropen = true;
    } else if (entity.display_snrprotokoll_status._value === 1) {
      this.snrnone = true;
    } else if (entity.display_snrprotokoll_status._value === 2) {
      this.snrdone = true;
    }
  };

  /**
   * @private
   * @return {Object}
   */
  static get properties() {
    return {
      /**
       * Supportcase
       */
      abgeschlossen: { type: Boolean, reflect: true },
      /**
       * snr button verstecken
       */
      hideSnr: { type: Boolean },
      hideDoa: { type: Boolean },
      snropen: { type: Boolean },
      snrdone: { type: Boolean },
      snrnone: { type: Boolean },
      /**
       * fields, wird von  @-object-ready="--data,((fields))" gefüllt
       */
      fields: { type: Object },
    };
  }

  injectItem(rawEntity) {
    this._FBPTriggerWire('--rawSCO', rawEntity.data);

    // wir müssen den spc aus der url holen :-(
    this.queryParams = {
      spc: window.location.search.split('spc=')[1],
      sco: rawEntity.data.id,
    };

    this._FBPTriggerWire('--qpSCO', this.queryParams);
  }

  injectUserCollection(rawData) {
    this._FBPTriggerWire('--userEntities', rawData.entities);
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('ObjektKachel') ||
      css`
        :host([hidden]) {
          display: none;
        }

        :host {
          display: block;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
            0 3px 1px -2px rgba(0, 0, 0, 0.2);
          background-color: white;
          width: var(--kachel-breite);
          height: 390px;
          margin: 24px 0 0 24px;
          float: left;
          border-radius: 4px;
          font-size: 14px;
          position: relative;

          box-sizing: border-box;
        }

        .userselect {
          padding: 0 8px;
        }

        furo-data-collection-dropdown {
          width: 100%;
        }

        :host([abgeschlossen]) {
          border-right: solid rgba(0, 128, 0, 0.42) 10px;
          border-radius: 4px;
        }

        div.num {
          font-size: 12px;
          color: gray;
          position: absolute;
          right: 4px;
          top: 4px;
        }

        div.serial {
          font-size: 12px;
          color: var(--primary);
          position: absolute;
          left: 8px;
          top: 4px;
        }

        select {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          border: none;
          border-radius: 0;
          width: 100%;
          background: transparent;
        }

        select {
          border-bottom: 1px solid rgba(0, 0, 0, 0.12);
          font-size: 14px;
          padding: 12px 0;
          outline: none;
        }

        select:focus {
          border-color: var(--primary, #3f51b5);
          border-width: 1px;
        }

        h3 {
          font-weight: 300;
          padding: 8px;
          margin-top: 24px;
          margin-bottom: 0px;
        }

        .txt {
          padding: 8px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .labelbox {
          padding: 8px;
        }

        .labelbox span {
          color: gray;
          font-size: 12px;
          display: block;
        }

        .labelbox data-display {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;
        }

        .memo {
          padding: 0 8px;
          overflow: auto;
          cursor: pointer;
          white-space: pre-line;
        }

        .pointer {
          cursor: pointer;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-vertical-flex>
        <div class="serial">${this.fields.subnr}</div>
        <div class="num">${this.fields.objektnr}</div>
        <furo-vertical-flex>
          <div class="pointer" @-click="^^support-objekt-clicked(queryParams)">
            <h3>${this.fields.kachel_header}</h3>
            <div class="txt">${this.fields.kachel_body}<br /></div>
          </div>
          <div class="memo" flex @-click="^^support-objekt-clicked(queryParams)">
            ${this.fields.kachel_memo}
          </div>

          <furo-horizontal-flex>
            <div class="labelbox">
              <span>${this.fields.kachel_infol}</span>
              ${this.fields.kachel_infoltext}
            </div>
            <div class="labelbox" style="width: 230px;">
              <span>${this.fields.kachel_infom}</span>
              ${this.fields.kachel_infomtext}
            </div>
            <div class="labelbox" style="width: 230px;">
              <span>${this.fields.kachel_infor}</span>
              ${this.fields.kachel_infortext}
            </div>
          </furo-horizontal-flex>
          <furo-loading-indicator-bar
            ƒ-start="--erledigtStateChanged, --userStateChanged"
            ƒ-stop="--dataStored"
          ></furo-loading-indicator-bar>
          <div class="userselect">
            <furo-data-collection-dropdown
              ƒ-bind-data="--data(*.ulid_sup_kopf_user)"
              condensed
              display-field="display_name"
              ƒ-inject-entities="--userEntities"
              @-value-changed="--userZuweisungChanged"
            ></furo-data-collection-dropdown>
          </div>

          <furo-horizontal-flex>
            <furo-button
              flex
              ?hidden="${this.hideDoa}"
              raised="true"
              label="${i18n.t('support-detail_objectcard_objectdetails')}"
              @-click="^^support-objekt-detail-clicked(queryParams)"
            ></furo-button>

            <furo-button
              flex
              ?hidden="${this.hideSnr}"
              ?primary="${this.snrdone}"
              ?secondary="${this.snrnone}"
              raised="true"
              label="${i18n.t('support-detail_objectcard_snrprotocol')}"
              @-click="^^snr-protokoll-clicked(queryParams)"
            ></furo-button>

            <furo-button
              flex
              raised="true"
              label="${i18n.t('support-detail_objectcard_erledigen')}"
              @-click="--objekt-erledigt-clicked"
            ></furo-button>
          </furo-horizontal-flex>
        </furo-vertical-flex>
      </furo-vertical-flex>

      <furo-data-object
        type="supportcaseobject.Object"
        ƒ-inject-raw="--rawSCO"
        @-object-ready="--data,((fields))"
      ></furo-data-object>

      <furo-deep-link service="Supportcaseobject" ƒ-qp-in="--qpSCO" @-hts-out="--htsSCO">
      </furo-deep-link>

      <furo-data-object
        type="supportcaseobject.Userzuweisung"
        @-object-ready="((userzuweisungCMO))"
      ></furo-data-object>

      <furo-custom-method
        method="userzuweisung"
        service="Supportcaseobject"
        ƒ-trigger-with-body="--userStateChanged"
        ƒ-hts-in="--htsSCO"
        @-response="--dataStored"
      ></furo-custom-method>

      <furo-data-object
        type="supportcaseobject.Erledigt"
        @-object-ready="((erledigtCMO))"
      ></furo-data-object>

      <furo-custom-method
        method="erledigt"
        service="Supportcaseobject"
        ƒ-trigger-with-body="--erledigtStateChanged"
        ƒ-hts-in="--htsSCO"
        @-response="--dataStored"
      ></furo-custom-method>
    `;
  }
}

window.customElements.define('objekt-kachel', ObjektKachel);
