import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme';
import { FBP } from '@furo/fbp';
import '../../project-components/show-date.js';

/**
 * `tr-history`
 * Historie Objekt einer Arbeit an einem Objekt
 * todo Tabelle mit echten Daten füllen
 *
 * @summary Tr-History
 * @customElement
 * @demo demo/tr-history.html
 * @appliesMixin FBP
 */
class TrHistory extends FBP(LitElement) {
  constructor() {
    super();
    this.fields = {};
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */

  /**
   *
   * @param fieldNode
   */
  bindData(fieldNode) {
    this.fields = fieldNode;

    // Bugfix damit, beim nullable-string
    // usertext nicht undefinded steht,
    // wenn kein Wert vorhanden ist.
    if (this.fields.data.usertext === undefined) {
      this.fields.data.usertext = '';
    }

    this._FBPTriggerWire('--data', fieldNode);
    this.requestUpdate();
  }

  index(i) {
    if (i % 2 === 0) {
      const myObj = this;
      myObj.setAttribute('class', 'dark');
    }
  }

  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent(this.name) ||
      css`
        :host {
          display: table-row;
        }

        :host([hidden]) {
          display: none;
        }

        td.main {
          font-weight: 600;
        }
        td.top {
          vertical-align: top;
          alignment: left;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <td class="top"><show-date ƒ-inject-raw="--data(*.data.datum)"></show-date></td>
      <td class="main" ƒ-.inner-text="--data(*.data.raptext)"></td>
      <td ƒ-.inner-text="--data(*.data.usertext)"></td>
    `;
  }
}

window.customElements.define('tr-history', TrHistory);
