import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';

/**
 * `make-empty-ausgabe-object`
 * Erstellt ein leere AusgabeEntity
 *
 * @customElement
 * @appliesMixin FBP
 */
class MakeEmptyAsgObject extends FBP(LitElement) {
  constructor() {
    super();
    this.newEmptyObject = {
      data: {},
      links: {},
    };
    this.zeitvorgabeIn = null;
  }

  qpIn(qp) {
    this._FBPTriggerWire('--qpIn', qp);
  }

  dateIn(vorgabetag) {
    this.zeitvorgabeIn.tag.year._value = vorgabetag.tag.year;
    this.zeitvorgabeIn.tag.month._value = vorgabetag.tag.month;
    this.zeitvorgabeIn.tag.day._value = vorgabetag.tag.day;
    this._FBPTriggerWire('--zeitvorgabeIn', this.zeitvorgabeIn);
  }

  triggerCustomMethod() {
    this._FBPTriggerWire('--triggerCustomMethod');
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPAddWireHook('--hts', hts => {
    //  const today = new Date();
    //  const rawObject = {
    //    data: {
    //      tag: {
    //        day: today.getDate(),
    //        month: today.getMonth() + 1, // January is 0!
    //        // eslint-disable-next-line no-param-reassign
    //        year: today.getFullYear(),
    //      },
    //    },
    //    links: hts,
    //  };
    //  /**
    //   * @event object-created
    //   * Fired when object-created
    //   * detail payload: empty object with links
    //   */
    //  const customEvent = new Event('object-created', { composed: true, bubbles: false });
    //  customEvent.detail = rawObject;
    //  this.dispatchEvent(customEvent);
    // });

    this._FBPAddWireHook('--htsUpdated', () => {
      this._FBPTriggerWire('--triggerCustomMethod');
    });

    this._FBPAddWireHook('--zeitObjBack', e => {
      this.newEmptyObject.data = e.data;
      this.newEmptyObject.links = e.links;

      const customEvent = new Event('object-created', { composed: true, bubbles: false });
      customEvent.detail = this.newEmptyObject;
      this.dispatchEvent(customEvent);
    });
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-deep-link service="Ausgabe" ƒ-qp-in="--qpIn" @-hts-out="--hts"></furo-deep-link>

      <furo-data-object
        type="ausgabe.Ausgabevorgabe"
        @-object-ready="((zeitvorgabeIn))"
      ></furo-data-object>

      <furo-custom-method
        service="Ausgabe"
        method="vorgabe"
        ƒ-hts-in="--hts"
        @-response="--zeitObjBack"
        @-hts-updated="--htsUpdated"
        ƒ-trigger="--triggerCustomMethod"
        ƒ-bind-request-data="--zeitvorgabeIn"
      ></furo-custom-method>
    `;
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('MakeEmptyAusgabeObject') ||
      css`
        :host {
          display: none;
        }
      `
    );
  }
}

window.customElements.define('make-empty-asg-object', MakeEmptyAsgObject);
