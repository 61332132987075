import { LitElement, html, css } from 'lit-element';
import { FBP } from '@furo/fbp';
import { Theme } from '@furo/framework/src/theme.js';
import { Styling } from './configs/styling.js';

import '@furo/input/src/furo-button.js';
import '@furo/route/src/furo-location.js';
import '@furo/route/src/furo-pages.js';
import '@furo/route/src/furo-app-flow.js';
import '@furo/app/src/furo-app-drawer.js';
import '@furo/app/src/furo-app-bar-top.js';
import '@furo/notification/src/furo-snackbar-display.js';
import './menu/main-menu.js';

/**
 * Static imports of the views
 * The lazy imports a below in _FBPReady
 */

import './views/view-dashboard.js';
import './views/view-auth.js';
import './views/view-404.js';

import './views/user-switch/view-user-switch.js';
import './views/supporte/view-supporte.js';
import './views/zeiterfassung/view-zeiterfassung.js';
import './views/ausgaben/view-ausgaben.js';
import './views/nachbestellung/view-nachbestellung.js';
import './views/bestellvorschlaege/view-bestellvorschlaege.js';
import './views/s-zeiterfassung/view-s-zeiterfassung.js';
import './views/s-ausgaben/view-s-ausgaben.js';
import './views/s-ersatzteile/view-s-ersatzteile.js';
import './views/s-rapport/view-rapport.js';
import './views/s-history/view-objekt-history.js';
import './views/support-detail/view-support-detail.js';
import './views/support-notiz/view-support-notiz.js';
import './views/objektprotokoll/view-objekt-protokoll.js';
import './views/objekt-detail/view-objekt-detail.js';

/**
 * Static imports of Type-Renderers
 */
import './project-components/typerenderer/registry.js';

/**
 * `main-stage`
 *
 * @customElement
 * @appliesMixin FBP
 */
class MainStage extends FBP(LitElement) {
  _FBPReady() {
    super._FBPReady();
    //  this._FBPTraceWires();
    /**
     * Register hook on wire --locationChanged to
     * Lazy load parts of the page
     *
     * DO NOT FORGET TO REGISTER THE LAZY LOADED PARTS IN ~/polymer.json => fragments[...]
     *
     */
    this._FBPAddWireHook('--locationChanged', async e => {
      switch (e.pathSegments[0]) {
        case 'examplelayout':
          //     await import('./views/view-layout-example.js');
          break;
        default:
      }
    });
  }

  /**
   *
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    const theme = Theme.getThemeForComponent(this.name);
    if (theme) {
      return [theme, Styling.theme];
    }
    // language=CSS
    return [
      css`
        :host {
          height: 100%;
          display: block;
          background: var(--background);
          color: var(--on-background);
        }

        furo-pages {
          height: 100vh;
          overflow: hidden;
        }

        furo-pages > * {
          height: 100%;
        }

        side-navigation {
          background-color: var(--llm-color);
        }
      `,
      Styling.theme,
    ];
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <furo-pages
        ƒ-inject-location="--locationChanged"
        default="supporte"
        @-response-error="--responseError"
        @-logout-request="--logoutRequested"
      >
        <view-user-switch name="user-switch"></view-user-switch>
        <view-s-zeiterfassung name="s-zeiterfassung"></view-s-zeiterfassung>
        <view-dashboard name="dashboard"></view-dashboard>
        <view-auth name="auth" ƒ-logout="--logoutRequested"></view-auth>
        <view-supporte name="supporte"></view-supporte>
        <view-support-detail name="support-detail"></view-support-detail>
        <view-support-notiz name="support-notiz"></view-support-notiz>
        <view-zeiterfassung name="zeiterfassung"></view-zeiterfassung>
        <view-ausgaben name="ausgaben"></view-ausgaben>
        <view-nachbestellung name="nachbestellung"></view-nachbestellung>
        <view-betsellvorschlaege name="bestellvorschlaege"></view-betsellvorschlaege>
        <view-s-ausgaben name="s-ausgaben"></view-s-ausgaben>
        <view-s-ersatzteile name="s-ersatzteile"></view-s-ersatzteile>
        <view-rapport name="rapport"></view-rapport>
        <view-objekt-history name="objekt-history"></view-objekt-history>
        <view-objekt-protokoll name="objekt-protokoll"></view-objekt-protokoll>
        <view-objekt-detail name="objekt-detail"></view-objekt-detail>
        <view-404 name="404"></view-404>
      </furo-pages>

      <furo-snackbar-display></furo-snackbar-display>
      <furo-location
        url-space-regex="${window.APPROOT}"
        @-location-path-changed="--locationChanged"
      ></furo-location>
      <furo-app-flow ƒ-emit="" event="response-error"></furo-app-flow>
    `;
  }
}

window.customElements.define('main-stage', MainStage);
