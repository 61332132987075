import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';
import { i18n } from '@furo/framework';

/**
 * `part-funktionspruefung`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-part-funktionspruefung
 * @appliesMixin FBP
 */
class PartFunktionspruefung extends FBP(LitElement) {
  bindData(d) {
    this._FBPTriggerWire('--fields', d);
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('PartFunktionspruefung') ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }

        h3 {
          margin-top: 56px;
          font-weight: Normal;
          font-size: 24px;
          letter-spacing: 0;
        }

        .io-nio > furo-data-checkbox-input {
          width: 80px;
        }

        furo-form-layouter > div {
          line-height: 67px;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <h3>${i18n.t('objekt-protokoll_abs_titel_funkp')}:</h3>

      <furo-form-layouter four>
        <div>${i18n.t('objekt-protokoll_abs_funkp')}</div>
        <furo-horizontal-flex class="io-nio">
          <furo-data-checkbox-input
            condensed
            ƒ-bind-data="--fields(*.funkp_io_l)"
            @-click="--funkp_io"
            ƒ-uncheck="--funkp_nio"
          ></furo-data-checkbox-input>
          <furo-data-checkbox-input
            condensed
            ƒ-bind-data="--fields(*.funkp_nio_l)"
            @-click="--funkp_nio"
            ƒ-uncheck="--funkp_io"
          ></furo-data-checkbox-input>
        </furo-horizontal-flex>
      </furo-form-layouter>
    `;
  }
}

window.customElements.define('part-funktionspruefung', PartFunktionspruefung);
