export const Translations = {
  'de-CH': {
    menu_titel_home: 'Home',
    'menu_titel_user-switch': 'Benutzerwechsel',
    menu_titel_auth: 'Anmelden',
    menu_titel_supporte: 'Supporte',
    menu_titel_zeiterfassung: 'Zeiterfassung',
    menu_titel_objektprotokoll: 'SNR-Protokoll',
    menu_titel_objektdetail: 'Objektdetail',
    menu_titel_ausgaben: 'Spesen',
    menu_titel_nachbestellung: 'Nachbestellung',
    menu_titel_bestellvorschlaege: 'Bestellvorschläge',
    'menu_titel_s-zeiterfassung': 'Arbeitszeit',
    'menu_titel_s-ausgaben': 'Ausgaben',
    'menu_titel_s-ersatzteile': 'Material',
    'menu_titel_s-rapport': 'Rapport',
    'menu_titel_s-objekt-history': 'Objekt History',
    'menu_titel_s-support-detail': 'Objekte',
    'menu_titel_s-support-notiz': 'Supportnotiz',
    'menu_titel_s-objekt-detail': 'Objektedetail',
    auth_login: 'Login',
    auth_logintext: 'Geben Sie Username und Passwort ein',
    auth_username: 'Username',
    auth_passwort: 'Passwort',
    auth_rememberme: 'Eingeloggt bleiben',
    auth_ok: 'OK',
    supporte_filter_filter: 'Filter',
    supporte_filter_suchtextfeld: 'Suchtext',
    supporte_filter_suchbuttontext: 'Suchen',
    supporte_filter_open: 'alle offenen',
    supporte_filter_closed: 'abgeschlossen',
    supporte_card_auftragsgeber: 'Auftragsgeber',
    supporte_card_supportart: 'Supportart',
    'support-detail_maincard_kontaktperson': 'Kontaktperson',
    'support-detail_maincard_mail': 'Mail',
    'support-detail_maincard_adressnummer': 'Adressnummer',
    'support-detail_maincard_supportart': 'Supportart',
    'support-detail_objectcard_garantie': 'Garantie',
    'support-detail_objectcard_standort': 'Standort',
    'support-detail-objectcard-seriennr': 'Serien-Nr.',
    'support-detail_objectcard_monteur': 'Zuständiger Monteur',
    'support-detail_objectcard_snrprotocol': 'SNR',
    'support-detail_objectcard_erledigen': 'erledigen',
    'support-detail_objectcard_oeffnen': 'öffnen',
    'support-detail_objectcard_objectdetails': 'Objektdetails',
    'support-notiz_main_titel' : 'Notizen zu Supportcase',
    'support-notiz_label_id' : 'Id',
    'support-notiz_label_supportnr' : 'Support-Nr.',
    'support-notiz_label_notiz' : 'Notiz',
    'objekt-history_maincard_garantie': 'Garantie',
    'objekt-history_maincard_standort': 'Standort',
    'objekt-history_historycard_historie': 'Historie',
    'objekt-history_historycard_datum': 'Datum',
    'objekt-history_historycard_beschreibung': 'Beschreibung',
    'objekt-history_historycard_zustaendigkeit': 'Zuständigkeit',
    's-zeiterfassung_main_titel': 'Zeiteintrag für Supportcase',
    's-zeiterfassung_create_titel': 'erstellen',
    's-zeiterfassung_edit_titel': 'bearbeiten',
    's-zeiterfassung_label_faktor': 'Faktor',
    's-zeiterfassung_label_dauer': 'Dauer',
    's-zeiterfassung_label_tag': 'Tag',
    's-zeiterfassung_label_zeitvon': 'Zeit von',
    's-zeiterfassung_label_zeitbis': 'Zeit bis',
    's-zeiterfassung_label_objekt': 'Objekt',
    's-zeiterfassung_label_kostenstelle': 'Kostenstelle',
    's-zeiterfassung_label_kilometer': 'Kilometer',
    's-zeiterfassung_label_text': 'Text',
    's-ersatzteile_main_titel': 'Material für Supportcase',
    's-ersatzteile_create_titel': 'erfassen',
    's-ersatzteile_edit_titel': 'bearbeiten',
    's-ersatzteile_label_tag': 'Tag',
    's-ersatzteile_label_objekt': 'Objekt',
    's-ersatzteile_label_lagerort': 'Lagerort',
    's-ersatzteile_label_artikel': 'Artikel',
    's-ersatzteile_label_artikeltext': 'Artikeltext',
    's-ersatzteile_label_internenotiz': 'interne Notiz',
    's-ersatzteile_label_menge': 'Menge',
    's-ersatzteile_label_verkaufseinheit': 'Verkaufseinheit',
    's-ersatzteile_label_bestellen': 'Nachbestellen',
    's-ersatzteile_label_lagerbestand': 'Lagerbestand',
    's-ausgaben_main_titel': 'Ausgaben für Supportcase',
    's-ausgaben_create_titel': 'erfassen',
    's-ausgaben_edit_titel': 'bearbeiten',
    's-ausgaben_label_user': 'User',
    's-ausgaben_label_tag': 'Tag',
    's-ausgaben_label_text': 'Text',
    's-ausgaben_label_internenotiz': 'interne Notiz',
    's-ausgaben_label_betrag': 'Betrag',
    's-ausgaben_label_menge': 'Menge',
    's-ausgaben_label_gesamtpreis': 'Gesamtpreis',
    's-ausgaben_label_objekt': 'Objekt',
    's-ausgaben_label_kostenstelle': 'Kostenstelle',
    's-ausgaben_label_beleg': 'Beleg vorhanden?',
    's-ausgaben_label_beleg_on_item': 'Beleg',
    rapport_main_titel: 'Rapport',
    rapport_titel_auftraggeber: 'Auftraggeber',
    rapport_titel_monteur: 'Servicetechniker',
    rapport_abstitel_objekte: 'Objekte',
    rapport_tabtitel_objekt: 'Objekt',
    rapport_tabtitel_beschreibung: 'Beschreibung',
    rapport_tabtitel_serviceart: 'SA',
    rapport_tabtitel_grund: 'Grund',
    rapport_tabtitel_status: 'Status',
    rapport_abstitel_ausgaben: 'Ausgaben',
    rapport_tabtitel_datum: 'Datum',
    rapport_tabtitel_anzahl: 'Anzahl',
    rapport_tabtitel_text: 'Text',
    rapport_tabtitel_menge: 'Menge',
    rapport_tabtitel_einheit: 'Einheit',
    rapport_tabtitel_mitarbeiter: 'Von',
    rapport_abstitel_ersatzteile: 'Material',
    rapport_tabtitel_artikel: 'Artikel',
    rapport_abstitel_arbeitszeiten: 'Arbeitszeiten',
    rapport_tabtitel_dauer: 'Dauer',
    rapport_tabtitel_zeitvon: 'von',
    rapport_tabtitel_zeitbis: 'bis',
    rapport_tabtitel_km: 'km',
    rapport_abstitel_Reisezeitpauschalen: 'Hin- und Rückreise',
    rapport_tabtitel_kostenstelle: 'Kostenstelle',
    rapport_tabtotal_totalarbeitszeit: 'Totalarbeitszeit',
    rapport_tabtotal_totalkm: 'Total km',
    rapport_tr_reisezeitpauschale_reisezeit_text: 'Hin- und Rückreisezeit',
    rapport_tr_reisezeitpauschale_reiseweg_text: 'Hin- und Rückreiseweg',
    rapport_tr_reisezeitpauschale_fahrkosten_text: 'Fahrtkostenanteil',
    // rapport_label_arbeitsbericht: 'Arbeitsbericht',
    rapport_label_arbeitsbericht: 'Besonderheiten',
    rapport_label_kundenmail: 'Kundenmail',
    rapport_label_protokollmail: 'Protokollmail',
    rapport_label_arbeiterklaert: 'Arbeit erklärt',
    rapport_label_arbeiterledigt: 'Arbeit erledigt',
    rapport_label_unterschriftzuruecksetzen: 'Unterschrift zurücksetzen',
    rapport_label_bestellnummer: 'Bestell-Nr.',
    rapport_btn_zwischenrapport_senden: 'Zwischenrapport versenden',
    rapport_btn_zwischenrapport_gesendet: 'Zwischenrapport versendet',
    rapport_btn_rapport_senden: 'Rapport versenden',
    rapport_btn_rapport_gesendet: 'Rapport versendet',
    rapport_btn_rapport_und_snr_senden: 'Rapport und SNR-Protokoll versenden',
    rapport_btn_rapport_und_snr_gesendet: 'Rapport und SNR-Protokoll versendet',
    zeiterfassung_main_titel_sc: 'Zeiteintrag für Supportcase',
    zeiterfassung_main_titel: 'Zeiteintrag',
    zeiterfassung_create_titel: 'erfassen',
    zeiterfassung_edit_titel: 'bearbeiten',
    zeiterfassung_label_faktor: 'Faktor',
    zeiterfassung_label_dauer: 'Dauer',
    zeiterfassung_label_zeitvon: 'Zeit von',
    zeiterfassung_label_zeitbis: 'Zeit bis',
    zeiterfassung_label_tag: 'Tag',
    zeiterfassung_label_objekt: 'Objekt',
    zeiterfassung_label_kostenstelle: 'Kostenstelle',
    zeiterfassung_label_text: 'Text',
    zeiterfassung_label_halbertag: '1/2 Tag',
    zeiterfassung_label_ganzertag: '1/1 Tag',
    ausgaben_main_titel: 'Spesen',
    ausgaben_create_titel: 'erfassen',
    ausgaben_edit_titel: 'bearbeiten',
    ausgaben_label_tag: 'Tag',
    ausgaben_label_text: 'Text',
    ausgaben_label_betrag: 'Betrag',
    ausgaben_label_menge: 'Menge',
    ausgaben_label_gesamtpreis: 'Gesamtpreis',
    ausgaben_label_kostenstelle: 'Kostenstelle',
    ausgaben_label_Beleg: 'Beleg vorhanden?',
    ausgaben_label_user: 'User',
    nachbestellung_filter_filter: 'Filter',
    nachbestellung_filter_open: 'alle offenen',
    nachbestellung_filter_bereitsbestellt: 'bereits bestellt',
    nachbestellung_main_titel: 'Materialbestellung',
    nachbestellung_create_titel: 'erfassen',
    nachbestellung_edit_titel: 'bearbeiten',
    nachbestellung_view_titel: 'anzeigen',
    nachbestellung_label_menge: 'Menge',
    nachbestellung_label_bestelltam: 'Bestellt am',
    nachbestellung_label_artikel: 'Artikel',
    nachbestellung_label_lagerort: 'Lagerort',
    nachbestellung_label_objekt: 'Objekt',
    nachbestellung_label_freigabe: 'Bestellen',
    nachbestellung_label_kommission: 'Kommission',
    nachbestellung_label_lagerbestand: 'Lagerbestand',
    betsellvorschlag_label_entnommen_vom_monteur: 'Entnommen vom Monteur',
    'objekt-protokoll_titel':
      'Prüf- und Messprotokoll ortsveränderlicher elektrischer Geräte nach Änderung, Instandsetzung oder Wiederholungsprüfung gemäss SNR 462638',
    'objekt-protokoll_abs_protokoll_anzeigen': 'Protokoll anzeigen',
    'objekt-protokoll_label_protokoll_nicht_benoetigt': 'SNR-Protokoll nicht benötigt',
    'objekt-protokoll_label_grund': 'Grund',
    'objekt-protokoll_label_supportnr': 'Supportnummer',
    'objekt-protokoll_label_objektnr': 'Objektnummer',
    'objekt-protokoll_label_datum': 'Datum',
    'objekt-protokoll_label_auftraggeber': 'Auftraggeber / Kunde',
    'objekt-protokoll_label_auftragnehmer': 'Auftragnehmer',
    'objekt-protokoll_label_kundennr': 'Kunden-Nr.',
    'objekt-protokoll_label_pruefer': 'Prüfer',
    'objekt-protokoll_abs_pruefung_nach': 'Prüfung nach',
    'objekt-protokoll-label_snr': 'SNR 462638',
    'objekt-protokoll-label_en': 'EN 60204',
    'objekt-protokoll_label_aenderung': 'Änderung',
    'objekt-protokoll_label_instandsetzung': 'Instandsetzung',
    'objekt-protokoll_label_wiederholungspruefung': 'Wiederholungsprüfung',
    'objekt-protokoll_abs_schutzklasse': 'Schutzklasse',
    'objekt-protokoll_label_schutzklasse1': '1',
    'objekt-protokoll_label_schutzklasse2': '2',
    'objekt-protokoll_label_schutzklasse3': '3',
    'objekt-protokoll_abs_geraetedaten': 'Gerätedaten',
    'objekt-protokoll_label_hersteller': 'Hersteller',
    'objekt-protokoll_label_typ': 'Typ',
    'objekt-protokoll_label_seriennr': 'Serien-Nr.',
    'objekt-protokoll_label_schutzart': 'Schutzart',
    'objekt-protokoll_label_nennspannung': 'Nennspannung',
    'objekt-protokoll_label_nennstrom': 'Nennstrom',
    'objekt-protokoll_label_nennleistung': 'Nennleistung',
    'objekt-protokoll_label_frequenz': 'Frequenz',
    'objekt-protokoll_abs_sichtpruefung': 'Sichtprüfung',
    'objekt-protokoll_label_schutzleiter': 'Schutzleiter',
    'objekt-protokoll_label_potentialausgleich_vorhanden': 'Potentialausgleich vorhanden',
    'objekt-protokoll_label_typenschild_warnhinweis_kennzeichen':
      'Typenschild/Warnhinweise/Kennzeichnungen',
    'objekt-protokoll_label_gehaeuse_schutzabdeckung': 'Gehäuse/Schutzabdeckungen',
    'objekt-protokoll_label_anschlussleitung_befestigung_zugentlastung':
      'Anschlussleitung/Befestigung/Zugentlastung',
    'objekt-protokoll_label_kuehlluftoeffnungen_luftfilter': 'Kühlluftöffnungen/Luftfilter',
    'objekt-protokoll_label_isolierteile': 'Isolierteile',
    'objekt-protokoll_label_anzeichen_von_ueberlastung':
      'Anzeichen von Überlastung und unsachgemäßem Gebrauch',
    'objekt-protokoll_label_unzulaessige_eingriffe': 'Unzulässige Eingriffe und Änderungen',
    'objekt-protokoll_label_sichtpruefung_schutzleiter_io': 'i.O.',
    'objekt-protokoll_label_sichtpruefung_schutzleiter_nio': 'n.i.O.',
    'objekt-protokoll_label_sichtpruefung_potentialausgleich_io': 'Ja',
    'objekt-protokoll_label_sichtpruefung_potentialausgleich_nio': 'Nein',
    'objekt-protokoll_label_sichtpruefung_typenschild_io': 'i.O.',
    'objekt-protokoll_label_sichtpruefung_typenschild_nio': 'n.i.O.',
    'objekt-protokoll_label_sichtpruefung_gehaeuse_io': 'i.O.',
    'objekt-protokoll_label_sichtpruefung_gehaeuse_nio': 'n.i.O.',
    'objekt-protokoll_label_sichtpruefung_anschlussleitung_io': 'i.O.',
    'objekt-protokoll_label_sichtpruefung_anschlussleitung_nio': 'n.i.O.',
    'objekt-protokoll_label_sichtpruefung_kuehlluftoeffnungen_io': 'i.O.',
    'objekt-protokoll_label_sichtpruefung_kuehlluftoeffnungen_nio': 'n.i.O.',
    'objekt-protokoll_label_sichtpruefung_isolierteile_io': 'i.O.',
    'objekt-protokoll_label_sichtpruefung_isolierteile_nio': 'n.i.O.',
    'objekt-protokoll_label_sichtpruefung_ueberlastung_io': 'i.O.',
    'objekt-protokoll_label_sichtpruefung_ueberlastung_nio': 'n.i.O.',
    'objekt-protokoll_label_sichtpruefung_eingriffe_io': 'i.O.',
    'objekt-protokoll_label_sichtpruefung_eingriffe_nio': 'n.i.O.',
    'objekt-protokoll_abs_messungen': 'Messungen',

    'objekt-protokoll_table_titel_beschreibung': 'Beschreibung',
    'objekt-protokoll_table_titel_grenzwert': 'Grenzwert',
    'objekt-protokoll_table_titel_messwert': 'Messwert',
    'objekt-protokoll_table_titel_io': 'i.O.',
    'objekt-protokoll_table_titel_nio': 'n.i.O.',
    'objekt-protokoll_table_titel_bemerkungen': 'Bemerkungen',

    'objekt-protokoll_label_slw_beschreibung': 'Schutzleiterwiderstand',
    'objekt-protokoll_label_slw_grenzwerttext': 'R-PE',
    'objekt-protokoll_label_slw_grenzwert': '< 0,3Ω *',
    'objekt-protokoll_label_slw_grenzwert_einheit': 'Ω',
    'objekt-protokoll_label_slw_messwert': 'Messwert',
    'objekt-protokoll_label_slw_io': 'i.O.',
    'objekt-protokoll_label_slw_nio': 'n.i.O.',
    'objekt-protokoll_label_slw_bemerkung': 'Bemerkungen',

    'objekt-protokoll_label_iw_beschreibung': 'Isolationswiderstand',
    'objekt-protokoll_label_iw_grenzwerttext': 'R-ISO',
    'objekt-protokoll_label_iw_grenzwert': '> 1MΩ **',
    'objekt-protokoll_label_iw_grenzwert_einheit': 'MΩ',
    'objekt-protokoll_label_iw_messwert': 'Messwert',
    'objekt-protokoll_label_iw_io': 'i.O.',
    'objekt-protokoll_label_iw_nio': 'n.i.O.',
    'objekt-protokoll_label_iw_bemerkung': 'Bemerkungen',

    'objekt-protokoll_label_sls_beschreibung': 'Schutzleiterstrom (aktiv) ***',
    'objekt-protokoll_label_sls_grenzwerttext': 'I-PE',
    'objekt-protokoll_label_sls_grenzwert': '< 3,5mA ****',
    'objekt-protokoll_label_sls_grenzwert_einheit': 'mA',
    'objekt-protokoll_label_sls_messwert': 'Messwert',
    'objekt-protokoll_label_sls_io': 'i.O.',
    'objekt-protokoll_label_sls_nio': 'n.i.O.',
    'objekt-protokoll_label_sls_bemerkung': 'Bemerkungen',

    'objekt-protokoll_label_ds_beschreibung': 'Differenzstrom (aktiv)',
    'objekt-protokoll_label_ds_grenzwerttext': 'I-DIFF',
    'objekt-protokoll_label_ds_zusatz':
      'Es wird der Differenzstrom zwischen 3Phasen und Neutralleiter gemessen',
    'objekt-protokoll_label_ds_grenzwert': '< 3,5mA ****',
    'objekt-protokoll_label_ds_grenzwert_einheit': 'mA',
    'objekt-protokoll_label_ds_messwert': 'Messwert',
    'objekt-protokoll_label_ds_io': 'i.O.',
    'objekt-protokoll_label_ds_nio': 'n.i.O.',
    'objekt-protokoll_label_ds_bemerkung': 'Bemerkungen',

    'objekt-protokoll_label_bs_beschreibung': 'Berührungsstrom',
    'objekt-protokoll_label_bs_grenzwerttext': 'I-B',
    'objekt-protokoll_label_bs_grenzwert': '< 0,5mA',
    'objekt-protokoll_label_bs_grenzwert_einheit': 'mA',
    'objekt-protokoll_label_bs_messwert': 'Messwert',
    'objekt-protokoll_label_bs_io': 'i.O.',
    'objekt-protokoll_label_bs_nio': 'n.i.O.',
    'objekt-protokoll_label_bs_bemerkung': 'Bemerkungen',
    'objekt-protokoll_label_eas_beschreibung': 'Ersatzableitstrom (passiv) ***',
    'objekt-protokoll_label_eas_grenzwerttext': 'I-EA',
    'objekt-protokoll_label_eas_grenzwert': '< 3,5mA ****',
    'objekt-protokoll_label_eas_grenzwert_einheit': 'mA',
    'objekt-protokoll_label_eas_messwert': 'Messwert',
    'objekt-protokoll_label_eas_io': 'i.O.',
    'objekt-protokoll_label_eas_nio': 'n.i.O.',
    'objekt-protokoll_label_eas_bemerkung': 'Bemerkungen',
    'objekt-protokoll_schlusstext_messungen':
      'Sofern keine Herstellerangaben vorhanden sind, müssen die Grenzwerte gemäß SNR 462638 eingehalten werden.',
    'objekt-protokoll_abs_titel_funkp': 'Funktionsprüfung',
    'objekt-protokoll_abs_funkp': 'Funktion des Geräts',
    'objekt-protokoll_label_funkp_io': 'i.O.',
    'objekt-protokoll_label_funkp_nio': 'n.i.O.',
    'objekt-protokoll_abs_pruefergebnis': 'Prüfergebnis',
    'objekt-protokoll_titel_maengel_festgestellt': 'Mängel festgestellt',
    'objekt-protokoll_label_pruefergebnis_ja': 'ja',
    'objekt-protokoll_label_pruefergebnis_nein': 'nein',
    'objekt-protokoll_label_pruefergebnis_maengel': 'Mängel/Bemerkungen',
    'objekt-protokoll_abs_elektroregel':
      'Das elektrische Gerät entspricht den anerkannten Regeln der Elektrotechnik. Ein sicherer Gebrauch bei bestimmungsgemässer Anwendung ist gewährleistet.',
    'objekt-protokoll_label_elektroregel_ja': 'ja',
    'objekt-protokoll_label_elektroregel_nein': 'nein',
    'objekt-protokoll_abs_verw_messgeraete': 'Verwendete Messgeräte',
    'objekt-protokoll_label_fabrikat': 'Fabrikat',
    'objekt-protokoll_label_fabrikatbez': 'Benning',
    'objekt-protokoll_label_fabrikattyp': 'Typ',
    'objekt-protokoll_label_fabrikattyp_st725': 'ST 725',
    'objekt-protokoll_label_fabrikattyp_st725_zusatz': 'mit / ohne Prüfadapter',
    'objekt-protokoll_label_fabrikattyp_it105': 'IT 105 CH',
    'objekt-protokoll_label_fabrikattyp_it105_zusatz': 'Leckstromzange: Megger DCM 305E',
    'objekt-protokoll_abs_auftraggeber': 'Auftraggeber',
    'objekt-protokoll_label_auftraggeber_name': 'Name / Vorname',
    'objekt-protokoll_label_auftraggeber_unterschrift': 'Unterschrift',
    'objekt-protokoll_label_auftraggeber_unterschriftzuruecksetzen': 'Unterschrift zurücksetzen',
    'objekt-protokoll_abs_pruefer': 'Prüfer/-in',
    'objekt-protokoll_label_pruefer_name': 'Name / Vorname',
    'objekt-protokoll_label_pruefer_unterschrift': 'Unterschrift',
    'objekt-protokoll_label_pruefer_unterschriftzuruecksetzen': 'Unterschrift zurücksetzen',
    'objekt-protokoll_tooltip_1s':
      '* bei Anschlussleitung bis max 5m. zuzügl. 0.1 Ohm je weitere 7.5m (aber max. 1 Ohm!).',
    'objekt-protokoll_tooltip_2s':
      '** Schutzklasse 1: > 1 MOhm (mit Heizelementen: > 0.3 MOhm)  Schutzklasse 2: > 2 MOhm  Schutzklasse 3: > 0.25 MOhm',
    'objekt-protokoll_tooltip_3s':
      '*** Es sind der Schutzleiterstrom (d.h. bei Netzbetrieb) ODER der Ersatzableitstrom (ohne Netzbetrieb) zu messen.',
    'objekt-protokoll_tooltip_4s':
      '**** I Leak & I-EA= Ab 3.5kW Anschlussleistung gilt 1mA/kW jedoch max. 10mA!',
    'objekt-protokoll_tooltip_text':
      'Schutzklasse 2 und Schutzklasse 1 mit Isolierten Schutzabdeckungen ohne PE Verbindung',
    _label_neu: 'Erstellen',
    _label_speichern: 'Speichern',
    _label_loeschen: 'Löschen',
    _label_senden: 'Senden',
    _label_bestellen: 'Bestellen',
    _label_notizen: 'Notizen',
    specvalidator_required: 'Pflichtfeld',
    specvalidator_min_text: 'Minimal x Zeichen',
    specvalidator_min_zahl: 'Wert muss mind. x sein',
    specvalidator_max_text: 'Maximal x Zeichen',
    specvalidator_max_zahl: 'Wert darf max. x sein',
    arbeitsausgabe_supportcase_ereldigt: 'Supportcase ist erledigt',
    arbeitszeit_zeit_von_step: '5 Min. Schritte',
    arbeitszeit_zeit_bis_step: '5 Min. Schritte',
    arbeitszeit_isZeitVonKleinerZeitBis: 'Zeit von darf nicht grösser als Zeit bis sein',
    arbeitszeit_isTagInDatumssperre: 'Datum ist im gesperrten Zeitraum',
    arbeitszeit_ValidateSupportCaseErledigt: 'Supportcase ist erledigt',
    ausgabe_ValidateSupportcaseErledigt: 'Supportcase ist erledigt',
    bestellung_DeleteBestellung:
      'Nachbestellung ist bereits bestellt und darf nicht gelöscht werden',
    ersatzteil_ValidateSupportcaseErledigt: 'Supportcase ist erledigt',
    supportcaseobjectprotokoll_check_Pruefart:
      'Änderung, Instandsetzung oder Wiederholungsprüfung muss ausgewählt werden',
    supportcaseobjectprotokoll_check_Schutzklasse: 'Es muss eine Schutzklasse definiert werden',
    supportcaseobjectprotokoll_check_Hersteller: 'Pflichtfeld',
    supportcaseobjectprotokoll_check_Typ: 'Pflichtfeld',
    supportcaseobjectprotokoll_check_LiefSnr: 'Pflichtfeld',
    supportcaseobjectprotokoll_check_Schutzart: 'Pflichtfeld',
    supportcaseobjectprotokoll_check_Nennspannung: 'Pflichtfeld',
    supportcaseobjectprotokoll_check_Nennstrom: 'Pflichtfeld',
    supportcaseobjectprotokoll_check_Nennleistung: 'Pflichtfeld',
    supportcaseobjectprotokoll_check_Frequenz: 'Pflichtfeld',
    supportcaseobjectprotokoll_check_Mw_Schutzleiterwiederstand: 'Pflichtfeld',
    supportcaseobjectprotokoll_check_Io_Schutzleiterwiederstand: 'Pflichtfeld',
    supportcaseobjectprotokoll_check_Mw_Isolationswiederstand: 'Pflichtfeld',
    supportcaseobjectprotokoll_check_Io_Isolationswiederstand: 'Pflichtfeld',
    supportcaseobjectprotokoll_check_Mw_Schutzleiterstrom: 'Pflichtfeld',
    supportcaseobjectprotokoll_check_Io_Schutzleiterstrom: 'Pflichtfeld',
    supportcaseobjectprotokoll_check_Funktionspruefung: 'i.O. oder n.i.O.',
    supportcaseobjectprotokoll_check_Maengel: 'ja oder nein',
    supportcaseobjectprotokoll_check_Regeln: 'ja oder nein',
    supportcaseobjectprotokoll_check_PrueferUnterschrift: 'Unterschrift benötigt',
    supportcaseobjectprotokoll_required_KundeName_KundeUnterschrift:
      'Auftraggeber und Unterschirft benötigt',
    zeit_isZeitVonKleinerZeitBis: 'Zeit von darf nicht grösser als Zeit bis sein',
    zeit_isTagInDatumssperre: 'Datum ist im gesperrten Zeitraum',
    'zerf-week-form-day0': 'Montag',
    'zerf-week-form-day1': 'Dienstag',
    'zerf-week-form-day2': 'Mittwoch',
    'zerf-week-form-day3': 'Donnerstag',
    'zerf-week-form-day4': 'Freitag',
    'zerf-week-form-day5': 'Samstag',
    'zerf-week-form-day6': 'Sonntag',
    'zerf-week-form-weektotal': 'Gesamt',
    'zerf-week-form-wochenuebersicht': 'Wochenübersicht',
    'zerf-week-form-wochevom': 'Woche vom',
    'zerf-week-form-wochentag': 'Wochentag',
    'zerf-week-form-ArbeitszeitExklFakt': 'Arbeitszeit',
    'zerf-week-form-ArbeitszeitInklFakt': 'Arbeitszeit mit Zuschlag',
    'user-switch_reset_alias': 'Benutzerwechsel zurücksetzen',
    'user-switch_tabtitel_Username': 'Username',
    'user-switch_tabtitel_Vorname': 'Vorname',
    'user-switch_tabtitel_Nachname': 'Nachname',
    textbausteinreferenz_label: 'Textbaustein',
  },
  'fr-CH': {
    menu_titel_home: 'Home',
    'menu_titel_user-switch': "Changement d'utilisateur",
    menu_titel_auth: 'Anmelden',
    menu_titel_supporte: 'Supports',
    menu_titel_zeiterfassung: "Saisie de l'heure",
    menu_titel_objektprotokoll: 'Protocole SNR',
    menu_titel_objektdetail: 'Détails de la propriété',
    menu_titel_ausgaben: 'Frais',
    menu_titel_nachbestellung: 'Commande suppl.',
    menu_titel_bestellvorschlaege: 'Propositions de commande',
    'menu_titel_s-zeiterfassung': 'Temps de travail',
    'menu_titel_s-ausgaben': 'Sorties',
    'menu_titel_s-ersatzteile': 'Pièces de rechange',
    'menu_titel_s-rapport': 'Rapport',
    'menu_titel_s-objekt-history': "Histoire d'objet",
    'menu_titel_s-support-detail': 'Objets',
    'menu_titel_s-objekt-detail': 'Détails de la propriété',
    auth_login: 'login',
    auth_logintext: "Entrez votre nom d'utilisateur et votre mot de passe",
    auth_username: "Nom d'utilisateur",
    auth_passwort: 'mot de passe',
    auth_rememberme: 'rester connecté',
    auth_ok: 'bien',
    supporte_filter_filter: 'filtre',
    supporte_filter_suchtextfeld: 'texte de recherche',
    supporte_filter_suchbuttontext: 'recherche',
    supporte_filter_open: 'tous ouverts',
    supporte_filter_closed: 'achevé',
    supporte_card_auftragsgeber: "Donneur d'ordre",
    supporte_card_supportart: 'Genre de support',
    'support-detail_maincard_kontaktperson': 'Personne de contact',
    'support-detail_maincard_mail': 'Mail',
    'support-detail_maincard_adressnummer': "Numéro d'adresse",
    'support-detail_maincard_supportart': 'Genre de support',
    'support-detail_objectcard_garantie': 'Garantie',
    'support-detail_objectcard_standort': "Lieu d'implantation",
    'support-detail-objectcard-seriennr': 'numéro de série',
    'support-detail_objectcard_monteur': 'Monteur responsable',
    'support-detail_objectcard_snrprotocol': 'SNR',
    'support-detail_objectcard_erledigen': 'finir',
    'support-detail_objectcard_oeffnen': 'ouvrir',
    'support-detail_objectcard_objectdetails': 'Détails de la propriété',
    'support-notiz_main_titel' : 'Notes de cas d\'assistance',
    'support-notiz_label_id' : 'Id',
    'support-notiz_label_supportnr' : 'Numéro du support',
    'support-notiz_label_notiz' : 'Remarque',
    'objekt-history_maincard_garantie': 'Garantie',
    'objekt-history_maincard_standort': "Lieu d'implantation",
    'objekt-history_historycard_historie': 'Historique',
    'objekt-history_historycard_datum': 'Date',
    'objekt-history_historycard_beschreibung': 'Description',
    'objekt-history_historycard_zustaendigkeit': 'Compétence',
    's-zeiterfassung_main_titel': "Entrée de l'heure pour le support",
    's-zeiterfassung_create_titel': 'créer',
    's-zeiterfassung_edit_titel': 'traiter',
    's-zeiterfassung_label_faktor': 'Facteur',
    's-zeiterfassung_label_dauer': 'Durée',
    's-zeiterfassung_label_tag': 'Jour',
    's-zeiterfassung_label_zeitvon': 'Heure de',
    's-zeiterfassung_label_zeitbis': "Heure jusqu'à",
    's-zeiterfassung_label_objekt': 'Objet',
    's-zeiterfassung_label_kostenstelle': 'Centre de coûts',
    's-zeiterfassung_label_kilometer': 'Kilomètres',
    's-zeiterfassung_label_text': 'Texte',
    's-ersatzteile_main_titel': 'Matériel pour le support',
    's-ersatzteile_create_titel': 'saisir',
    's-ersatzteile_edit_titel': 'traiter',
    's-ersatzteile_label_tag': 'Jour',
    's-ersatzteile_label_objekt': 'Objet',
    's-ersatzteile_label_lagerort': "Lieu d'implantation",
    's-ersatzteile_label_artikel': 'Article',
    's-ersatzteile_label_artikeltext': "Texte de l'article",
    's-ersatzteile_label_internenotiz': 'Note interne',
    's-ersatzteile_label_menge': 'Quantité',
    's-ersatzteile_label_verkaufseinheit': 'Verkaufseinheit',
    's-ersatzteile_label_bestellen': 'Commandez à nouveau',
    's-ersatzteile_label_lagerbestand': 'Stocker',
    's-ausgaben_main_titel': 'Sorties pour le support',
    's-ausgaben_create_titel': 'saisir',
    's-ausgaben_edit_titel': 'traiter',
    's-ausgaben_label_user': 'Utilisateur',
    's-ausgaben_label_tag': 'Jour',
    's-ausgaben_label_text': 'Texte',
    's-ausgaben_label_internenotiz': 'Note interne',
    's-ausgaben_label_betrag': 'Montant',
    's-ausgaben_label_menge': 'Quantité',
    's-ausgaben_label_gesamtpreis': 'Prix global',
    's-ausgaben_label_objekt': 'Objet',
    's-ausgaben_label_kostenstelle': 'Centre de coûts',
    's-ausgaben_label_beleg': 'Justificatif disponible?',
    's-ausgaben_label_beleg_on_item': 'Justificatif',
    rapport_main_titel: 'Rapport',
    rapport_titel_auftraggeber: 'Client',
    rapport_titel_monteur: 'Monteur',
    rapport_abstitel_objekte: 'Objets',
    rapport_tabtitel_objekt: 'Objet',
    rapport_tabtitel_beschreibung: 'Description',
    rapport_tabtitel_serviceart: 'GS',
    rapport_tabtitel_grund: 'Motif',
    rapport_tabtitel_status: 'Statut',
    rapport_abstitel_ausgaben: 'Sorties',
    rapport_tabtitel_datum: 'Date',
    rapport_tabtitel_anzahl: 'Nombre',
    rapport_tabtitel_text: 'Texte',
    rapport_tabtitel_menge: 'Quantité',
    rapport_tabtitel_einheit: 'Unité',
    rapport_tabtitel_mitarbeiter: 'de',
    rapport_abstitel_ersatzteile: 'Pièces de rechange',
    rapport_tabtitel_artikel: 'Article',
    rapport_abstitel_arbeitszeiten: 'Temps de travail',
    rapport_tabtitel_dauer: 'Durée',
    rapport_tabtitel_zeitvon: 'de',
    rapport_tabtitel_zeitbis: 'à',
    rapport_tabtitel_km: 'km',
    rapport_abstitel_Reisezeitpauschalen: 'Forfaits temps de voyage',
    rapport_tabtitel_kostenstelle: 'Centre de coûts',
    rapport_tabtotal_totalarbeitszeit: 'Temps de travail total',
    rapport_tabtotal_totalkm: 'Total km',
    rapport_tr_reisezeitpauschale_reisezeit_text: 'Temps de parcours',
    rapport_tr_reisezeitpauschale_reiseweg_text: 'Aller-retour',
    rapport_tr_reisezeitpauschale_fahrkosten_text: 'Frais de voyage',
    // rapport_label_arbeitsbericht: "Rapport d'activité",
    rapport_label_arbeitsbericht: 'Les particularités',
    rapport_label_kundenmail: 'Mail client',
    rapport_label_protokollmail: 'Mail protocole',
    rapport_label_arbeiterklaert: 'Travail expliqué',
    rapport_label_arbeiterledigt: 'Travail achevé',
    rapport_label_unterschriftzuruecksetzen: 'Signature réinitialisée',
    rapport_label_bestellnummer: 'numéro de commande',
    rapport_btn_zwischenrapport_senden: 'Envoyer le rapport intermédiaire',
    rapport_btn_zwischenrapport_gesendet: 'Envoyés le rapport intermédiaire',
    rapport_btn_rapport_senden: 'Envoyer le rapport',
    rapport_btn_rapport_gesendet: 'Rapport envoyé',
    rapport_btn_rapport_und_snr_senden: 'Envoyer le rapport et Protocole SNR',
    rapport_btn_rapport_und_snr_gesendet: 'Envoyés le rapport et Protocole SNR',
    zeiterfassung_main_titel_sc: "Entrée de l'heure pour le ,",
    zeiterfassung_main_titel: "Entrée de l'heure",
    zeiterfassung_create_titel: 'saisir',
    zeiterfassung_edit_titel: 'traiter',
    zeiterfassung_label_faktor: 'Facteur',
    zeiterfassung_label_dauer: 'Durée',
    zeiterfassung_label_zeitvon: 'Heure de',
    zeiterfassung_label_zeitbis: "Heure jusqu'à",
    zeiterfassung_label_tag: 'Jour',
    zeiterfassung_label_objekt: 'Object',
    zeiterfassung_label_kostenstelle: 'Centre de coûts',
    zeiterfassung_label_text: 'Texte',
    zeiterfassung_label_halbertag: '1/2 Jour',
    zeiterfassung_label_ganzertag: '1/1 Jour',
    ausgaben_main_titel: 'Sorties',
    ausgaben_create_titel: 'saisir',
    ausgaben_edit_titel: 'traiter',
    ausgaben_label_tag: 'Jour',
    ausgaben_label_text: 'Texte',
    ausgaben_label_betrag: 'Montant',
    ausgaben_label_menge: 'Quantité',
    ausgaben_label_gesamtpreis: 'Prix global',
    ausgaben_label_kostenstelle: 'Centre de coûts',
    ausgaben_label_Beleg: 'Justificatif disponible?',
    ausgaben_label_user: 'Utilisateur',
    nachbestellung_filter_filter: 'filtre',
    nachbestellung_filter_open: 'tous ouverts',
    nachbestellung_filter_bereitsbestellt: 'déjà commandé',
    nachbestellung_main_titel: 'Commande de matériel',
    nachbestellung_create_titel: 'saisir',
    nachbestellung_edit_titel: 'traiter',
    nachbestellung_view_titel: 'afficher',
    nachbestellung_label_menge: 'Quantité',
    nachbestellung_label_bestelltam: 'Commandé le',
    nachbestellung_label_artikel: 'Article',
    nachbestellung_label_lagerort: 'Lieu de stockage',
    nachbestellung_label_objekt: 'Objet',
    nachbestellung_label_freigabe: 'commander',
    nachbestellung_label_kommission: 'commission',
    nachbestellung_label_lagerbestand: 'Stocker',
    betsellvorschlag_label_entnommen_vom_monteur: "prise par l'installateur",
    'objekt-protokoll_titel':
      'Tests initiaux et tests répétés du matériel électrique portable Rapport de test et de mesure selon SNR 462638',
    'objekt-protokoll_abs_protokoll_anzeigen': 'Afficher le protocole',
    'objekt-protokoll_label_protokoll_nicht_benoetigt': 'Protocole SNR non nécessaire',
    'objekt-protokoll_label_grund': 'raison',
    'objekt-protokoll_label_supportnr': 'Numéro du support',
    'objekt-protokoll_label_objektnr': 'Numéro de object',
    'objekt-protokoll_label_datum': 'Date',
    'objekt-protokoll_label_auftraggeber': 'Client',
    'objekt-protokoll_label_auftragnehmer': 'Entrepreneur',
    'objekt-protokoll_label_kundennr': 'Numéro de client',
    'objekt-protokoll_label_pruefer': 'examinateur',
    'objekt-protokoll_abs_pruefung_nach': ' Vérifier après',
    'objekt-protokoll-label_snr': ' SNR 462638 ',
    'objekt-protokoll-label_en': 'EN 60204',
    'objekt-protokoll_label_aenderung': 'changement',
    'objekt-protokoll_label_instandsetzung': 'réparation',
    'objekt-protokoll_label_wiederholungspruefung': ' examen de rattrapage',
    'objekt-protokoll_abs_schutzklasse': 'classe de protection',
    'objekt-protokoll_label_schutzklasse1': '1',
    'objekt-protokoll_label_schutzklasse2': '2',
    'objekt-protokoll_label_schutzklasse3': '3',
    'objekt-protokoll_abs_geraetedaten': " données de l'appareil",
    'objekt-protokoll_label_hersteller': 'fabricant',
    'objekt-protokoll_label_typ': 'type',
    'objekt-protokoll_label_seriennr': 'numéro de série',
    'objekt-protokoll_label_schutzart': 'classe de protection IP',
    'objekt-protokoll_label_nennspannung': 'tension nominale',
    'objekt-protokoll_label_nennstrom': 'courant nominal',
    'objekt-protokoll_label_nennleistung': 'puissance nominale',
    'objekt-protokoll_label_frequenz': 'fréquence',
    'objekt-protokoll_abs_sichtpruefung': 'inspection visuelle',
    'objekt-protokoll_label_schutzleiter': 'conducteur de protection',
    'objekt-protokoll_label_potentialausgleich_vorhanden': 'Liaison équipotentielle installée?',
    'objekt-protokoll_label_typenschild_warnhinweis_kennzeichen':
      'Plaque signalétique/avertissements/marques',
    'objekt-protokoll_label_gehaeuse_schutzabdeckung': 'Boîtier et housse de protection',
    'objekt-protokoll_label_anschlussleitung_befestigung_zugentlastung':
      'Câble de raccordement/fixation/décharge de traction',
    'objekt-protokoll_label_kuehlluftoeffnungen_luftfilter':
      "Ouvertures d'air de refroidissement / Filtre",
    'objekt-protokoll_label_isolierteile': 'Isolation',
    'objekt-protokoll_label_anzeichen_von_ueberlastung': 'Signes de surcharge',
    'objekt-protokoll_label_unzulaessige_eingriffe': 'Interventions et changements inadmissibles',
    'objekt-protokoll_label_sichtpruefung_schutzleiter_io': 'i.O.',
    'objekt-protokoll_label_sichtpruefung_schutzleiter_nio': 'n.i.O.',
    'objekt-protokoll_label_sichtpruefung_potentialausgleich_io': 'Oui',
    'objekt-protokoll_label_sichtpruefung_potentialausgleich_nio': 'Non',
    'objekt-protokoll_label_sichtpruefung_typenschild_io': 'i.O.',
    'objekt-protokoll_label_sichtpruefung_typenschild_nio': 'n.i.O.',
    'objekt-protokoll_label_sichtpruefung_gehaeuse_io': 'i.O.',
    'objekt-protokoll_label_sichtpruefung_gehaeuse_nio': 'n.i.O.',
    'objekt-protokoll_label_sichtpruefung_anschlussleitung_io': 'i.O.',
    'objekt-protokoll_label_sichtpruefung_anschlussleitung_nio': 'n.i.O.',
    'objekt-protokoll_label_sichtpruefung_kuehlluftoeffnungen_io': 'i.O.',
    'objekt-protokoll_label_sichtpruefung_kuehlluftoeffnungen_nio': 'n.i.O.',
    'objekt-protokoll_label_sichtpruefung_isolierteile_io': 'i.O.',
    'objekt-protokoll_label_sichtpruefung_isolierteile_nio': 'n.i.O.',
    'objekt-protokoll_label_sichtpruefung_ueberlastung_io': 'i.O.',
    'objekt-protokoll_label_sichtpruefung_ueberlastung_nio': 'n.i.O.',
    'objekt-protokoll_label_sichtpruefung_eingriffe_io': 'i.O.',
    'objekt-protokoll_label_sichtpruefung_eingriffe_nio': 'n.i.O.',
    'objekt-protokoll_abs_messungen': 'mesures',
    'objekt-protokoll_table_titel_beschreibung': 'description',
    'objekt-protokoll_table_titel_grenzwert': 'limite',
    'objekt-protokoll_table_titel_messwert': 'mesures',
    'objekt-protokoll_table_titel_io': 'i.O.',
    'objekt-protokoll_table_titel_nio': 'n.i.O.',
    'objekt-protokoll_table_titel_bemerkungen': 'remarques',

    'objekt-protokoll_label_slw_beschreibung': 'Résistance',
    'objekt-protokoll_label_slw_grenzwerttext': 'R-PE',
    'objekt-protokoll_label_slw_grenzwert': '< 0,3Ω *',
    'objekt-protokoll_label_slw_grenzwert_einheit': 'Ω',
    'objekt-protokoll_label_slw_messwert': 'mesures',
    'objekt-protokoll_label_slw_io': 'i.O.',
    'objekt-protokoll_label_slw_nio': 'n.i.O.',
    'objekt-protokoll_label_slw_bemerkung': 'remarques',

    'objekt-protokoll_label_iw_beschreibung': "la résistance d'isolement",
    'objekt-protokoll_label_iw_grenzwerttext': 'R-ISO',
    'objekt-protokoll_label_iw_grenzwert': '> 1MΩ **',
    'objekt-protokoll_label_iw_grenzwert_einheit': 'MΩ',
    'objekt-protokoll_label_iw_messwert': 'mesures',
    'objekt-protokoll_label_iw_io': 'i.O.',
    'objekt-protokoll_label_iw_nio': 'n.i.O.',
    'objekt-protokoll_label_iw_bemerkung': 'remarques',

    'objekt-protokoll_label_sls_beschreibung': 'Courant de conducteur de protection ***',
    'objekt-protokoll_label_sls_grenzwerttext': 'I-PE',
    'objekt-protokoll_label_sls_grenzwert': '< 3,5mA ****',
    'objekt-protokoll_label_sls_grenzwert_einheit': 'mA',
    'objekt-protokoll_label_sls_messwert': 'mesures',
    'objekt-protokoll_label_sls_io': 'i.O.',
    'objekt-protokoll_label_sls_nio': 'n.i.O.',
    'objekt-protokoll_label_sls_bemerkung': 'remarques',

    'objekt-protokoll_label_ds_beschreibung': 'Courant différentiel',
    'objekt-protokoll_label_ds_grenzwerttext': 'I-DIFF',
    'objekt-protokoll_label_ds_zusatz':
      'Le courant différentiel entre le conducteur triphasé et neutre est mesuré',
    'objekt-protokoll_label_ds_grenzwert': '< 3,5mA ****',
    'objekt-protokoll_label_ds_grenzwert_einheit': 'mA',
    'objekt-protokoll_label_ds_messwert': 'mesures',
    'objekt-protokoll_label_ds_io': 'i.O.',
    'objekt-protokoll_label_ds_nio': 'n.i.O.',
    'objekt-protokoll_label_ds_bemerkung': 'remarques',

    'objekt-protokoll_label_bs_beschreibung': ' courant de contact',
    'objekt-protokoll_label_bs_grenzwerttext': 'I-B',
    'objekt-protokoll_label_bs_grenzwert': '< 0,5mA',
    'objekt-protokoll_label_bs_grenzwert_einheit': 'mA',
    'objekt-protokoll_label_bs_messwert': 'mesures',
    'objekt-protokoll_label_bs_io': 'i.O.',
    'objekt-protokoll_label_bs_nio': 'n.i.O.',
    'objekt-protokoll_label_bs_bemerkung': 'remarques',

    'objekt-protokoll_label_eas_beschreibung': 'remplaçant ***',
    'objekt-protokoll_label_eas_grenzwerttext': 'I-EA',
    'objekt-protokoll_label_eas_grenzwert': '< 3,5mA ****',
    'objekt-protokoll_label_eas_grenzwert_einheit': 'mA',
    'objekt-protokoll_label_eas_messwert': 'mesures',
    'objekt-protokoll_label_eas_io': 'i.O.',
    'objekt-protokoll_label_eas_nio': 'n.i.O.',
    'objekt-protokoll_label_eas_bemerkung': 'remarques',

    'objekt-protokoll_schlusstext_messungen':
      "Si aucune instruction du fabricant n'est disponible, les valeurs limites conformes à SNR 462638 doivent être respectées.",
    'objekt-protokoll_abs_titel_funkp': 'test fonctionnel',
    'objekt-protokoll_abs_funkp': "Fonction de l'appareil",
    'objekt-protokoll_label_funkp_io': 'i.O.',
    'objekt-protokoll_label_funkp_nio': 'n.i.O.',
    'objekt-protokoll_abs_pruefergebnis': 'résultat du test',
    'objekt-protokoll_titel_maengel_festgestellt': 'Défauts trouvés',
    'objekt-protokoll_label_pruefergebnis_ja': 'oui',
    'objekt-protokoll_label_pruefergebnis_nein': 'non',
    'objekt-protokoll_label_pruefergebnis_maengel': 'Les défauts / commentaires',
    'objekt-protokoll_abs_elektroregel':
      "L'appareil électrique est conforme aux règles reconnues de l'électrotechnique. Une utilisation sûre dans des conditions normales d'utilisation est garantie.",
    'objekt-protokoll_label_elektroregel_ja': 'oui',
    'objekt-protokoll_label_elektroregel_nein': 'non',
    'objekt-protokoll_abs_verw_messgeraete': 'Appareils de mesure utilisé',
    'objekt-protokoll_label_fabrikat': 'Fabrikat',
    'objekt-protokoll_label_fabrikatbez': 'Benning',
    'objekt-protokoll_label_fabrikattyp': 'Typ',
    'objekt-protokoll_label_fabrikattyp_st725': 'ST 725',
    'objekt-protokoll_label_fabrikattyp_st725_zusatz': 'mit / ohne Prüfadapter',
    'objekt-protokoll_label_fabrikattyp_it105': 'IT 105 CH',
    'objekt-protokoll_label_fabrikattyp_it105_zusatz': 'Leckstromzange: Megger DCM 305E',
    'objekt-protokoll_abs_auftraggeber': 'Client',
    'objekt-protokoll_label_auftraggeber_name': 'Nom / Prénom',
    'objekt-protokoll_label_auftraggeber_unterschrift': 'signature',
    'objekt-protokoll_label_auftraggeber_unterschriftzuruecksetzen': 'Signature réinitialisée',
    'objekt-protokoll_abs_pruefer': 'Examin',
    'objekt-protokoll_label_pruefer_name': 'Nom / Prénom',
    'objekt-protokoll_label_pruefer_unterschrift': 'signature',
    'objekt-protokoll_label_pruefer_unterschriftzuruecksetzen': 'Signature réinitialisée',
    'objekt-protokoll_tooltip_1s':
      "* avec câble de raccordement jusqu'à max. 5m. plus. 0.1 ohms chaque 7.5 m supplémentaires (mais maximum 1 ohm!)",
    'objekt-protokoll_tooltip_2s':
      '** Classe de protection 1: > 1 MOhm (avec éléments chauffants: > 0.3 MOhm)  Classe de protection 2: > 2 MOhm  Classe de protection 3: > 0.25 MOhm',
    'objekt-protokoll_tooltip_3s':
      "*** Mesurer le courant du conducteur de protection (c'est-à-dire en fonctionnement sur le secteur) OU le courant de fuite de remplacement (sans fonctionnement sur le secteur)",
    'objekt-protokoll_tooltip_4s':
      '**** I Leak & I-EA= À partir de 3.5 kW de charge connectée, le courant de fuite peut être de 1 mA/kW, mais max. 10mA',
    'objekt-protokoll_tooltip_text':
      'Seule la classe de protection 2 et Classe de protection 1 avec  couvercles isolés sans connexion de contacteur',
    _label_neu: 'créer',
    _label_speichern: 'mémoriser',
    _label_loeschen: 'annuler',
    _label_senden: 'envoyer',
    _label_bestellen: 'Commander',
    _label_notizen: 'Remarques',
    specvalidator_required: 'Case obligatoire',
    specvalidator_min_text: 'Max. x caractères de texte',
    specvalidator_min_zahl: "La valeur doit être d'au moins x",
    specvalidator_max_text: 'Max. x caractères de texte',
    specvalidator_max_zahl: 'La valeur peut être de max. x',
    arbeitsausgabe_supportcase_ereldigt: 'Support achevé',
    arbeitszeit_zeit_von_step: 'Etape de 5 min.',
    arbeitszeit_zeit_bis_step: 'Etape de 5 min.',
    arbeitszeit_isZeitVonKleinerZeitBis: "L'heure de ne doit pas dépasser celle jusqu'à",
    arbeitszeit_isTagInDatumssperre: 'La date est bloquée dans la plage horaire',
    arbeitszeit_ValidateSupportCaseErledigt: 'Support achevé',
    ausgabe_ValidateSupportcaseErledigt: 'Support achevé',
    bestellung_DeleteBestellung: 'Commande suppl. déjà passée et ne doit pas être annulée',
    ersatzteil_ValidateSupportcaseErledigt: 'Support achevé',
    supportcaseobjectprotokoll_check_Pruefart:
      'Modification, réparation ou contrôle systématique doit être sélectionné',
    supportcaseobjectprotokoll_check_Schutzklasse: 'Une classe de protection doit être définie',
    supportcaseobjectprotokoll_check_Hersteller: 'Case obligatoire',
    supportcaseobjectprotokoll_check_Typ: 'Case obligatoire',
    supportcaseobjectprotokoll_check_LiefSnr: 'Case obligatoire',
    supportcaseobjectprotokoll_check_Schutzart: 'Case obligatoire',
    supportcaseobjectprotokoll_check_Nennspannung: 'Case obligatoire',
    supportcaseobjectprotokoll_check_Nennstrom: 'Case obligatoire',
    supportcaseobjectprotokoll_check_Nennleistung: 'Case obligatoire',
    supportcaseobjectprotokoll_check_Frequenz: 'Case obligatoire',
    supportcaseobjectprotokoll_check_Mw_Schutzleiterwiederstand: 'Case obligatoire',
    supportcaseobjectprotokoll_check_Io_Schutzleiterwiederstand: 'Case obligatoire',
    supportcaseobjectprotokoll_check_Mw_Isolationswiederstand: 'Case obligatoire',
    supportcaseobjectprotokoll_check_Io_Isolationswiederstand: 'Case obligatoire',
    supportcaseobjectprotokoll_check_Mw_Schutzleiterstrom: 'Case obligatoire',
    supportcaseobjectprotokoll_check_Io_Schutzleiterstrom: 'Case obligatoire',
    supportcaseobjectprotokoll_check_Funktionspruefung: 'OK ou pas OK',
    supportcaseobjectprotokoll_check_Maengel: 'oui ou non',
    supportcaseobjectprotokoll_check_Regeln: 'oui ou non',
    supportcaseobjectprotokoll_check_PrueferUnterschrift: 'Signature nécessaire',
    supportcaseobjectprotokoll_required_KundeName_KundeUnterschrift:
      'Client et signature nécessaires',
    zeit_isZeitVonKleinerZeitBis: "L'heure de ne doit pas dépasser celle jusqu'à",
    zeit_isTagInDatumssperre: 'La date est bloquée dans la plage horaire',
    'zerf-week-form-day0': 'lundi',
    'zerf-week-form-day1': 'mardi',
    'zerf-week-form-day2': 'mercredi',
    'zerf-week-form-day3': 'jeudi',
    'zerf-week-form-day4': 'vendredi',
    'zerf-week-form-day5': 'samedi',
    'zerf-week-form-day6': 'dimanche',
    'zerf-week-form-weektotal': 'global',
    'zerf-week-form-wochenuebersicht': "Vue d'ensemble hebdomadaire",
    'zerf-week-form-wochevom': 'semaine de',
    'zerf-week-form-wochentag': 'Jour de la semaine',
    'zerf-week-form-ArbeitszeitExklFakt': 'Temps de travail',
    'zerf-week-form-ArbeitszeitInklFakt': 'Temps de travail avec supplément',
    'user-switch_reset_alias': "Réinitialiser le changement d'utilisateur",
    'user-switch_tabtitel_Username': "Nom d'utilisateur",
    'user-switch_tabtitel_Vorname': 'Prénom',
    'user-switch_tabtitel_Nachname': 'Nom de famille',
    textbausteinreferenz_label: 'Module de texte',
  },
};

Translations.de = Translations['de-CH'];
Translations['de-ch'] = Translations['de-CH'];
Translations['de-DE'] = Translations['de-CH'];
Translations['de-de'] = Translations['de-CH'];
Translations['de-AT'] = Translations['de-CH'];
Translations['de-at'] = Translations['de-CH'];
Translations['de-LI'] = Translations['de-CH'];
Translations['de-li'] = Translations['de-CH'];

Translations.fr = Translations['fr-CH'];
Translations['fr-ch'] = Translations['fr-CH'];
Translations['fr-CA'] = Translations['fr-CH'];
Translations['fr-FR'] = Translations['fr-CH'];
Translations['fr-fr'] = Translations['fr-CH'];
