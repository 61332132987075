import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme';
import { FBP } from '@furo/fbp';
import '@furo/layout/src/furo-horizontal-flex';

/**
 *
 * The API may change, documentation will follow
 *
 * `furo-app-drawer`
 * todo Describe the element
 *
 * @summary Application drawer
 * @customElement
 * @demo demo-furo-app-drawer
 * @appliesMixin FBP
 */
class FuroAppDrawer extends FBP(LitElement) {
  constructor() {
    super();
    /**
     * Width for the autofloat
     * @type {number}
     */
    this.floatBreakpoint = 1159;
    this._movementDetectionRange = 10;

    // return **this** to component which want to connect
    window.addEventListener('connect-to-drawer-requested', e => {
      if (e.detail.name === this.name) {
        e.detail.drawer = this;
      }
    });
  }

  /**
   * @private
   * @return {Object}
   */
  static get properties() {
    return {
      /**
       * Use method floatDrawer or set this attribute to enable float mode
       * @private
       */
      _isFloating: { type: Boolean, reflect: true, attribute: 'float' },

      /**
       * Enable this to put the drawer on the right side
       */
      isReverse: { type: Boolean, reflect: true, attribute: 'reverse' },
      /**
       * disables automatic floating mode
       */
      permanent: { type: Boolean },
      /**
       * let the menu float (hidden).
       */
      float: { type: Boolean },
      /**
       * Min width of the app-drawer to switch to floating mode
       */
      floatBreakpoint: { type: Number, attribute: 'float-breakpoint' },
      /**
       * name of this drawer, needed if you want to connect to this drawer
       */
      name: { type: String },
    };
  }

  /**
   * helper variable to set the floating
   * @param val
   * @private
   */
  set __isFloating(val) {
    this._isFloating = val;
    if (val) {
      /**
       * @event is-floating
       * Fired when drawer is in floating mode. This event is fired when drawer is closed and opened
       */
      const customEvent = new Event('is-floating', { composed: true, bubbles: true });
      this.dispatchEvent(customEvent);
    } else {
      /**
       * @event is-pinned
       * Fired when drawer is in pinned mode.
       */
      const customEvent = new Event('is-pinned', { composed: true, bubbles: true });
      this.dispatchEvent(customEvent);
    }
  }

  get __isFloating() {
    return this._isFloating;
  }

  /**
   * open the drawer when it is in float mode
   */
  open() {
    this.isOpen = true;
    if (this.__isFloating) {
      const drawer = this.shadowRoot.getElementById('drawer');
      // drawer.style.transform = "translate3d(0, 0, 0)";
      if (this.isReverse) {
        // drawer.style.transform = "translate3d("+ width +"px, 0, 0)";
        drawer.style.right = 0;
      } else {
        drawer.style.left = 0;
        // drawer.style.transform = "translate3d(-"+ width +"px, 0, 0)";
      }
      const backdrop = this.shadowRoot.getElementById('backdrop');
      backdrop.style.opacity = 1;
      backdrop.style.pointerEvents = 'auto';

      // unregister movement tracker
      this.removeEventListener('mousemove', this.moveHandler, true);
      this.removeEventListener('touchmove', this.moveHandler, true);
      // unregister trackend
      this.removeEventListener('mouseup', this.trackEnd, { once: true });
      this.removeEventListener('touchend', this.trackEnd, { once: true });
    }
    /**
     * @event drawer-opened
     * Fired when drawer was opened.
     */
    const customEvent = new Event('drawer-opened', { composed: true, bubbles: true });
    this.dispatchEvent(customEvent);
  }

  /**
   * closes the drawer when it is in float mode
   */
  close() {
    this.isOpen = false;
    if (this.__isFloating) {
      const drawer = this.shadowRoot.getElementById('drawer');
      const { width } = drawer.getBoundingClientRect();
      if (this.isReverse) {
        // drawer.style.transform = "translate3d("+ width +"px, 0, 0)";
        drawer.style.right = `${-width}px`;
      } else {
        drawer.style.left = `${-width}px`;
        // drawer.style.transform = "translate3d(-"+ width +"px, 0, 0)";
      }
      const backdrop = this.shadowRoot.getElementById('backdrop');
      backdrop.style.opacity = 0;
      backdrop.style.pointerEvents = 'none';

      // unregister movement tracker
      this.removeEventListener('mousemove', this.moveHandler, true);
      this.removeEventListener('touchmove', this.moveHandler, true);
      // unregister trackend
      this.removeEventListener('mouseup', this.trackEnd, { once: true });
      this.removeEventListener('touchend', this.trackEnd, { once: true });
    }
    /**
     * @event drawer-closed
     * Fired when drawer was closed.
     */
    const customEvent = new Event('drawer-closed', { composed: true, bubbles: true });
    this.dispatchEvent(customEvent);
  }

  /**
   * let the drawer float
   */
  floatDrawer() {
    this.__isFloating = true;
  }

  /**
   * disable the floating
   */
  pinDrawer() {
    this.__isFloating = false;
  }

  /**
   * Put the drawer on the right side
   *
   * Or use the attribute reverse for the same effect
   *
   */
  putDrawerToRight() {
    this.isReverse = true;
  }

  /**
   * Put the drawer on the left side (default)
   */
  putDrawerToLeft() {
    this.isReverse = false;
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()
    /**
     * Register hook on wire --backdropClicked to
     * close the menu
     */
    this._FBPAddWireHook('--backdropClicked', () => {
      this.close();
    });

    // register resize listener
    if (!this.permanent) {
      if (window.ResizeObserver) {
        const ro = new ResizeObserver(entries => {
          for (const entry of entries) {
            window.requestAnimationFrame(() => {
              const cr = entry.contentRect;
              this.__isFloating = cr.width <= this.floatBreakpoint;
            });
          }
          if (this.__isFloating) {
            this.close();
          }
        });
        ro.observe(this);
      } else {
        // fallback, just listen to the resize event
        const cr = this.getBoundingClientRect();
        this.__isFloating = cr.width <= this.floatBreakpoint;

        window.addEventListener('resize', () => {
          // eslint-disable-next-line no-shadow
          const cr = this.getBoundingClientRect();
          this.__isFloating = cr.width <= this.floatBreakpoint;
          if (this.__isFloating) {
            this.close();
          }
        });
      }
    }

    // initial size
    const cr = this.getBoundingClientRect();
    this.__isFloating = cr.width <= this.floatBreakpoint;

    const drawer = this.shadowRoot.getElementById('drawer');
    /**
     * Set the transition effect after the first render. Otherwise we get a flickering effect
     */
    setTimeout(() => {
      drawer.style.transitionDuration = '200ms';
    }, 201);
    this.shadowRoot.getElementById('backdrop');
  }

  // eslint-disable-next-line class-methods-use-this
  pauseEvent(e) {
    if (e.stopPropagation) e.stopPropagation();
    if (e.preventDefault) e.preventDefault();
    e.cancelBubble = true;
    e.returnValue = false;
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  _getScreenX(e) {
    let x;
    if (e instanceof MouseEvent) {
      x = e.screenX;
    } else {
      x = e.changedTouches[0].screenX;
    }
    return x;
  }

  // eslint-disable-next-line class-methods-use-this
  _getScreenY(e) {
    let y;
    if (e instanceof MouseEvent) {
      y = e.screenY;
    } else {
      y = e.changedTouches[0].screenY;
    }
    return y;
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('FuroAppDrawer') ||
      css`
        :host {
          display: block;
          height: 100%;
          position: relative;
          overflow: hidden;
        }

        :host([hidden]) {
          display: none;
        }

        furo-horizontal-flex {
          height: 100%;
        }

        #drawer {
          border-right: 1px solid var(--separator, rgb(228, 228, 228));
          /*transition-duration: 200ms; is set in fbpReady */
          background: var(--surface-light);
        }

        ::slotted([scroll]) {
          height: 100%;
          overflow-y: auto;
        }

        /* disable pointer events, z-index 15 just to be below the drawer */
        #backdrop {
          pointer-events: none;
          transition-duration: 200ms;
          transition-property: opacity;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          opacity: 0;
          background: var(--furo-app-drawer-backdrop, rgba(0, 0, 0, 0.5));
          z-index: 15;
        }

        #drag {
          position: absolute;
          top: 0;
          width: 18px;
          bottom: 0;
          left: 0;
          z-index: 16;
        }

        :host([reverse]) #drag {
          left: unset;
          right: 0;
        }

        /* put the floating drawer outside the visible area, z-index 16 should be enough layers above 0 */
        :host([float]) #drawer {
          position: absolute;
          z-index: 16;
          top: 0;
          left: 0;
          bottom: 0;
        }

        /* put drawer to the right side on reverse mode */
        :host([float][reverse]) #drawer {
          left: unset;
          right: 0;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-horizontal-flex ?reverse="${this.isReverse}">
        <div id="drawer">
          <slot name="drawer"></slot>
        </div>
        <div flex>
          <slot></slot>
        </div>
      </furo-horizontal-flex>
      <div id="backdrop" @-click="--backdropClicked"></div>
      <div id="drag"></div>
    `;
  }
}

window.customElements.define('mora-app-drawer', FuroAppDrawer);
