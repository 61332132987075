import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';

/**
 * `data-display`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-data-display
 * @appliesMixin FBP
 */
class DataDisplay extends FBP(LitElement) {
  /**
   * @private
   * @return {Object}
   */
  static get properties() {
    return {
      /**
       * Description
       */
      val: { type: String },
    };
  }

  bind(d) {
    if (d !== undefined) {
      d.addEventListener('field-value-changed', () => {
        this.val = d._value;
      });
      // direct
      this.val = d._value;
    }
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('DataDisplay') ||
      css`
        :host {
          display: inline;
        }

        :host([hidden]) {
          display: none;
        }

        :host([empty]) {
          opacity: 0.4;
          background: whitesmoke;
          min-width: 60px;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      ${this.val}
    `;
  }
}

window.customElements.define('data-display', DataDisplay);
