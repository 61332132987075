import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';
import './objekt-kachel.js';
/**
 * `object-card-list`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-object-card-list
 * @appliesMixin FBP
 */
class ObjectCardList extends FBP(LitElement) {
  /**
   * Erwartet einen HTS für htsSupportcaseobject
   * @param hts
   */
  htsIn(hts) {
    this._FBPTriggerWire('--htsSupportcaseobject', hts);
  }

  htsUser(hts) {
    this._FBPTriggerWire('--htsUser', hts);
  }

  // to reset the data object
  clear() {
    this._FBPTriggerWire('--clear');
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    //  this._FBPTraceWires();
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('ObjectCardList') ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <flow-repeat
        ƒ-inject-items="--rawSupportcaseobjectCollection(*.entities)"
        ƒ-trigger-all="--userResponse"
      >
        <template>
          <objekt-kachel
            ƒ-inject-item="--item"
            ƒ-inject-user-collection="--trigger"
          ></objekt-kachel>
        </template>
      </flow-repeat>

      <furo-collection-agent
        service="Supportcaseobject"
        ƒ-hts-in="--htsSupportcaseobject"
        list-on-hts-in=""
        @-response="--rawSupportcaseobjectCollection"
      ></furo-collection-agent>

      <!-- ƒ-list="--rawSupportcaseobjectCollection" ist damit die user erst nach den Objekten geladen werden -->
      <furo-collection-agent
        service="User"
        ƒ-hts-in="--htsUser"
        ƒ-list="--rawSupportcaseobjectCollection"
        @-response="--userResponse"
      ></furo-collection-agent>
    `;
  }
}

window.customElements.define('object-card-list', ObjectCardList);
