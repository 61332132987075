import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';
import '@furo/icon/src/furo-icon.js';

/**
 * `user-profile`
 * Zeigt den Benutzernamen mit einem Icon des aktuell angemeldeten Bernutzers an
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-show-date
 * @appliesMixin FBP
 */

class UserProfile extends FBP(LitElement) {
  constructor() {
    super();
    this.username = '';
    this.alias = '';
    this.hideAlias = true;
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()
  }

  bindData(d) {
    d.username.addEventListener('field-value-changed', e => {
      this.username = e.target._value;
      this.requestUpdate();
    });

    d.alias.addEventListener('field-value-changed', e => {
      this.alias = e.target._value;

      if (this.alias === '') {
        this.hideAlias = true;
      } else {
        this.hideAlias = false;
      }
      this.requestUpdate();
    });
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent(this.name) ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }

        div {
          white-space: nowrap;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <div>
        <furo-icon icon="face"></furo-icon>
        ${this.username}
        <furo-icon icon="social:group" ?hidden="${this.hideAlias}"></furo-icon>
        ${this.alias}
      </div>
    `;
  }
}

window.customElements.define('user-profile', UserProfile);
