import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';
import '../../menu/objekt-menu.js';
import './panel-objektdetail.js';
/**
 * `view-objekt-detail`
 * Hier wird eine View angezeigt die Dynamisch angepasst werden kann.
 * Es handelt sich dabei um Dynamische Objekt-Felder
 *
 * @summary darstellung view-objekt-detail
 * @customElement
 * @demo demo-view-objekt-detail
 * @appliesMixin FBP
 */

class ViewObjektDetail extends FBP(LitElement) {
  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    //  this._FBPTraceWires()
  }

  static get properties() {
    return {
      /**
       * Name der Aktuellen Seite
       */
      pageName: {
        type: String,
        attribute: 'page-name',
      },
      pageTitle: {
        type: String,
      },
    };
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('ViewObjektDetail') ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }

        furo-app-bar-top {
          height: 48px;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-vertical-flex>
        <furo-app-bar-top drawer="objekt-detail">
          <objekt-menu flex page-name="s-objekt-detail" ƒ-refresh="--pageActivated"></objekt-menu>
        </furo-app-bar-top>
        <furo-app-drawer name="objekt-detail" float-breakpoint="1020">
          <panel-objektdetail
            scroll
            ƒ-bind-data="--objectdetailEntity"
            @-save-clicked="--saveClicked"
            ƒ-page-activated="--pageActivated"
          ></panel-objektdetail>
        </furo-app-drawer>
      </furo-vertical-flex>

      <furo-deep-link
        service="Supportcaseobjectdetail"
        ƒ-qp-in="--pageActivated(*.query)"
        @-hts-out="--htsObjectdetail"
      ></furo-deep-link>

      <furo-entity-agent
        service="Supportcaseobjectdetail"
        ƒ-hts-in="--htsObjectdetail"
        ƒ-load="--htsUpdated"
        ƒ-bind-request-data="--objectdetailEntity(*.data)"
        @-hts-injected="--htsUpdated"
        @-save-success="--saveSuccess"
        ƒ-save="--saveClicked"
        @-load-success="--dataLoaded"
        @-response-error="--error"
      ></furo-entity-agent>

      <furo-data-object
        type="supportcaseobject.ObjectdetailEntity"
        ƒ-clear-all-errors="--dataLoaded, --saveSuccess"
        ƒ-inject-raw="--dataLoaded, --saveSuccess"
        @-object-ready="--objectdetailEntity"
      ></furo-data-object>

      <furo-ripple noink class="success" ƒ-trigger="--saveSuccess"></furo-ripple>
      <furo-ripple noink class="fail" ƒ-trigger="--error"></furo-ripple>
    `;
  }
}

window.customElements.define('view-objekt-detail', ViewObjektDetail);
