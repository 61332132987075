import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';
import { i18n } from '@furo/framework';

/**
 * `part-unterschrift`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-part-unterschrift-auftraggeber
 * @appliesMixin FBP
 */
class PartUnterschriftAuftraggeber extends FBP(LitElement) {
  bindData(d) {
    this.fields = d;
    this._FBPTriggerWire('--fields', d);

    this._FBPAddWireHook('--triggerClearAuftraggeber', () => {
      this._FBPTriggerWire('--clearAuftraggeber', null);
      this.fields.kunde_unterschrift._value = '';
    });
  }

  pageActivated(data) {
    this._FBPTriggerWire('--pageActivated', data);
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('PartUnterschriftAuftraggeber') ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }

        h3 {
          margin-top: 56px;
          font-weight: Normal;
          font-size: 24px;
          letter-spacing: 0;
        }

        h4 {
          margin-top: 40px;
          margin-bottom: 0;
          font-weight: Normal;
          font-size: 16px;
          letter-spacing: 0.15px;
        }

        furo-data-sign-pad {
          width: 600px;
          height: 180px;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <h3>${i18n.t('objekt-protokoll_abs_auftraggeber')}:</h3>
      <furo-form-layouter two breakpoint-big="810,405" breakpoint-small="380">
        <furo-data-text-input
          label="${i18n.t('objekt-protokoll_label_auftraggeber_name')}"
          class="padding-sign"
          ƒ-bind-data="--fields(*.kunde_name)"
        ></furo-data-text-input
        ><br />
        <div full></div>
        <furo-data-sign-pad
          label="${i18n.t('objekt-protokoll_label_auftraggeber_unterschrift')}"
          ƒ-clear="--clearAuftraggeber"
          ƒ-bind-data="--fields(*.kunde_unterschrift)"
          @-sign-updated="--img"
          ƒ-resize="--pageActivated"
        ></furo-data-sign-pad>
        <div full></div>
        <furo-button
          label="${i18n.t('objekt-protokoll_label_auftraggeber_unterschriftzuruecksetzen')}"
          class="padding-sign"
          raised
          @-click="--triggerClearAuftraggeber"
        >
        </furo-button>
      </furo-form-layouter>
    `;
  }
}

window.customElements.define('part-unterschrift-auftraggeber', PartUnterschriftAuftraggeber);
