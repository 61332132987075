import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme';
import { FBP } from '@furo/fbp';
import { i18n } from '@furo/framework';

import './display-adresse.js';
import './table-arbeitszeiten.js';
import './table-arbeitsausgaben.js';
import './table-objekte.js';
import './table-ersatzteile.js';
import './table-reisezeitpauschalen.js';
import '@furo/util/src/furo-pretty-json.js';

/**
 * `panel-rapport`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo/panel-rapport.html
 * @appliesMixin FBP
 */
let sendenBtnText = '';
let hideSnrProtokoll = true;
let hideReisezeitpauschale = true;
let clearUnterschrift = false;
class PanelRapport extends FBP(LitElement) {
  constructor() {
    super();
    this.fields = {};
    this.disableSendButton = false;
    this.primarySendButton = false;
  }

  bindData(d) {
    this.disableSendButton = false;
    this.primarySendButton = false;

    this.fields = d;

    if (this.fields.totalarbeitszeit._value == null) {
      this.fields.totalarbeitszeit._value = 0;
    }

    if (this.fields.totalkm._value == null) {
      this.fields.totalkm._value = 0;
    }

    d.addEventListener('field-value-changed', e => {
      if (e.detail._name === 'erledigt_l') {
        if (e.detail._value) {
          if (this.fields.hide_snrprotokoll._value) {
            sendenBtnText = i18n.t('rapport_btn_rapport_senden');
          } else {
            sendenBtnText = i18n.t('rapport_btn_rapport_und_snr_senden');
          }
          // sendenBtnText = i18n.t("rapport_btn_rapport_und_snr_senden");
        } else {
          sendenBtnText = i18n.t('rapport_btn_zwischenrapport_senden');
        }
      }
      this.requestUpdate();
    });

    d.addEventListener('new-data-injected', () => {
      if (this.fields.hide_snrprotokoll._value) {
        hideSnrProtokoll = true;
      } else {
        hideSnrProtokoll = false;
      }

      if (this.fields.hide_reisezeitpauschale._value) {
        hideReisezeitpauschale = true;
      } else {
        hideReisezeitpauschale = false;
      }

      if (this.fields.display_auftraggeber._value == null) {
        this.fields.display_auftraggeber._value = '';
      }
      if (this.fields.display_monteur._value == null) {
        this.fields.display_monteur._value = '';
      }

      if (clearUnterschrift) {
        this._FBPTriggerWire('--clear');
        clearUnterschrift = false;
      }

      this._FBPTriggerWire('--data', d._value);
      this._FBPTriggerWire('--injected', this.fields);

      this.requestUpdate();
    });

    this.requestUpdate();
  }

  lockSendButton() {
    this.disableSendButton = true;
    this.requestUpdate();
  }

  unlockSendButton() {
    this.disableSendButton = false;
    this.requestUpdate();
  }

  colorSendButton(d) {
    if (typeof d.data !== 'undefined') {
      if (d.data.erledigt_l) {
        if (hideSnrProtokoll) {
          sendenBtnText = i18n.t('rapport_btn_rapport_gesendet');
        } else {
          sendenBtnText = i18n.t('rapport_btn_rapport_und_snr_gesendet');
        }

        // sendenBtnText = i18n.t("rapport_btn_rapport_und_snr_gesendet");
      } else {
        sendenBtnText = i18n.t('rapport_btn_zwischenrapport_gesendet');
      }
    }

    this.primarySendButton = true;
    this.requestUpdate();
  }

  uncolorSendButton() {
    this.primarySendButton = false;
    this.requestUpdate();
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()

    this._FBPAddWireHook('--clear', () => {
      this.fields.unterschrift._value = '';
    });
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent(this.name) ||
      css`
        :host {
          display: block;
          background-color: white;
          padding: 24px;
        }

        :host([hidden]) {
          display: none;
        }

        #rapportsenden {
          background-color: var(--primary-variant);
        }

        furo-data-sign-pad {
          width: 600px;
          height: 180px;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <h2>${i18n.t('rapport_main_titel')} ${this.fields.display_supportcase}</h2>

      <furo-form-layouter two="" breakpoint-big="810,405" breakpoint-small="380">
        <display-adresse ƒ-bind-data="--injected(*.adresse)"></display-adresse>

        <div>
          <span>
            ${i18n.t('rapport_titel_auftraggeber')}: ${this.fields.display_auftraggeber} <br />
          </span>
          <span> ${i18n.t('rapport_titel_monteur')}: ${this.fields.display_monteur} <br /> </span>
          <span>
            ${i18n.t('rapport_label_bestellnummer')}: ${this.fields.display_kommission} <br />
          </span>
        </div>
      </furo-form-layouter>

      <table-objekte ƒ-bind-data="--injected(*.objekte)"></table-objekte>
      <hr />

      <table-arbeitszeiten
        ƒ-bind-data="--injected(*.arbeitszeiten)"
        ƒ-bind-totalarbeitszeit="--injected(*.totalarbeitszeit)"
        ƒ-bind-totalkm="--injected(*.totalkm)"
      ></table-arbeitszeiten>
      <hr />

      <table-arbeitsausgaben ƒ-bind-data="--injected(*.arbeitsausgaben)"></table-arbeitsausgaben>
      <hr />

      <table-ersatzteile ƒ-bind-data="--injected(*.ersatzteile)"></table-ersatzteile>
      <hr />

      <table-reisezeitpauschalen
        ?hidden="${hideReisezeitpauschale}"
        ƒ-bind-data="--injected(*.reisezeitpauschalen)"
      ></table-reisezeitpauschalen>
      <hr ?hidden="${hideReisezeitpauschale}" />

      <furo-horizontal-flex condensed>
        <furo-data-textarea-input
          flex
          rows="4"
          label="${i18n.t('rapport_label_arbeitsbericht')}"
          ƒ-bind-data="--injected(*.rapporttext)"
        ></furo-data-textarea-input>
      </furo-horizontal-flex>
      <hr />

      <furo-horizontal-flex>
        <furo-data-text-input
          style="width: 50%;"
          label="${i18n.t('rapport_label_kundenmail')}"
          ƒ-bind-data="--injected(*.kundenmail)"
        ></furo-data-text-input>
      </furo-horizontal-flex>

      <furo-horizontal-flex>
        <furo-data-text-input
          ?hidden="${hideSnrProtokoll}"
          style="width: 50%;"
          label="${i18n.t('rapport_label_protokollmail')}"
          ƒ-bind-data="--injected(*.snrmail)"
        ></furo-data-text-input>
      </furo-horizontal-flex>

      <furo-horizontal-flex>
        <furo-data-checkbox-input
          label="${i18n.t('rapport_label_arbeiterledigt')}"
          ƒ-bind-data="--injected(*.erledigt_l)"
        ></furo-data-checkbox-input>
      </furo-horizontal-flex>

      <mora-furo-vertical-flex style="align-content: center">
        <furo-horizontal-flex>
          <furo-data-sign-pad
            style="padding-left: -20px"
            ƒ-clear="--clear"
            ƒ-bind-data="--injected(*.unterschrift)"
            @-sign-updated="--img"
            ƒ-resize="--data"
          ></furo-data-sign-pad>
        </furo-horizontal-flex>
        <furo-horizontal-flex>
          <furo-button
            raised
            @-click="--clear"
            label="${i18n.t('rapport_label_unterschriftzuruecksetzen')}"
          >
          </furo-button>
        </furo-horizontal-flex>
      </mora-furo-vertical-flex>

      <hr />
      <furo-horizontal-flex>
        <furo-button
          id="rapportsenden"
          raised
          ?primary=${this.primarySendButton}
          ?disabled=${this.disableSendButton}
          label="${sendenBtnText}"
          @-click="-^save"
        ></furo-button>
      </furo-horizontal-flex>
    `;
  }
}

window.customElements.define('panel-rapport', PanelRapport);
