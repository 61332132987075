import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme';
import { FBP } from '@furo/fbp';

/**
 * `display-arbeitszeiten-colspan`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo/display-arbeitszeiten-colspan.html
 * @appliesMixin FBP
 */
class TrArbeitszeitenColspan extends FBP(LitElement) {
  constructor() {
    super();
    this.fields = {};
  }

  /**
   *
   * @param fieldNode
   */
  bindData(fieldNode) {
    this.fields = fieldNode;

    this.requestUpdate();
  }

  index(i) {
    if (i % 2 === 0) {
      const myObj = this;
      myObj.setAttribute('class', 'dark');
    }
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent(this.name) ||
      css`
        :host {
          display: table-row;
        }

        :host([hidden]) {
          display: none;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <td colspan="8">${this.fields.text}</td>
    `;
  }
}

window.customElements.define('tr-arbeitszeiten-colspan', TrArbeitszeitenColspan);
