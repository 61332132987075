import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';

/**
 * `make-empty-bes-object`
 * Erstellt ein leere besEntity
 *
 * @customElement
 * @appliesMixin FBP
 */
class MakeEmptyBesObject extends FBP(LitElement) {
  qpIn(qp) {
    this._FBPTriggerWire('--queryParams', qp);
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    this._FBPAddWireHook('--hts', hts => {
      const rawObject = {
        data: {
          its_artikel: {
            link: {
              href: '/api/artikel',
              method: 'GET',
              rel: 'list',
              service: 'Artikel',
              type: 'artikel.Artikel',
            },
          },
        },
        links: hts,
      };
      /**
       * @event object-created
       * Fired when object-created
       * detail payload: empty object with links
       */
      const customEvent = new Event('object-created', { composed: true, bubbles: false });
      customEvent.detail = rawObject;
      this.dispatchEvent(customEvent);
    });
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-deep-link
        service="Bestellung"
        ƒ-qp-in="--queryParams"
        @-hts-out="--hts"
      ></furo-deep-link>
    `;
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('MakeEmptybesObject') ||
      css`
        :host {
          display: none;
        }
      `
    );
  }
}

window.customElements.define('make-empty-bes-object', MakeEmptyBesObject);
