import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme';
import { FBP } from '@furo/fbp';
import { i18n } from '@furo/framework';
import '../../project-components/show-date.js';

/**
 * `display-ersatzteile`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo/display-ersatzteile.html
 * @appliesMixin FBP
 */
class TrReisezeitpauschaleReiseweg extends FBP(LitElement) {
  constructor() {
    super();
    this.fields = {};
  }

  /**
   *
   * @param fieldNode
   */
  bindData(fieldNode) {
    this.fields = fieldNode;

    // Eintrag nicht anzeigen, wenn der wert leer oder 0 ist.
    if (this.fields.fahrweg._value === null || this.fields.fahrweg._value === 0) {
      this.noDisplay = true;
    } else {
      this.noDisplay = false;
    }

    // Wurde keine Fahrweg übergeben, soll das Element nicht angezeigt werden!
    if (this.fields.fahrweg._value == null) {
      this.hidden = true;
    }

    fieldNode.addEventListener('field-value-changed', () => {
      this.requestUpdate();
    });
    this._FBPTriggerWire('--data', fieldNode);
    this.requestUpdate();
  }

  static get properties() {
    return {
      /**
       * Name der Aktuellen Seite
       */
      noDisplay: {
        type: Boolean,
        attribute: 'hidden',
        reflect: true,
      },
    };
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent(this.name) ||
      css`
        :host {
          display: table-row;
        }

        :host([hidden]) {
          display: none;
        }
        .datum {
        }
        .text {
        }
        .menge {
          padding-right: 5px;
          text-align: right;
        }
        .einheit {
        }
        .user {
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <td class="datum"><show-date ƒ-bind-data="--data(*.tag)"></show-date></td>
      <td class="text">${i18n.t('rapport_tr_reisezeitpauschale_reiseweg_text')}</td>
      <td class="menge">${this.fields.fahrweg}</td>
      <td class="einheit">${i18n.t('rapport_tabtitel_km')}</td>
      <td class="user">${this.fields.display_user}</td>
    `;
  }
}

window.customElements.define('tr-reisezeitpauschale-reiseweg', TrReisezeitpauschaleReiseweg);
