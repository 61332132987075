import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';
import { i18n } from '@furo/framework';

/**
 * `sz-detail-button-bar`
 *  Dynamische create update delete buttons anhand von HATEOAS rels
 *
 *
 * @customElement
 * @demo demo-sz-detail-button-bar
 * @appliesMixin FBP
 */
class DetailButtonBar extends FBP(LitElement) {
  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('SzDetailButtonBar') ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }
      `
    );
  }

  bindEntity(entity) {
    this._FBPTriggerWire('--zoEntity', entity);
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-button-bar ƒ-bind-entity="--zoEntity">
        <furo-button
          hide-no-rel
          disable-pristine
          rel="update"
          raised
          label="${i18n.t('_label_speichern')}"
          @-click="^^save-clicked, --selfDisablingSave"
          ƒ-disable="--selfDisablingSave"
        ></furo-button>

        <furo-button
          hide-no-rel
          disable-pristine
          rel="create"
          raised
          label="${i18n.t('_label_neu')}"
          @-click="^^create-clicked, --selfDisablingCreate"
          ƒ-disable="--selfDisablingCreate"
        ></furo-button>

        <div flex></div>

        <furo-button
          hide-no-rel
          disable-no-rel
          rel="delete"
          unelevated
          label="${i18n.t('_label_loeschen')}"
          danger
          @-click="^^delete-clicked, --selfDisablingDelete"
          ƒ-disable="--selfDisablingDelete"
        ></furo-button>
      </furo-button-bar>
    `;
  }
}

window.customElements.define('detail-button-bar', DetailButtonBar);
