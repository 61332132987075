import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';
import { i18n } from '@furo/framework';
import '@furo/data-ui/src/furo-data-table';
import '@furo/layout/src/furo-horizontal-flex';
import '@furo/layout/src/furo-vertical-flex';
import '@furo/layout/src/furo-vertical-scroller';
import '../../menu/admin-menu.js';
import './tr-user-switch.js';

/**
 * `view-user-switch`
 * Adminpanel für Administratoren
 *
 * @summary Adminpanel für Administratoren
 * @customElement
 * @demo demo-view-Admin
 * @appliesMixin FBP
 */
class ViewUserSwitch extends FBP(LitElement) {
  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()

    // this._FBPAddWireHook('', (e) => {
    // });

    this._FBPAddWireHook('--user-Clicked', e => {
      this.useraliasData.userid._value = e.id;
      this._FBPTriggerWire('--setuseraliasData', this.useraliasData);
      this._FBPTriggerWire('--triggersetUseralias', true);
    });

    this._FBPAddWireHook('--user-reset-Clicked', () => {
      this.useraliasData.userid._value = '';
      this._FBPTriggerWire('--setuseraliasData', this.useraliasData);
      this._FBPTriggerWire('--triggersetUseralias', true);
    });
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('ViewUserSwitch') ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }
        furo-app-bar-top {
          height: 48px;
        }

        table {
          padding: 10px;
        }

        table,
        th {
          border: 1px solid black;
        }

        furo-button {
          padding: 20px;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-vertical-flex>
        <furo-app-bar-top drawer="user-switch">
          <admin-menu
            flex
            page-name="user-switch"
            ƒ-refresh="--pageActivated, --setuseraliasResponse"
          ></admin-menu>
        </furo-app-bar-top>
        <furo-app-drawer name="user-switch" float-breakpoint="1020">
          <furo-vertical-scroller @-user-clicked="--user-Clicked">
            <div>
              <furo-button
                label="${i18n.t('user-switch_reset_alias')}"
                raised
                danger
                @-click="--user-reset-Clicked"
              ></furo-button>
            </div>

            <table style="width: 100%">
              <thead>
                <tr>
                  <th>${i18n.t('user-switch_tabtitel_Username')}</th>
                  <th>${i18n.t('user-switch_tabtitel_Vorname')}</th>
                  <th>${i18n.t('user-switch_tabtitel_Nachname')}</th>
                </tr>
              </thead>
              <tbody>
                <template
                  is="flow-repeat"
                  ƒ-inject-items="--userprofilesResponse(*.entities)"
                  ƒ-select="--itemSelected"
                  ƒ-deselect="--addClicked"
                >
                  <tr-user-switch ƒ-inject-item="--item"></tr-user-switch>
                </template>
              </tbody>
            </table>
          </furo-vertical-scroller>
        </furo-app-drawer>
      </furo-vertical-flex>

      <!--non-visual components -->

      <furo-deep-link
        service="Admin"
        ƒ-qp-in="--pageActivated"
        @-hts-out="--htsAdmin"
      ></furo-deep-link>

      <furo-custom-method
        service="Admin"
        method="userprofiles"
        ƒ-hts-in="--htsAdmin"
        @-response="--userprofilesResponse"
        @-response-error="--error"
        @-hts-updated="--htsUpdated"
        ƒ-trigger="--htsUpdated"
      ></furo-custom-method>

      <furo-data-object
        type="admin.Useralias"
        @-object-ready="((useraliasData))"
      ></furo-data-object>

      <furo-custom-method
        service="Admin"
        method="setuseralias"
        ƒ-hts-in="--htsAdmin"
        @-response="--setuseraliasResponse"
        ƒ-trigger="--triggersetUseralias"
        ƒ-bind-request-data="--setuseraliasData"
      >
      </furo-custom-method>

      <furo-app-flow event="alias-changed" ƒ-emit="--setuseraliasResponse, --error"></furo-app-flow>
    `;
  }
}

window.customElements.define('view-user-switch', ViewUserSwitch);
