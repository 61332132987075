import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme';
import { FBP } from '@furo/fbp';
import { i18n } from '@furo/framework';

/**
 * `bes-filter`
 * todo Describe your element
 *
 * @customElement
 * @demo demo/bes-filter.html
 * @appliesMixin FBP
 */
class BesFilter extends FBP(LitElement) {
  constructor() {
    super();

    this.defaultOpenClose = 'open';
    this.defaultYear = new Date().getFullYear().toString();
    this.defaultMonth = new Date().getMonth().toString();

    if (this.defaultMonth.length === 1) {
      this.defaultMonth = `0${this.defaultMonth}`;
    }

    this.options = [
      { id: 'open', label: i18n.t('nachbestellung_filter_open') },
      { id: 'closed', label: i18n.t('nachbestellung_filter_bereitsbestellt') },
    ];

    this.yearoptions = [
      {
        id: (new Date().getFullYear() - 2).toString(),
        label: (new Date().getFullYear() - 2).toString(),
      },
      {
        id: (new Date().getFullYear() - 1).toString(),
        label: (new Date().getFullYear() - 1).toString(),
      },
      { id: new Date().getFullYear().toString(), label: new Date().getFullYear().toString() },
      {
        id: (new Date().getFullYear() + 1).toString(),
        label: (new Date().getFullYear() + 1).toString(),
      },
    ];

    this.monthoptions = [
      { id: '01', label: 'Jan' },
      { id: '02', label: 'Feb' },
      { id: '03', label: 'Mär' },
      { id: '04', label: 'Apr' },
      { id: '05', label: 'Mai' },
      { id: '06', label: 'Jun' },
      { id: '07', label: 'Jul' },
      { id: '08', label: 'Aug' },
      { id: '09', label: 'Sep' },
      { id: '10', label: 'Okt' },
      { id: '11', label: 'Nov' },
      { id: '12', label: 'Dez' },
    ];
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    this._FBPTriggerWire('--options', this.options);
    this._FBPTriggerWire('--yearoptions', this.yearoptions);
    this._FBPTriggerWire('--monthoptions', this.monthoptions);

    /**
     * Register hook on wire --filterOpenCloseChanged to
     * setze den richtigen filter und triggert einen --filterChanged event
     */
    this._FBPAddWireHook('--filterOpenCloseChanged', e => {
      this.defaultOpenClose = e;
      this._FBPTriggerWire('--filterChanged');
    });

    /**
     * Register hook on wire --filterYearChanged to
     * setze den richtigen filter und triggert einen --filterChanged event
     */
    this._FBPAddWireHook('--filterYearChanged', e => {
      this.defaultYear = e;
      this._FBPTriggerWire('--filterChanged');
    });

    /**
     * Register hook on wire --filterMonthChanged to
     * setze den richtigen filter und triggert einen --filterChanged event
     */
    this._FBPAddWireHook('--filterMonthChanged', e => {
      this.defaultMonth = e;
      this._FBPTriggerWire('--filterChanged');
    });

    /**
     * Register hook on wire --filterChanged to
     * setze den richtigen filter und werfe einen new-filter event
     */
    this._FBPAddWireHook('--filterChanged', () => {
      const filter = [];
      if (this.defaultOpenClose === 'open') {
        const opencloseFilter = ['status', 'eq', this.defaultOpenClose];
        filter.push(opencloseFilter);
      } else {
        const opencloseFilter = ['status', 'eq', this.defaultOpenClose];
        filter.push(opencloseFilter);
        const yearFilter = ['year', 'eq', this.defaultYear];
        filter.push(yearFilter);
        const monthFilter = ['month', 'eq', this.defaultMonth];
        filter.push(monthFilter);
        // filter = [["status", "eq", e]];
      }

      /**
       * @event new-filter
       * Fired when filter changed
       * detail payload: Filter Array
       */
      this.requestUpdate();

      const customEvent = new Event('new-filter', { composed: true, bubbles: false });
      customEvent.detail = filter;
      this.dispatchEvent(customEvent);
    });
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent(this.name) ||
      css`
        :host {
          display: block;
          padding-left: var(--spacing-s);
          box-sizing: border-box;
        }

        :host([hidden]) {
          display: none;
        }
        furo-select-input {
          width: 100%;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-select-input
        condensed
        label="${i18n.t('nachbestellung_filter_filter')}"
        ƒ-set-options="--options"
        @-value-changed="--filterOpenCloseChanged"
        value="${this.defaultOpenClose}"
      ></furo-select-input>
      <furo-horizontal-flex condensed ?hidden="${this.defaultOpenClose === 'open'}">
        <furo-select-input
          condensed
          autofocus
          flex
          label="Jahr"
          ƒ-set-options="--yearoptions"
          @-value-changed="--filterYearChanged"
          value="${this.defaultYear}"
        ></furo-select-input>
        <furo-select-input
          condensed
          autofocus
          flex
          label="Monat"
          ƒ-set-options="--monthoptions"
          @-value-changed="--filterMonthChanged"
          value="${this.defaultMonth}"
        ></furo-select-input>
      </furo-horizontal-flex>
    `;
  }
}

window.customElements.define('bes-filter', BesFilter);
