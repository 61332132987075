import { LitElement, html, css } from 'lit-element';
import { FBP } from '@furo/fbp';

import './sz-item.js';
import '@furo/layout/src/furo-vertical-scroller';
import '@furo/input/src/furo-icon-button.js';

import '@furo/data/src/furo-deep-link.js';
import '@furo/app/src/furo-loading-indicator-bar.js';

import '../../project-components/supportcase-supportnr-display.js';

/**
 * `sz-list`
 * Describe your element
 *
 * @summary shortdescription
 * @customElement
 * @demo demo/sz-list.html
 * @appliesMixin FBP
 */
class SzList extends FBP(LitElement) {
  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()
  }

  /**
   *
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return css`
      :host {
        display: block;
        height: 100%;
        width: var(--kachel-breite);
        padding: 24px 0;
        background-color: var(--surface, white);
        box-sizing: border-box;
      }

      .date {
        border-top: 1px solid gainsboro;
        position: sticky;
        top: 0;
        background-color: whitesmoke;
        height: 40px;
        line-height: 40px;
        box-sizing: border-box;
        padding-left: 12px;
        padding-right: 12px;
        z-index: 1;
      }

      .date span {
        float: right;
      }

      furo-icon-button {
        background-color: var(--primary);
        border-radius: 50%;
        color: var(--on-primary);
        margin: 12px;
      }

      .sc {
        color: var(--primary);
        line-height: 64px;
        font-size: 20px;
        text-align: center;
      }

      sz-item[selected] {
        background-color: rgba(26, 127, 240, 0.18);
      }
    `;
  }

  /**
   * Exposes --reload-list
   * @param {*} det
   */
  reloadList(det) {
    this._FBPTriggerWire('--reload-list', det);
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <furo-vertical-flex>
        <furo-horizontal-flex>
          <supportcase-supportnr-display
            flex
            ƒ-bind-data="--supportcaseEntity(*.data)"
          ></supportcase-supportnr-display>
          <furo-icon-button
            icon="add"
            @-click="^^add-time-clicked(injectedHTS),--addClicked"
          ></furo-icon-button>
        </furo-horizontal-flex>
        <furo-vertical-scroller flex @-list-item-clicked="--itemSelected">
          <furo-loading-indicator-bar
            ƒ-start="--htsInjected, --reload-list"
            ƒ-stop="--zeitenRcvd"
          ></furo-loading-indicator-bar>
          <template
            is="flow-repeat"
            ƒ-inject-items="--zeitenRcvd(*.entities)"
            ƒ-select="--itemSelected"
            ƒ-deselect="--addClicked"
          >
            <sz-item
              ƒ-inject="--item"
              ƒ-select="--itemSelected"
              ƒ-deselect="--itemDeSelected"
              ƒ-clear-date="--firstItem"
              ƒ-.index="--index"
            ></sz-item>
          </template>
        </furo-vertical-scroller>
      </furo-vertical-flex>

      <!-- liste der Arbeitszeiten -->
      <furo-deep-link
        service="Arbeitszeit"
        ƒ-qp-in="--qpIn"
        @-hts-out="--hts,((injectedHTS))"
      ></furo-deep-link>
      <furo-collection-agent
        service="Arbeitszeit"
        ƒ-hts-in="--hts"
        @-hts-injected="--htsInjected"
        ƒ-list="--htsInjected, --reload-list"
        @-response="--zeitenRcvd"
      ></furo-collection-agent>

      <!-- Datenkomponenten um den Supportcase zu laden und daraus die Supportnummer anzuzeigen -->
      <furo-deep-link
        service="Supportcase"
        ƒ-qp-in="--toggleSupportcase"
        @-hts-out="--htsSupportcase"
      ></furo-deep-link>

      <furo-entity-agent
        service="Supportcase"
        ƒ-hts-in="--htsSupportcase"
        @-hts-injected="--htsSupportcaseUpdated"
        ƒ-load="--htsSupportcaseUpdated"
        @-response="--supportcaseReceived"
      ></furo-entity-agent>

      <furo-data-object
        type="supportcase.SupportcaseEntity"
        ƒ-inject-raw="--supportcaseReceived"
        @-object-ready="--supportcaseEntity"
      ></furo-data-object>
    `;
  }

  setQp(qp) {
    this._FBPTriggerWire('--qpIn', qp);
    this._FBPTriggerWire('--toggleSupportcase', qp);
  }
}

window.customElements.define('sz-list', SzList);
