import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme';
import { FBP } from '@furo/fbp';
import { i18n } from '@furo/framework';

/**
 * `table-filter`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo/table-filter.html
 * @appliesMixin FBP
 */
class TableFilter extends FBP(LitElement) {
  constructor() {
    super();
    this.options = [
      { id: 'open', label: i18n.t('supporte_filter_open') },
      { id: 'closed', label: i18n.t('supporte_filter_closed') },
    ];
    this.suchtext = '';
    this.filteroption = '';
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    this._FBPTriggerWire('--options', this.options);

    /**
     * Register hook on wire --filterChanged to
     * setze den richtigen filter und werfe einen new-filter event
     */
    this._FBPAddWireHook('--filterChanged', e => {
      this.filteroption = e;
      this._FBPTriggerWire('--fireFilterEvent', null);
    });

    this._FBPAddWireHook('--suchtextchanged', e => {
      this.suchtext = e;
    });

    // ["status", "eq", e];
    // ["suchtext","like",e]

    this._FBPAddWireHook('--fireFilterEvent', () => {
      let filter = [];

      if (this.filteroption !== '') {
        const statusfilter = ['status', 'eq', this.filteroption];
        filter.push(statusfilter);
      }

      if (this.suchtext !== '') {
        const suchfilter = ['suchtext', 'like', this.suchtext];
        filter.push(suchfilter);
      }

      if (filter === []) {
        filter = '';
      }

      /**
       * @event new-filter
       * Fired when filter changed
       * detail payload: Filter Array
       */
      const customEvent = new Event('new-filter', { composed: true, bubbles: true });
      customEvent.detail = filter;
      this.dispatchEvent(customEvent);
    });
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent(this.name) ||
      css`
        :host {
          display: block;
          background-color: white;
          padding: 16px 0 4px 0;
        }

        :host([hidden]) {
          display: none;
        }

        furo-horizontal-flex > * {
          margin: 0 16px;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-horizontal-flex>
        <furo-select-input
          autofocus
          condensed
          label="${i18n.t('supporte_filter_filter')}"
          ƒ-set-options="--options"
          @-value-changed="--filterChanged"
        ></furo-select-input>

        <furo-search-input
          flex
          condensed
          trailing-icon="search"
          label="${i18n.t('supporte_filter_suchtextfeld')}"
          @-value-changed="--suchtextchanged"
        ></furo-search-input>
      </furo-horizontal-flex>

      <furo-de-bounce
        wait="500"
        ƒ-input-wire="--suchtextchanged"
        @-out="--fireFilterEvent"
      ></furo-de-bounce>
    `;
  }
}

window.customElements.define('table-filter', TableFilter);
