import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';
import { i18n } from '@furo/framework';

/**
 * `part-abs-schutzklasse`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-part-abs-schutzklasse
 * @appliesMixin FBP
 */
class PartAbsSchutzklasse extends FBP(LitElement) {
  bindData(d) {
    this._FBPTriggerWire('--fields', d);
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('PartAbsSchutzklasse') ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }

        h4 {
          margin-top: 40px;
          margin-bottom: 0;
          font-weight: Normal;
          font-size: 16px;
          letter-spacing: 0.15px;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <h4>${i18n.t('objekt-protokoll_abs_schutzklasse')}:</h4>
      <furo-form-layouter double="">
        <furo-data-checkbox-input
          condensed
          ƒ-bind-data="--fields(*.obj_schutzklasse1_l)"
          @-click="--sel4"
          ƒ-uncheck="--sel5,--sel6"
        ></furo-data-checkbox-input>
        <furo-data-checkbox-input
          condensed
          ƒ-bind-data="--fields(*.obj_schutzklasse2_l)"
          @-click="--sel5"
          ƒ-uncheck="--sel4,--sel6"
        ></furo-data-checkbox-input>
        <furo-data-checkbox-input
          condensed
          ƒ-bind-data="--fields(*.obj_schutzklasse3_l)"
          @-click="--sel6"
          ƒ-uncheck="--sel4,--sel5"
        ></furo-data-checkbox-input>
      </furo-form-layouter>
    `;
  }
}

window.customElements.define('part-abs-schutzklasse', PartAbsSchutzklasse);
