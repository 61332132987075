import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme';
import { FBP } from '@furo/fbp';
import '../../project-components/show-date.js';

/**
 * `display-arbeitszeiten`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo/display-arbeitszeiten.html
 * @appliesMixin FBP
 */
class TrArbeitszeiten extends FBP(LitElement) {
  constructor() {
    super();
    this.fields = {};
  }

  /**
   *
   * @param fieldNode
   */
  bindData(fieldNode) {
    this.fields = fieldNode;

    let zeitVonHours = fieldNode.zeit_von.hours;
    let zeitVonMinutes = fieldNode.zeit_von.minutes;
    let zeitBisHours = fieldNode.zeit_bis.hours;
    let zeitBisMinutes = fieldNode.zeit_bis.minutes;

    if (zeitVonHours === undefined) {
      zeitVonHours = '00';
    }
    if (zeitVonMinutes === undefined) {
      zeitVonMinutes = '00';
    }
    if (zeitBisHours === undefined) {
      zeitBisHours = '00';
    }
    if (zeitBisMinutes === undefined) {
      zeitBisMinutes = '00';
    }

    if (fieldNode.zeit_von.hours < 10) {
      zeitVonHours = `0${zeitVonHours}`;
    }

    if (fieldNode.zeit_von.minutes < 10) {
      zeitVonMinutes = `0${zeitVonMinutes}`;
    }

    if (fieldNode.zeit_bis.hours < 10) {
      zeitBisHours = `0${zeitBisHours}`;
    }

    if (fieldNode.zeit_bis.minutes < 10) {
      zeitBisMinutes = `0${zeitBisMinutes}`;
    }

    this.zeit_von = `${zeitVonHours}:${zeitVonMinutes}`;
    this.zeit_bis = `${zeitBisHours}:${zeitBisMinutes}`;

    this._FBPTriggerWire('--data', fieldNode);
    this.requestUpdate();
  }

  index(i) {
    if (i % 2 === 0) {
      const myObj = this;
      myObj.setAttribute('class', 'dark');
    }
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent(this.name) ||
      css`
        :host {
          display: table-row;
        }

        :host([hidden]) {
          display: none;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <td><show-date ƒ-bind-data="--data(*.tag)"></show-date></td>
      <td>${this.fields.dauer}</td>
      <td>${this.zeit_von}</td>
      <td>${this.zeit_bis}</td>
      <td>${this.fields.km}</td>
      <td>${this.fields.display_objectnumber}</td>
      <td>${this.fields.display_kostenstelle} - ${this.fields.display_kostenstelletext}</td>
      <td>${this.fields.display_user}</td>
    `;
  }
}

window.customElements.define('tr-arbeitszeiten', TrArbeitszeiten);
