import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme';
import { FBP } from '@furo/fbp';
import './tr-arbeitszeiten.js';
import './tr-arbeitszeiten-colspan.js';

/**
 * `table-body-arbeitszeiten`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo/display-arbeitszeiten.html
 * @appliesMixin FBP
 */
class TbArbeitszeiten extends FBP(LitElement) {
  constructor() {
    super();
    this.fields = {};
  }

  /**
   *
   * @param fieldNode
   */
  bindData(fieldNode) {
    this._FBPTriggerWire('--item', fieldNode);
  }

  index(i) {
    this._FBPTriggerWire('--index', i);
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent(this.name) ||
      css`
        :host {
          display: contents;
        }

        :host([hidden]) {
          display: none;
        }

        .dark {
          background-color: #f5f5f5;
        }

        tr-arbeitszeiten:hover {
          background-color: rgba(0, 0, 0, 0.12);
        }

        tr-arbeitszeiten-colspan:hover {
          background-color: rgba(0, 0, 0, 0.12);
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <tr-arbeitszeiten ƒ-bind-data="--item" ƒ-index="--index"></tr-arbeitszeiten>
      <tr-arbeitszeiten-colspan ƒ-bind-data="--item" ƒ-index="--index"></tr-arbeitszeiten-colspan>
    `;
  }
}

window.customElements.define('tb-arbeitszeiten', TbArbeitszeiten);
