import { LitElement, html, css } from 'lit-element';
import { FBP } from '@furo/fbp';
// import {i18n} from "@furo/framework";

import '@furo/data/src/furo-data-object.js';
import '@furo/data/src/furo-entity-agent.js';

import '@furo/data-input/src/furo-catalog.js';
import '@furo/data-input/src/furo-data-collection-dropdown.js';
import '@furo/form/src/furo-catalog.js';

import './make-empty-ausgabe-object.js';
import '../../project-components/detail-button-bar.js';
import './arbeitsausgaben-detail-form.js';

/**
 * `ausgaben-detail`
 * Describe your element
 *
 * @summary shortdescription
 * @customElement
 * @demo demo/ausgaben-detail.html
 * @appliesMixin FBP
 */
class ArbeitsausgabenDetail extends FBP(LitElement) {
  /**
   * Exposes --queryParams
   * @param {Object} det
   */
  setQp(det) {
    // setze auf edit wenn wir spc und arb haben
    this._FBPTriggerWire('--queryParams', det);
    // setze auf neu ausgabentellen wenn wir nur sco haben

    if (det.ara === undefined) {
      this._FBPTriggerWire('--creatorQP', det);
    } else {
      this._FBPTriggerWire('--editQP', det);
    }
    // wir müssen nach dem delete nur den QP SPC schicken, damit ara verschwindet
    this.createNewQP = { spc: det.spc };
  }

  addClicked(e) {
    this._FBPTriggerWire('--addClicked', e);
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires();
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <furo-vertical-flex style="position: relative">
        <furo-banner-display autofocus=""></furo-banner-display>
        <furo-banner ƒ-parse-grpc-status="--error" icon="warning"></furo-banner>

        <arbeitsausgaben-detail-form
          flex
          scroll
          ƒ-bind-data="--entityReady(*.data)"
          ƒ-object-collection-in="--objekteResponse"
          ƒ-kst-collection-in="--KstausgabeResponse"
          ƒ-start-activity="--saveClicked, --createClicked"
          ƒ-stop-activity="--dataLoaded, --saveSuccess, --emptyObject, --createSuccess, --error"
        >
        </arbeitsausgaben-detail-form>

        <furo-ripple noink class="success" ƒ-trigger="--saveSuccess, --createSuccess"></furo-ripple>
        <detail-button-bar
          ƒ-bind-entity="--entityReady"
          @-save-clicked="--saveClicked"
          @-create-clicked="--createClicked"
          @-delete-clicked="--deleteClicked"
        >
        </detail-button-bar>
      </furo-vertical-flex>

      <!-- Ab hier die datenlogik-->
      <furo-deep-link
        service="Arbeitsausgabe"
        ƒ-qp-in="--queryParams, --createdQp"
        @-hts-out="--hts"
      ></furo-deep-link>

      <make-empty-ausgabe-object
        ƒ-qp-in="--creatorQP"
        ƒ-trigger-custom-method="--addClicked, --createSuccess"
        @-object-created="--emptyObject"
      ></make-empty-ausgabe-object>

      <furo-entity-agent
        service="Arbeitsausgabe"
        ƒ-hts-in="--hts"
        ƒ-load="--editQP"
        ƒ-bind-request-data="--entityReady(*.data)"
        ƒ-delete="--deleteClicked"
        @-delete-success="^^ausgabe-deleted(createNewQP)"
        @-save-success="--saveSuccess, ^^ausgabe-saved"
        @-create-success="--createSuccess, ^^ausgabe-created(createNewQP), --createdQp(createNewQP)"
        ƒ-save="--saveClicked, --createClicked"
        @-load-success="--dataLoaded"
        @-response-error="--error"
      ></furo-entity-agent>

      <furo-data-object
        type="arbeitsausgabe.ArbeitsausgabeEntity"
        ƒ-clear-all-errors="--dataLoaded, --saveSuccess, --emptyObject, --createSuccess"
        ƒ-inject-raw="--dataLoaded, --saveSuccess, --emptyObject"
        @-object-ready="--entityReady"
      ></furo-data-object>

      <furo-deep-link
        service="Supportcaseobject"
        ƒ-qp-in="--queryParams"
        @-hts-out="--htsObjekte"
      ></furo-deep-link>

      <furo-collection-agent
        service="Supportcaseobject"
        ƒ-hts-in="--htsObjekte"
        list-on-hts-in
        @-response="--objekteResponse"
      ></furo-collection-agent>

      <furo-deep-link
        service="Kstausgabe"
        ƒ-qp-in="--queryParams"
        @-hts-out="--htsKstausgabe"
      ></furo-deep-link>

      <furo-collection-agent
        service="Kstausgabe"
        ƒ-hts-in="--htsKstausgabe"
        list-on-hts-in
        @-response="--KstausgabeResponse"
      ></furo-collection-agent>
    `;
  }

  /**
   *
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return css`
      :host {
        display: block;
        background-color: var(--surface, white);
        font-size: 14px;
        padding: 12px 24px;
        height: 100%;
        box-sizing: border-box;
      }

      furo-ripple.success {
        --furo-ripple-bg-color: var(--success, #5ef47f);
      }

      furo-banner-display {
        --furo-icon-fill-color: var(--danger, red);
      }
      ausgaben-detail-form {
        margin-top: 12px;
      }
    `;
  }
}

window.customElements.define('arbeitsausgaben-detail', ArbeitsausgabenDetail);
