import { LitElement, html, css } from 'lit-element';
import { FBP } from '@furo/fbp';

import './zerf-item.js';
import '@furo/layout/src/furo-vertical-scroller';
import '@furo/input/src/furo-icon-button.js';

import '@furo/data/src/furo-deep-link.js';
import '@furo/app/src/furo-loading-indicator-bar.js';
import './zerf-dateselector.js';
/**
 * `zerf-list`
 * Describe your element
 *
 * @summary shortdescription
 * @customElement
 * @demo demo/zerf-list.html
 * @appliesMixin FBP
 */
class ZerfList extends FBP(LitElement) {
  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    //    this._FBPTraceWires()
  }

  /**
   *
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return css`
      :host {
        display: block;
        height: 100%;
        width: var(--kachel-breite);
        padding: 24px 0;
        background-color: var(--surface, white);
        box-sizing: border-box;
      }

      .date {
        border-top: 1px solid gainsboro;
        position: sticky;
        top: 0;
        background-color: whitesmoke;
        height: 40px;
        line-height: 40px;
        box-sizing: border-box;
        padding-left: 12px;
        padding-right: 12px;
        z-index: 1;
      }

      .date span {
        float: right;
      }

      furo-icon-button {
        background-color: var(--primary);
        border-radius: 50%;
        color: var(--on-primary);
        margin: 12px;
      }

      .sc {
        color: var(--primary);
        line-height: 64px;
        font-size: 20px;
        text-align: center;
      }

      zerf-item[selected] {
        background-color: rgba(26, 127, 240, 0.18);
      }
    `;
  }

  /**
   * Exposes --reload-list
   * @param {*} det
   */
  reloadList(det) {
    this._FBPTriggerWire('--reload-list', det);
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <furo-vertical-flex>
        <furo-horizontal-flex>
          <div flex></div>
          <furo-icon-button
            icon="add"
            @-click="^^add-time-clicked(injectedHTS),--addClicked"
          ></furo-icon-button>
        </furo-horizontal-flex>
        <zerf-dateselector
          class="sc"
          @-date-changed="--date-selected, ^^date-selected"
        ></zerf-dateselector>
        <furo-vertical-scroller flex @-list-item-clicked="--itemSelected">
          <furo-loading-indicator-bar
            ƒ-start="--htsInjected, --reload-list"
            ƒ-stop="--zeitenRcvd"
          ></furo-loading-indicator-bar>
          <template
            is="flow-repeat"
            ƒ-inject-items="--zeitenRcvd(*.entities)"
            ƒ-select="--itemSelected"
            ƒ-deselect="--addClicked"
          >
            <zerf-item
              ƒ-inject="--item"
              ƒ-select="--itemSelected"
              ƒ-deselect="--itemDeSelected"
              ƒ-clear-date="--firstItem"
              ƒ-.index="--index"
            ></zerf-item>
          </template>
        </furo-vertical-scroller>
      </furo-vertical-flex>

      <!-- liste der Zeiten -->
      <furo-deep-link
        service="Zeit"
        ƒ-qp-in="--qpIn"
        @-hts-out="--hts,((injectedHTS))"
      ></furo-deep-link>
      <furo-collection-agent
        service="Zeit"
        ƒ-hts-in="--hts"
        ƒ-list="--reload-list"
        ƒ-update-qp="--date-selected"
        @-qp-changed="--reload-list"
        @-response="--zeitenRcvd"
      ></furo-collection-agent>
    `;
  }

  setQp(qp) {
    this._FBPTriggerWire('--qpIn', qp);
  }
}

window.customElements.define('zerf-list', ZerfList);
