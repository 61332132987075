import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme';
import { FBP } from '@furo/fbp';
import { i18n } from '@furo/framework';
import './tb-arbeitszeiten.js';

/**
 * `display-arbeitszeiten`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo/display-arbeitszeiten.html
 * @appliesMixin FBP
 */
class TableArbeitszeiten extends FBP(LitElement) {
  constructor() {
    super();
    this.fields = {};
  }

  /**
   *
   * @param repeaterNode
   */
  bindData(repeaterNode) {
    this.fields = repeaterNode;
    repeaterNode.addEventListener('repeated-fields-changed', () => {
      this._FBPTriggerWire('--data-changed', this.fields);
      this.requestUpdate();
    });
    this._FBPTriggerWire('--data-changed', this.fields);
    this.requestUpdate();
  }

  bindTotalarbeitszeit(totalarbeitszeitNode) {
    this.totalarbeitszeit = totalarbeitszeitNode._value;
  }

  bindTotalkm(totalkmNode) {
    this.totalkm = totalkmNode._value;
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent(this.name) ||
      css`
        :host {
          display: block;
          font-size: 14px;
        }

        :host([hidden]) {
          display: none;
        }

        table {
          width: 100%;
          max-width: 100%;
          margin-bottom: 2rem;
          background-color: #fff;
        }
        tr {
          transition: all 0.3s ease;
        }

        th {
          text-align: left;
        }

        .total {
          font-weight: bold;
          background-color: rgba(0, 0, 0, 0.12);
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      <h3>${i18n.t('rapport_abstitel_arbeitszeiten')}</h3>
      <table cellpadding="0" cellspacing="0">
        <thead>
          <tr>
            <th>${i18n.t('rapport_tabtitel_datum')}</th>
            <th>${i18n.t('rapport_tabtitel_dauer')}</th>
            <th>${i18n.t('rapport_tabtitel_zeitvon')}</th>
            <th>${i18n.t('rapport_tabtitel_zeitbis')}</th>
            <th>${i18n.t('rapport_tabtitel_km')}</th>
            <th>${i18n.t('rapport_tabtitel_objekt')}</th>
            <th>${i18n.t('rapport_tabtitel_kostenstelle')}</th>
            <th>${i18n.t('rapport_tabtitel_mitarbeiter')}</th>
          </tr>
        </thead>
        <tbody>
          <template
            is="flow-repeat"
            ƒ-inject-items="--data-changed(*.repeats)"
            ƒ-select="--itemSelected"
            ƒ-deselect="--addClicked"
          >
            <tb-arbeitszeiten ƒ-bind-data="--item" ƒ-index="--index"></tb-arbeitszeiten>
          </template>

          <tr class="total">
            <td>Total</td>
            <td>${this.totalarbeitszeit}</td>
            <td></td>
            <td></td>
            <td>${this.totalkm}</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
    `;
  }
}

window.customElements.define('table-arbeitszeiten', TableArbeitszeiten);
