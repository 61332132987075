import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';
import { i18n } from '@furo/framework';

/**
 * `part-messgeraet`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-part-messgeraet
 * @appliesMixin FBP
 */
class PartMessgeraet extends FBP(LitElement) {
  bindData(d) {
    this._FBPTriggerWire('--fields', d);
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('PartMessgeraet') ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }

        h3 {
          margin-top: 56px;
          font-weight: Normal;
          font-size: 24px;
          letter-spacing: 0;
        }

        h4 {
          font-size: 20px;
          height: 40px;
          line-height: 56px;
          margin: 0px;
          font-weight: normal;
        }
        span {
          font-size: 14px;
          height: 24px;
          letter-spacing: 0.1px;
          color: rgba(var(--on-surface-rgb), var(--medium-emphasis-surface));
          line-height: 20px;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <h3>${i18n.t('objekt-protokoll_abs_verw_messgeraete')}:</h3>

      <furo-form-layouter two>
        <furo-vertical-flex>
          <h4>
            ${i18n.t('objekt-protokoll_label_fabrikat')}:
            ${i18n.t('objekt-protokoll_label_fabrikatbez')}
          </h4>
          <span
            >${i18n.t('objekt-protokoll_label_fabrikattyp')}:
            ${i18n.t('objekt-protokoll_label_fabrikattyp_st725')}</span
          >
          <span>${i18n.t('objekt-protokoll_label_fabrikattyp_st725_zusatz')}</span>
          <furo-data-checkbox-input
            condensed
            ƒ-bind-data="--fields(*.vm_st725_l)"
            @-click="--sel1"
            ƒ-uncheck="--sel2"
          ></furo-data-checkbox-input>
        </furo-vertical-flex>

        <furo-vertical-flex>
          <h4>
            ${i18n.t('objekt-protokoll_label_fabrikat')}:
            ${i18n.t('objekt-protokoll_label_fabrikatbez')}
          </h4>
          <span
            >${i18n.t('objekt-protokoll_label_fabrikattyp')}:
            ${i18n.t('objekt-protokoll_label_fabrikattyp_it105')}</span
          >
          <span>${i18n.t('objekt-protokoll_label_fabrikattyp_it105_zusatz')}</span>
          <furo-data-checkbox-input
            condensed
            ƒ-bind-data="--fields(*.vm_it105_l)"
            @-click="--sel2"
            ƒ-uncheck="--sel1"
          ></furo-data-checkbox-input>
        </furo-vertical-flex>
      </furo-form-layouter>
    `;
  }
}

window.customElements.define('part-messgeraet', PartMessgeraet);
