import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme';
import { FBP } from '@furo/fbp';

/**
 * `display-adresse`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo/display-adresse.html
 * @appliesMixin FBP
 */
class DisplayAdresse extends FBP(LitElement) {
  constructor() {
    super();
    this.fields = {};
  }

  bindData(fieldNode) {
    this.fields = fieldNode;
    fieldNode.addEventListener('field-value-changed', () => {
      this.requestUpdate();
    });
    this.requestUpdate();
  }

  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent(this.name) ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   */
  render() {
    // language=HTML
    return html`
      ${this.fields.firma} <br />
      ${this.fields.strasse} <br />
      ${this.fields.plz_ort} <br />
      ${this.fields.telefonnummer} <br />
      ${this.fields.email} <br />
    `;
  }
}

window.customElements.define('display-adresse', DisplayAdresse);
