import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';
import '@furo/route/src/furo-qp-changer.js';
import '@furo/notification/src/furo-catalog.js';
import './sz-list.js';
import './sz-detail.js';

/**
 * `view-s-zeiterfassung`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-view-s-zeiterfassung
 * @appliesMixin FBP
 */
class ViewSZeiterfassung extends FBP(LitElement) {
  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    //       this._FBPTraceWires();
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('ViewSZeiterfassung') ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }
        furo-app-bar-top {
          height: 48px;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-vertical-flex>
        <furo-app-bar-top drawer="szeiterfassung">
          <objekt-menu flex page-name="s-zeiterfassung" ƒ-refresh="--pageActivated"></objekt-menu>
        </furo-app-bar-top>

        <furo-app-drawer
          name="szeiterfassung"
          float-breakpoint="1020"
        >

          <sz-list
            slot="drawer"
            drawer="szeiterfassung"
            @-item-clicked="--zeitItemClicked"
            @-add-time-clicked="--addClicked"
            ƒ-set-qp="--pageActivated(*.query)"
            ƒ-reload-list="--azChange, --azCreated"
          ></sz-list>

          <sz-detail
            ƒ-set-qp="--locationChanged(*.query)"
            ƒ-add-time-clicked="--addClicked"
            @-az-created="--azCreated"
            @-az-saved="--azChange"
            @-az-deleted="--azChange, --azDeletedQP"
          ></sz-detail>

      </furo-vertical-flex>
      </furo-app-drawer>

      <!-- wir hören auf die URL -->
      <furo-location
        url-space-regex="${window.APPROOT}/s-zeiterfassung"
        @-location-changed="--locationChanged"
      ></furo-location>

      <!-- addClicked gibt uns einen HTS rein und wir kriegen die qp -->
      <furo-reverse-deep-link
        service="Arbeitszeit"
        rel="list" @-converted="--addQp"
        ƒ-convert="--addClicked"
      ></furo-reverse-deep-link>

      <furo-reverse-deep-link
        service="Arbeitszeit"
        rel="self" @-converted="--itemQp"
        ƒ-convert="--zeitItemClicked(*.links)"
      ></furo-reverse-deep-link>

      <!-- wir schreiben die QP in die URL -->
      <furo-qp-changer clear="arb,sco" ƒ-set-qp="--itemQp,--addQp,--azDeletedQP"></furo-qp-changer>
    `;
  }
}

window.customElements.define('view-s-zeiterfassung', ViewSZeiterfassung);
