import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';
import { i18n } from '@furo/framework';
import '@furo/data-input/src/furo-data-checkbox-input';

/**
 * `ausgaben-detail-form`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-ausgaben-detail-form
 * @appliesMixin FBP
 */
class ArbeitsausgabenDetailForm extends FBP(LitElement) {
  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    this._FBPAddWireHook('--kstChanged', e => {
      if (this.kstList !== undefined) {
        for (const i of this.kstList.entities) {
          if (i.data.id === e) {
            // ID-Match Kostenstelle gefunden!
            if (i.data.ausgaben_fixbetrag_l) {
              // Kostenstelle mit FixBetrag
              const epValue = this.fields.einzelpreis._value;
              epValue.labels.disabled = true;
              epValue.value = i.data.ausgaben_fixbetrag;
              this.fields.einzelpreis._value = epValue;
            } else {
              // Kostenstelle ohne FixBetrag
              const epValue = this.fields.einzelpreis._value;
              epValue.labels = {};
              epValue.value = 0;
              this.fields.einzelpreis._value = epValue;
            }
          }
        }
      }
    });

    this._FBPAddWireHook('--clearEinzelpreis', () => {
      if (this.fields.einzelpreis._value.labels.disabled === undefined) {
        this.fields.einzelpreis._value = null;
      }
    });

    this._FBPAddWireHook('--clearMenge', () => {
      this.fields.menge._value = null;
    });

    // this._FBPTraceWires()
  }

  /**
   * Collection der Objekte
   * @param {}
   */
  objectCollectionIn(det) {
    this._FBPTriggerWire('--objectCollectionInjected', det);
  }

  /**
   * Collection der KST
   * @param {}
   */
  kstCollectionIn(det) {
    this.kstList = det;
    this._FBPTriggerWire('--kstCollectionInjected', det);
  }

  bindData(d) {
    this.fields = d;
    this._FBPTriggerWire('--data', d);
  }

  /**
   * Startet die progressbar vom form
   */
  startActivity() {
    this._FBPTriggerWire('--startActivity', null);
  }

  /**
   * Stopt die progressbar vom form
   */
  stopActivity() {
    this._FBPTriggerWire('--stopActivity', null);
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('AusgabenDetailForm') ||
      css`
        :host {
          display: block;
        }

        :host([hidden]) {
          display: none;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-form
        header-text="${i18n.t('s-ausgaben_main_titel')}"
        ƒ-start-activity="--startActivity"
        ƒ-stop-activity="--stopActivity"
      >
        <furo-form-layouter two breakpoint-big="810,405" breakpoint-small="380">
          <furo-data-date-input ƒ-bind-data="--data(*.tag)"></furo-data-date-input>
          <furo-data-text-input full flex ƒ-bind-data="--data(*.text)"></furo-data-text-input>
          <furo-data-text-input
            full
            flex
            ƒ-bind-data="--data(*.notiz)"
            leading-icon="av:note"
          ></furo-data-text-input>

          <furo-data-collection-dropdown
            full
            ƒ-inject-entities="--objectCollectionInjected(*.entities)"
            ƒ-bind-data="--data(*.ulid_supportcaseobject)"
          ></furo-data-collection-dropdown>

          <furo-data-collection-dropdown
            full
            ƒ-inject-entities="--kstCollectionInjected(*.entities)"
            ƒ-bind-data="--data(*.ulid_kostenstelle)"
            @-value-changed="--kstChanged"
            display-field="text"
          ></furo-data-collection-dropdown>

          <furo-data-number-input
            ƒ-bind-data="--data(*.einzelpreis)"
            value="0"
            step="0.01"
            leading-icon="av:note"
            trailing-icon="clear"
            @-trailing-icon-clicked="--clearEinzelpreis"
            ƒ-focus="--clearEinzelpreis"
          ></furo-data-number-input>
          <furo-data-number-input
            ƒ-bind-data="--data(*.menge)"
            value="0"
            trailing-icon="clear"
            @-trailing-icon-clicked="--clearMenge"
            ƒ-focus="--clearMenge"
          ></furo-data-number-input>



          <furo-data-checkbox-input ƒ-bind-data="--data(*.beleg_l)"></furo-data-checkbox-input>
        </furo-form-layouter>
      </furo-form>
    `;
  }
}

window.customElements.define('arbeitsausgaben-detail-form', ArbeitsausgabenDetailForm);
