import { LitElement, html, css } from 'lit-element';
import { Theme } from '@furo/framework/src/theme.js';
import { FBP } from '@furo/fbp';

/**
 * `user-switch-kachel`
 * Kachel um einen möglichen Benutzerwechsel darzustellen
 *
 * @summary Kachel für Benutzerwechsel
 * @customElement
 * @demo demo-user-switch-kachel
 * @appliesMixin FBP
 */

class TrUserSwitch extends FBP(LitElement) {
  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    //  this._FBPTraceWires();

    // this._FBPAddWireHook('', (e) => {
    // });
  }

  injectItem(item) {
    this.data = item.data;
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent('TrUserSwitch') ||
      css`
        :host {
          display: table-row;
          text-align: center;
        }

        :host([hidden]) {
          display: none;
        }

        td {
          border: 1px solid black;
        }

        furo-button {
          width: 100%;
          padding: 10px;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <td>
        <furo-button
          label="${this.data.username}"
          raised
          primary
          @-click="^^user-clicked(data)"
        ></furo-button>
      </td>
      <td>${this.data.vorname}</td>
      <td>${this.data.nachname}</td>
      <!--non-visual components -->
    `;
  }
}

window.customElements.define('tr-user-switch', TrUserSwitch);
