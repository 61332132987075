import { LitElement, html, css } from 'lit-element';
import { FBP } from '@furo/fbp';
import { Theme } from '@furo/framework/src/furo.js';
import '@furo/layout/src/furo-catalog.js';

import '@furo/form';
import '@furo/input';
import '@furo/app/src/furo-card.js';
import '@furo/form/src/furo-form.js';
import '@furo/input/src/furo-icon-button.js';
import '@furo/notification/src/furo-snackbar.js';

/**
 * `view-dashboard`
 * todo Describe your element
 *
 * @summary todo shortdescription
 * @customElement
 * @demo demo-view-dashboard
 * @appliesMixin FBP
 */
class ViewDashboard extends FBP(LitElement) {
  /**
   * flow is ready lifecycle method
   */
  _FBPReady() {
    super._FBPReady();
    // this._FBPTraceWires()
  }

  /**
   * Themable Styles
   * @private
   * @return {CSSResult}
   */
  static get styles() {
    // language=CSS
    return (
      Theme.getThemeForComponent(this.name) ||
      css`
        :host {
          display: block;
          height: 100%;
          overflow: hidden;
        }

        :host([hidden]) {
          display: none;
        }

        .content {
          padding: var(--spacing-s);
          background-color: var(--background);
          color: var(--on-surface);
        }

        .content > * {
          margin: 0 var(--spacing) var(--spacing) 0;
          width: 360px;
          height: 640px;
          float: left;
        }
      `
    );
  }

  /**
   * @private
   * @returns {TemplateResult}
   * @private
   */
  render() {
    // language=HTML
    return html`
      <furo-vertical-flex>
        <furo-app-bar-top drawer="main-drawer">
          <div>Dashboard</div>
          <furo-empty-spacer></furo-empty-spacer>
          <furo-icon-button icon="search" @-click="--float"></furo-icon-button>
          <furo-icon-button icon="settings" @-click="--op"></furo-icon-button>
          <furo-snackbar
            position-right
            action-button-text="ignore"
            timeout-in-ms="8000"
            label-text="Settings page is not implemented yet. This message destroys itself in 8 seconds"
            ƒ-show="--op"
          ></furo-snackbar>
        </furo-app-bar-top>
        <furo-split-view flex style="overflow: hidden">
          <div slot="master" style="background-color: aliceblue;height: 100%">master</div>
          <div scroll style="background-color: #e0d39b;height: 100%">
            <furo-card class="selected" style="height: 300px; width: 300px">
              <h2>Dash</h2>
              <a href="/api/samples" target="_blank">Direkter aufruf der API</a>
            </furo-card>
          </div>
        </furo-split-view>
      </furo-vertical-flex>
    `;
  }
}

window.customElements.define('view-dashboard', ViewDashboard);
